import { useState, useContext, useEffect } from "react"
import { ToastsContext } from "contexts/ToastsManagerContext"
import { useTranslation } from "react-i18next"

export function useNetworkStatus(shouldAlert) {
	const { addToast } = useContext(ToastsContext)
	const { t } = useTranslation()

	const [isOnline, setIsOnline] = useState(true)
	const [removeToast, setRemoveToast] = useState(null)

	/* Control network connection */
	useEffect(() => {
		const onOnline = () => {
			setIsOnline(true)
		}
		const onOffline = () => {
			setIsOnline(false)
		}

		window.addEventListener("online", onOnline)
		window.addEventListener("offline", onOffline)

		return () => {
			window.removeEventListener("online", onOnline)
			window.removeEventListener("offline", onOffline)
		}
	}, [])

	/* eslint-disable-next-line complexity */
	useEffect(() => {
		if (!shouldAlert) { return }

		if ((!isOnline && !removeToast) || (isOnline && removeToast)) {
			if (removeToast) {
				removeToast()
			}
			const removeToastFn = addToast(
				isOnline ? "info" : "warning",
				t(isOnline ? "network.up.title" : "network.down.title"),
				t(isOnline ? "network.up.subtitle" : "network.down.subtitle"),
				isOnline ? 10000 : null
			)
			setRemoveToast(() => isOnline ? null : removeToastFn)
		}
	}, [shouldAlert, isOnline, removeToast, addToast, t])

	return isOnline
}

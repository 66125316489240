import React from "react"
import PropTypes from "prop-types"

function PulsingErrorPill({ children, className }) {
	return (
		<div className={`pulsing-error-pill ${className || ""}`}>
			<div className={`animated-dot`}>
				<div className="dot-container">
					<div className="scaling-dot" />
				</div>
			</div>
			<div className="text">
				{children}
			</div>
		</div>
	)
}
PulsingErrorPill.propTypes = {
	children: PropTypes.element.isRequired,
	className: PropTypes.string,
}

export default PulsingErrorPill

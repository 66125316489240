import React, { createContext, useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import auth0 from "auth0-js"
import { OperatorProfile, User, UserAuthService, ParseModelKit } from "pinpo-model-kit"
import { Sentry, Tracking } from "pinpo-web-framework"
import moment from "moment"
import "moment/min/locales"
import i18n from "i18n"

const AuthenticationContext = createContext({})

function AuthenticationProvider(props) {
	const [user, setUser] = useState(null)
	const [complementaryData, setComplementaryData] = useState({})
	const [postLoginRedirection, setPostLoginRedirection] = useState("")
	const [isInitialized, setInitialized] = useState(false)
	const [isProfileComplete, setIsProfileComplete] = useState(false)

	async function _fetchComplementaryData(parseUser) {
		if (!parseUser) {
			return
		}
		const persistedUser = await User.Queries.userById(parseUser.id)
			.first()

		if (!persistedUser) {
			throw new Error("User not found")
		}
		const isAdmin = await UserAuthService.isAdmin(persistedUser)

		if (
			persistedUser.get("firstname")
			&& persistedUser.get("lastname")
			&& persistedUser.get("operatorProfile")
		) {
			setIsProfileComplete(true)
		}

		_updateSettingsLanguage(persistedUser)

		/* Update last connexion date */
		persistedUser.set("lastConnexionDate", new Date())

		await persistedUser.save()
		setUser(persistedUser)

		/* Tracking */
		Tracking.identifyUser(persistedUser)

		/* Sentry context */
		Sentry.setPersistentContext({
			persistedUser: {
				id: persistedUser.id,
				email: persistedUser.get("email"),
				username: User.fullName(persistedUser),
			},
		})

		/* Illustration  */

		setComplementaryData({
			isAdmin,
		})
	}

	function _updateSettingsLanguage(parseUser) {
		/* Set the language if it's not already done */
		if (!parseUser.get("preferredLanguage")) {
			parseUser.set("preferredLanguage", process.env.REACT_APP_DEFAULT_LANGUAGE || "fr")
		}
		i18n.changeLanguage(parseUser.get("preferredLanguage"))
			.catch((error) => (Sentry.report(error)))
		/* Set moment format */
		moment.locale(parseUser.get("preferredLanguage"))
	}

	/**
	 * Start the Auth0 cross origin authentication flow.
	 * (If succeeding the redirectUri will be call with additional url params
	 * to be used with parseHash of the auth0 js sdk)
	 * @param {string} email - Email of the user
	 * @param {string} password - Password of the user
	 * @returns {Promise<Object>} only rejects if there is an error
	 */
	function _loginAuth0(email, password) {
		return new Promise((_, reject) => {
			AuthenticationProvider.webAuth.login({
				email,
				password,
				realm: process.env.REACT_APP_AUTH0_REALM,
			}, reject)
		})
	}

	function _parseHash(hash) {
		return new Promise((resolve, reject) => {
			AuthenticationProvider.webAuth.parseHash({ hash }, (error, authResult) => {
				if (error) {
					reject(error)
				} else {
					resolve(authResult)
				}
			})
		})
	}

	function _logoutAuth0() {
		AuthenticationProvider.webAuth.logout({ returnTo: `${window.location.origin}/logout` })
	}

	async function _loginParse(authResult) {
		const { idTokenPayload } = authResult
		return await User.logInWith("auth0", {
			authData: {
				id: idTokenPayload.sub,
				appId: idTokenPayload.aud,
				accessToken: authResult.accessToken,
			},
		})
	}

	async function _logoutParse() {
		try {
			await User.logOut()
		} catch (_) {
			/*
			 * The parse logOut method only fails if the session token is
			 * already deleted on the server. However, there is nothing to be
			 * done here.
			 */
		}
	}

	/**
	 * Performs a logout for the current user
	 * @returns {Promise<void>}
	 */
	const logout = useCallback(async () => {
		setInitialized(false)
		await _logoutParse()
		_logoutAuth0()
		setUser(null)
		await _postLogout()
		setInitialized(true)
	}, [])

	const _fetchComplementaryDataRef = useRef(_fetchComplementaryData)

	const _postLogin = useCallback(async (parseUser) => {
		try {
			await _fetchComplementaryDataRef.current(parseUser)
		} catch (error) {
			logout()
		}
	}, [logout])

	function _postLogout() {
		Tracking.clearUser()
		Sentry.setPersistentContext({ user: { username: "Anonymous (not logged)" } })
	}

	/**
	 * Perform a sign up & login with auth0 provider
	 * @param email - The desired email of the user
	 * @param password - The desired password of the user
	 * @returns {Promise<Object>} - the auth data { email, emailVerified }
	 * @private
	 */
	function _signupAuth0(email, password) {
		return new Promise((resolve, reject) => {
			AuthenticationProvider.webAuth.signup({
				email,
				password,
				connection: process.env.REACT_APP_AUTH0_REALM,
			}, (error, result) => {
				if (error) {
					reject(error)
				} else {
					resolve(result)
				}
			})
		})
	}

	/**
	 * Performs a sign up with the provided credentials
	 * @param {string} email
	 * @param {string} password
	 * @param {string} firstname
	 * @param {string} lastname
	 * @returns {Promise<User>}
	 */
	async function signup(email, password, firstname, lastname) {
		await _signupAuth0(email, password)
	}

	async function saveAdditionalInfo(firstname, lastname) {
		user.set("firstname", firstname)
		user.set("lastname", lastname)
		const operator = new OperatorProfile()
		operator.setACL(new ParseModelKit.ACL(user))
		await operator.save({ owner: user })
		user.set("operatorProfile", operator)
		await user.save()
		await _fetchComplementaryData(user)
	}

	/**
	 * Retrieves the auth data from the hash and logs in with the parse-server
	 * @param {string} hash - The hash provided by auth0 on the redirectUri
	 * @returns {Promise<void>}
	 */
	async function authSuccessHandling(hash) {
		setInitialized(false)
		const authResult = await _parseHash(hash)
		const parseUser = await _loginParse(authResult)
		await _postLogin(parseUser)
		setInitialized(true)
	}

	/**
	 * Performs a login with user's credentials
	 * @param email - Email of the user
	 * @param password - Password of the user
	 * @returns {Promise<void>} only rejects if there is an error
	 */
	async function login(email, password) {
		await _loginAuth0(email, password)
	}

	/**
	 * Substitute the ID of the current user for the one associated with the token
	 * @param token - A session token belong to the target user
	 */
	async function become(token) {
		setInitialized(false)
		const parseUser = await User.become(token)
		await _postLogin(parseUser)
		setInitialized(true)
	}

	function changePassword(email) {
		return new Promise((resolve, reject) => {
			AuthenticationProvider.webAuth.changePassword({
				email,
				connection: process.env.REACT_APP_AUTH0_REALM,
			}, (error) => error ? reject(error) : resolve())
		})
	}

	const functions = {
		signup,
		saveAdditionalInfo,
		authSuccessHandling,
		login,
		logout,
		become,
		changePassword,
		setPostLoginRedirection,
	}

	// Load the user if authentication still valid to the corresponding state

	useEffect(() => {
		const parseUser = User.current()
		if (parseUser) {
			setUser(parseUser)
			_postLogin(parseUser)
				.then(() => setInitialized(true))
		} else {
			setInitialized(true)
		}
	}, [_postLogin])

	return (
		<AuthenticationContext.Provider
			value={{
				isInitialized,
				user,
				complementaryData,
				postLoginRedirection,
				isProfileComplete,
				...functions,
			}}
		>
			{props.children}
		</AuthenticationContext.Provider>
	)
}

/**
 * Auth0 js sdk instance configuration.
 * @type {WebAuth}
 */
AuthenticationProvider.webAuth = new auth0.WebAuth({
	domain: "auth.pinpo.fr",
	clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
	responseType: "token id_token",
	redirectUri: `${window.location.origin}/login`,
	scope: "openid",
})

AuthenticationProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export {
	AuthenticationContext,
	AuthenticationProvider,
}

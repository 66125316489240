/* eslint-disable max-lines */

import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
	EngagementReview,
	EngagementReviewService,
	LeadEngagement,
	QualificationService,
} from "pinpo-model-kit"
import QualificationFlagsHelper from "../../services/helpers/QualificationFlagsHelper"
import Config from "../../services/Config"
import ErrorHandlingService from "../../services/ErrorHandlingService"
import i18next from "i18next"
import { capitalize, lowerFirst } from "lodash/string"
import i18n from "../../i18n"
import { Trans, useTranslation } from "react-i18next"
import hoistNonReactStatics from "hoist-non-react-statics"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { ToastsContext } from "../ToastsManagerContext"
import FormResponse from "../../models/FormResponse"
import Review from "../../models/Review"
import { uniqueAndSortEngagements } from "contexts/utils"
import { usePromiseQueue } from "hooks/PromiseQueueHook"

const CurrentEngagementsContext = createContext({})

async function _getReview(engagement) {
	const parseReview = await EngagementReview.Queries.getLastReviewForEngagement(engagement)
		.first()
	if (parseReview) {
		const reviewTags = await EngagementReviewService.getReviewTagsList()
		return new Review(parseReview, reviewTags || [])
	}
	return null
}

function _preparePersonaDescription(script) {
	const name = capitalize(script.get("assistantName"))
	const gender = script.get("assistantGender")
		? i18n.t(`chat.notifications.persona.gender.${script.get("assistantGender")}`)
		: null
	const role = lowerFirst(script.get("role"))
	const tone = script.get("tone")
		?.toLowerCase() ?? null

	let key = gender && tone ? "genderAndTone" : "base"
	if (gender && !tone) {
		key = "gender"
	} else if (!gender && tone) {
		key = "tone"
	}

	return (
		<Trans i18nKey={`chat.notifications.persona.${key}`}>
			Vous
			incarnez <strong>{{ name }}</strong> ({{ gender }}), <strong>{{ role }}</strong>.
			Adoptez un ton <strong>{{ tone }}</strong> lors de vos échanges avec le lead.
		</Trans>
	)
}

function _prepareNotifications(engagement, type = "chat") {
	const conversation = engagement.get("currentConversation")
	const script = conversation.get("script")
	const newPersona = script.has("assistantName")

	const persona = {
		id: "persona",
		text: newPersona ? _preparePersonaDescription(script) : null,
	}
	const role = {
		id: "role",
		text: !newPersona ? script.get("role") : null,
	}
	const context = {
		id: "context",
		text: script.get("context"),
	}
	const mission = {
		id: "mission",
		text: script.get("mission"),
	}
	const resignReason = {
		id: "resignReason",
		text: conversation.get("resignReason"),
		color: "ocean",
	}
	return [persona, role, context, mission, resignReason]
		.map((notification) => ({
			...notification,
			type,
			closed: false,
		}))
		.filter((notification) => notification.text)
}

function _prepareFlags(engagement, previousFlags) {
	const qualificationFlagsInDB = engagement
		.get("contactRequest")
		.get("flags") || []
	const flagsHelper = new QualificationFlagsHelper()
	return flagsHelper.getQualificationFlags(qualificationFlagsInDB, previousFlags)
}

function CurrentEngagementsProvider({ children }) {
	const { t } = useTranslation()
	const [sessions, setSessions] = useState([])
	const [currentSessionId, setCurrentSessionId] = useState(null)
	const [isLockFetching, setIsLockFetching] = useState(false)
	const [currentEngagementsForOperator, setCurrentEngagementsForOperator] = useState([])
	const [substitutesFailedTries, setSubstitutesFailedTries] = useState(0)
	const {
		user,
		isInitialized,
		logout,
	} = useContext(AuthenticationContext)
	const {
		addToast,
	} = useContext(ToastsContext)

	const flushSession = useCallback((sessionId) => {
		setCurrentEngagementsForOperator(
			(engagements) => engagements.filter((e) => e.id !== sessionId)
		)
		setSessions((oldSession) => oldSession.filter((s) => s.engagement.id !== sessionId))
	}, [])

	const getCurrentSession = useCallback(() => (
		sessions.find((session) => session.engagement.id === currentSessionId)
	), [sessions, currentSessionId])

	const _resetSessionAfterError = useCallback((error, callback) => {
		setCurrentSessionId(null)
		setIsLockFetching(false)
		if (callback && typeof callback === "function") {
			return callback(error)
		}
		return null
	}, [])

	const _mergeSessionWithGeneratorCallback = useCallback((generator, sessionId) => {
		setSessions((oldSessions) => {
			const currentSession = oldSessions.find(
				(session) => session.engagement.id === sessionId
			)
			const mergedSession = generator(oldSessions, currentSession)
			if (mergedSession) {
				return [
					...oldSessions.filter(
						(session) => session.engagement.id !== mergedSession.engagement.id
					),
					mergedSession,
				]
			}
			return oldSessions
		})
	}, [])

	const _mergeCurrentSessionWithGeneratorCallback = useCallback((generator) => (
		_mergeSessionWithGeneratorCallback(generator, currentSessionId)
	), [currentSessionId, _mergeSessionWithGeneratorCallback])

	const {
		queuePromise,
		drainQueue
	} = usePromiseQueue({ limit: 2, maxLength: 2 })

	/**
	 * Update substitutes in state.
	 *
	 * Only trigger reloading if:
	 * - There is a current session.
	 * - The endpoint has not failed more than 3 times.
	 * - There is no more than 2 requests in the queue.
	 */
	const reloadSubstitutes = useCallback(() => {
		if (!currentSessionId || substitutesFailedTries >= 3) { return }
		queuePromise(async () => {
			const engagement = LeadEngagement.createWithoutData(currentSessionId)
			try {
				const substitutes = await QualificationService.getSubstitutesForEngagement(
					engagement
				)
				_mergeCurrentSessionWithGeneratorCallback((_oldSessions, currentSession) => {
					if (currentSession) {
						return {
							...currentSession,
							substitutes
						}
					}
					return null
				})
			} catch (error) {
				setSubstitutesFailedTries(substitutesFailedTries + 1)
				ErrorHandlingService.errorHandlerFactory([{
					matchError: () => true,
					getDescription: () => i18next.t("error.unableToLoadSubstitutes.description"),
				}])(error)
			}
		})
	}, [
		queuePromise,
		currentSessionId,
		_mergeCurrentSessionWithGeneratorCallback,
		substitutesFailedTries
	])

	/**
	 * Load engagements associated with the operator
	 * @param operator - The object representing the operator (Parse User)
	 * @returns {Promise<void>} - Async call without payload in the response
	 */
	const loadCurrentEngagementForOperator = useCallback(async (operator) => {
		try {
			const engagements = await LeadEngagement
				.Queries
				.currentEngagementForOperator(operator)
				.find()

			_mergeCurrentSessionWithGeneratorCallback((_oldSessions, currentSession) => {
				if (currentSession) {
					const { qualificationFlags } = currentSession
					const currentEngagement = engagements.find((engagement) => (
						engagement.id === currentSession.engagement.id
					))
					return {
						...currentSession,
						qualificationFlags: _prepareFlags(currentEngagement, qualificationFlags),
					}
				}
				return null
			})

			setCurrentEngagementsForOperator(uniqueAndSortEngagements(engagements))
		} catch (error) {
			ErrorHandlingService.errorHandlerFactory([{
				matchError: (err) => err.code === 209,
				getDescription: () => t("authentication.session.expired.error"),
				getCallback: (err) => {
					if (err.code === 209) {
						logout()
					}
				},
			}, {
				matchError: () => true,
				getDescription: () => i18next.t("error.unableToLoadCurrentEngagements.description"),
			}])(error)
		}
	}, [_mergeCurrentSessionWithGeneratorCallback, t, logout])

	const takeCurrentEngagementForEngagementIdAndOperator = useCallback(
		async (engagementId, operator, callback) => {
			try {
				setIsLockFetching(true)

				const currentEngagementCount = await LeadEngagement.Queries
					.currentEngagementForOperator(operator)
					.count()

				if (currentEngagementCount >= Config.MaxSimultaniousQualifications) {
					throw new Error("The maximum of simultaneous handled leads have been reached")
				}

				/* Fetch the engagement */
				const engagement = await LeadEngagement.Queries
					.unhandledActifEngagementForId(engagementId)
					.first()

				if (!engagement) {
					throw new Error("No engagement found for the ID provided")
				}
				/* Set the operator and build form */
				await QualificationService.assignEngagement(engagement)
				if (callback && typeof callback === "function") {
					callback()
				}
			} catch (error) {
				setIsLockFetching(false)
				ErrorHandlingService.errorHandlerFactory([{
					matchError: () => error.message.includes("maximum of simultaneous"),
					getDescription: () => i18next.t("error.maximumSimultaneousLeads.description", {
						limit: Config.MaxSimultaniousQualifications,
					}),
					level: "info",
				}, {
					matchError: () => error.message.includes("No engagement found"),
					getDescription: () => (
						i18next.t("error.noEngagementForQualification.description")
					),
					level: "info",
				}, {
					matchError: () => error.message.includes("already being assign"),
					getDescription: () => i18next.t("error.alreadyBeingAssign.description"),
					level: "info",
				}])(error)
				if (callback && typeof callback === "function") {
					callback(error)
				}
			}
		},
		[]
	)

	const fetchSessionForOperatorAndEngagementId = useCallback(
		async (operator, engagementId, callback = null, _showLoader = false) => {
			setCurrentSessionId(null)
			setIsLockFetching(true)

			let engagement
			try {
				engagement = await LeadEngagement.Queries
					.currentEngagementForOperator(operator)
					.include("contactRequest.qualificationFeature")
					.get(engagementId)
			} catch (error) { // Catch 101, but throw everything else
				if (error.code !== 101) {
					throw error
				}
			}
			const conversation = engagement && engagement.attributes.currentConversation
			if (!conversation) {
				const error = new Error("You are not assign to this qualification anymore")
				error.code = 404
				ErrorHandlingService.errorHandlerFactory([{
					matchError: () => true,
					getDescription: () => i18n.t("error.fetchSessionReview.description"),
					level: "info",
				}])(error)
				return _resetSessionAfterError(error, callback)
			}
			/* ContactRequest */
			const contactRequest = engagement.get("contactRequest")

			let { formResponses } = conversation.attributes

			/* If error when building formResponses */
			if (!formResponses) {
				formResponses = await conversation.updateFormResponses([])
			}

			/* Load Review object only if user is admin */
			let review = null
			try {
				review = await _getReview(engagement)
			} catch (error) {
				ErrorHandlingService.errorHandlerFactory([{
					matchError: () => true,
					getDescription: () => i18n.t("error.fetchSessionReview.description"),
				}])(error)
			}
			const sessionId = engagement.id

			_mergeSessionWithGeneratorCallback((_oldSessions, oldSession = {}) => {
				const {
					formResponses: oldFormResponses = [],
					qualificationFlags: previousFlags = []
				} = oldSession
				return {
					engagement,
					review,
					property: contactRequest.get("property"),
					agency: contactRequest.get("agency"),
					chatNotifications: _prepareNotifications(engagement),
					qualificationFlags: _prepareFlags(engagement, previousFlags),
					formResponses: formResponses.map((parseResponse) => {
						const response = new FormResponse(parseResponse)
						const oldFormResponse = oldFormResponses.find((r) => (
							r.getObject().get("field").id === response.getObject().get("field").id
						))
						if (oldFormResponse) {
							response.setDismissed(oldFormResponse.getDismissed())
						}
						return response
					})
				}
			}, sessionId)
			setCurrentEngagementsForOperator((engagements) => (
				uniqueAndSortEngagements(
					[...engagements.filter((e) => e.id !== sessionId),
						engagement]
				)
			))
			setCurrentSessionId(sessionId)
			setIsLockFetching(false)
			return null
		},
		[_resetSessionAfterError, _mergeSessionWithGeneratorCallback]
	)

	const resignEngagement = useCallback(async (engagement, adminOnly, reason, callback) => {
		setCurrentSessionId(null)
		try {
			setIsLockFetching(false)

			const name = engagement.get("lead")
				.identifier()

			await QualificationService.resignEngagement(engagement, adminOnly, reason)

			flushSession(engagement.id)

			addToast(
				"success",
				i18n.t("general.success"),
				i18n.t("engagement.resign.success.toast.text", { name }),
				5000
			)

			if (callback && typeof callback === "function") {
				callback()
			}
		} catch (error) {
			ErrorHandlingService.errorHandlerFactory([{
				matchError: () => true,
				getDescription: () => i18n.t("error.unableToResign.description"),
			}])(error)
			/* Restart watching conversation now resignation is completed */
			if (callback && typeof callback === "function") {
				callback(error)
			}
		}
	}, [
		addToast,
		flushSession
	])

	const completeEngagement = useCallback(async (engagement, notify) => {
		setCurrentSessionId(null)
		setIsLockFetching(false)

		try {
			const name = engagement.get("lead")
				.identifier()

			/* Call the cloud function to complete the engagement */
			await QualificationService.submitEngagement(engagement, notify)

			let subtextKey = "toast.qualification.success.subtext"
			if (!notify) {
				subtextKey = "toast.qualification.closed.subtext"
			}

			flushSession(engagement.id)

			addToast(
				"success",
				i18n.t("general.success"),
				i18n.t(subtextKey, { name }),
				5000,
			)
		} catch (error) {
			ErrorHandlingService.errorHandlerFactory([{
				matchError: () => true,
				getDescription: () => i18next.t("error.qualificationCompletionFailed.description"),
			}])(error)
		}
	}, [
		addToast,
		flushSession,
	])

	const updateQualificationFlags = useCallback((groupId, qualificationFlags) => {
		_mergeCurrentSessionWithGeneratorCallback((oldSessions, currentSession) => {
			if (currentSession) {
				const qualificationFlagsResult = QualificationFlagsHelper
					.updateFlagsWithValueForSection(
						currentSession.qualificationFlags, groupId, qualificationFlags,
					)
				const [selectedFlag] = qualificationFlags
				return {
					...currentSession,
					qualificationFlags: qualificationFlagsResult,
					formResponses: (currentSession.formResponses || []).map((response) => {
						// Dismiss empty responses if a special flag is selected
						if (selectedFlag
							&& !selectedFlag.includes("na-")
							&& !response.getObject().getValue()) {
							response.setDismissed(true)
						}
						return response
					})
				}
			}
			return null
		})
	}, [_mergeCurrentSessionWithGeneratorCallback])

	const updateChatNotification = useCallback((notification) => {
		_mergeCurrentSessionWithGeneratorCallback((oldSessions, currentSession) => {
			if (currentSession) {
				const chatNotifications = currentSession.chatNotifications
					.map((previousNotification) => (
						previousNotification.id === notification.id
							? notification
							: previousNotification
					))

				return {
					...currentSession,
					chatNotifications,
				}
			}
			return null
		})
	}, [_mergeCurrentSessionWithGeneratorCallback])

	const resetCurrentSession = useCallback(() => {
		setCurrentSessionId(null)
		setIsLockFetching(false)
	}, [])

	const updateEngagementReview = useCallback((review) => {
		_mergeCurrentSessionWithGeneratorCallback((oldSessions, currentSession) => {
			if (currentSession) {
				return {
					...currentSession,
					review: review.newInstance(),
				}
			}
			return null
		})
	}, [_mergeCurrentSessionWithGeneratorCallback])

	const updateFormResponse = useCallback(async (response) => {
		await response.getObject().save()
		_mergeCurrentSessionWithGeneratorCallback((oldSessions, currentSession) => {
			if (currentSession) {
				const newFormResponses = [...currentSession.formResponses]
				const idx = newFormResponses.findIndex((formResponse) => (
					formResponse?.getObject()?.id === response?.getObject()?.id
				))
				if (idx !== -1) {
					newFormResponses.splice(idx, 1, response)
				}

				return {
					...currentSession,
					formResponses: newFormResponses,
				}
			}
			return null
		})
	}, [_mergeCurrentSessionWithGeneratorCallback])

	const updateEngagement = useCallback((engagement) => {
		_mergeCurrentSessionWithGeneratorCallback((oldSessions, currentSession) => {
			if (currentSession) {
				return {
					...currentSession,
					engagement: engagement.newInstance(),
				}
			}
			return null
		})
	}, [_mergeCurrentSessionWithGeneratorCallback])

	const functions = {
		takeCurrentEngagementForEngagementIdAndOperator,
		fetchSessionForOperatorAndEngagementId,
		resignEngagement,
		completeEngagement,
		updateQualificationFlags,
		updateChatNotification,
		getCurrentSession,
		resetCurrentSession,
		updateEngagementReview,
		updateFormResponse,
		updateEngagement,
	}

	useEffect(() => {
		if (isInitialized && user) {
			loadCurrentEngagementForOperator(user)
		}
	}, [
		isInitialized,
		user,
		loadCurrentEngagementForOperator,
	])

	/**
	 * Reload substitutes when engagement updated.
	 */
	const currentSession = getCurrentSession()
	useEffect(() => {
		if (currentSession?.engagement) {
			reloadSubstitutes()
		} else if (!currentSessionId) {
			drainQueue()
		}
	}, [
		reloadSubstitutes,
		currentSession?.formResponses,
		currentSession?.engagement,
		currentSessionId,
		drainQueue
	])

	return (
		<CurrentEngagementsContext.Provider
			value={{
				currentEngagementsForOperator,
				isLockFetching,
				...functions,
			}}
		>
			{children}
		</CurrentEngagementsContext.Provider>
	)
}

function withCurrentEngagements(Component) {
	function WrapperComponent(props) {
		const context = useContext(CurrentEngagementsContext)
		return <Component {...props} {...context} />
	}

	hoistNonReactStatics(WrapperComponent, Component)
	return WrapperComponent
}

CurrentEngagementsProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export {
	CurrentEngagementsContext,
	CurrentEngagementsProvider,
	withCurrentEngagements,
}

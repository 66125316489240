import React from "react"
import PropTypes from "prop-types"
import { withErrorManager } from "pinpo-web-framework"

import ChatContent from "./ChatContent"
import ChatFooter from "./ChatFooter"
import ChatNotifications from "components/parts/chat-room/chat/ChatNotifications"
import ChatOptOutView from "./ChatOptOutView"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"
import { withConversation } from "../../../../contexts/conversations/ConversationsContext"

class ChatContainer extends React.Component {

	static labelForError = "Chat"

	// State and Constructor
	state = {}

	// Private Methods
	onSendMessage = async (text) => {
		const currentSession = this.props.getCurrentSession()
		if (!currentSession) { return }
		const { engagement } = currentSession
		if (!engagement) { return }
		const contactRequest = engagement.get("contactRequest")
		await this.props.sendMessageToLead(contactRequest, text)
		this.props.saveDraftMessageForLead(contactRequest, "")
	}

	onDraftChange = (draftMessage) => {
		const currentSession = this.props.getCurrentSession() || {}
		const { engagement } = currentSession
		if (!engagement) { return }
		const contactRequest = engagement.get("contactRequest")
		this.props.saveDraftMessageForLead(contactRequest, draftMessage)
	}

	// Lifecycle

	render() {
		const { updateChatNotification } = this.props
		const currentSession = this.props.getCurrentSession()
		const contactRequest = (
			currentSession
			&& currentSession.engagement
			&& currentSession.engagement.get("contactRequest")
		)
		const isOptOut = (
			contactRequest
			&& contactRequest.get("flags")
			&& contactRequest.get("flags").includes("opt-out")
		)
		return (
			<div className="chat-container full-height d-flex flex-column">
				<ChatNotifications { ...currentSession }
					updateNotification={ updateChatNotification }
				/>
				<ChatContent
					toggleMail={ this.props.toggleMail }
					onSendMessage={ this.onSendMessage }
					{ ...currentSession }
				/>
				{ isOptOut
					? (
						<ChatOptOutView />
					)
					: (
						<ChatFooter
							onSendMessage={ this.onSendMessage }
							onDraftChange={ this.onDraftChange }
							{ ...currentSession }
						/>
					)
				}
			</div>
		)
	}

}
ChatContainer.propTypes = {
	getCurrentSession: PropTypes.func.isRequired,
	sendMessageToLead: PropTypes.func.isRequired,
	saveDraftMessageForLead: PropTypes.func.isRequired,
	updateChatNotification: PropTypes.func.isRequired,
	toggleMail: PropTypes.func.isRequired,
}

export default withErrorManager(withCurrentEngagements(withConversation(ChatContainer)))

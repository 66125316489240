import React from "react"
import PropTypes from "prop-types"
import { Colors } from "pinpo-ui-kit"

import { Icon } from "@iconify/react"
import baselineWarning from "@iconify/icons-ic/baseline-warning"

function DisplayError({ error, className }) {
	return (
		<div className={`display-error-component ${className || ""}`}>
			<Icon
				className="mr-xs"
				icon={baselineWarning}
				width="2.4rem"
				height="2.4rem"
				color={Colors.pressure}
			/>
			<span>{error.message ?? error}</span>
		</div>
	)
}
DisplayError.propTypes = {
	error: PropTypes.oneOf([
		PropTypes.object,
		PropTypes.string,
	]).isRequired,
	className: PropTypes.string,
}

export default DisplayError

import React, { useState, useEffect, useContext, useCallback } from "react"
import PropTypes from "prop-types"
import { Query } from "pinpo-model-kit"
import hoistNonReactStatics from "hoist-non-react-statics"

import ErrorHandlingService from "../services/ErrorHandlingService"

const QueriesContext = React.createContext({})
const QueriesConsumer = QueriesContext.Consumer

const defaultState = {
	queries: {},
}

function QueriesProvider(props) {
	const [state, setState] = useState(defaultState)

	const upsertQuery = useCallback((query) => {
		setState((prevState) => ({
			...prevState,
			queries: {
				...prevState.queries,
				[query.id]: query,
			},
		}))
	}, [])

	const functions = {
		upsertQuery,
	}

	return (
		<QueriesContext.Provider value={{ ...state, ...functions }}>
			{props.children}
		</QueriesContext.Provider>
	)
}
QueriesProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

function useParseQuery(
	classObject,
	config,
	// Use dependencies to avoid heavy computation with stringify
	dependencies = [JSON.stringify(config)],
	errorHandler = ErrorHandlingService.defaultErrorHandler
) {
	const { queries, upsertQuery } = useContext(QueriesContext)
	const [query, setQuery] = useState(null)
	/**
	 * After initialisation, `queries` array changes shouldn't trigger reload.
	 * This array is only used to retrieve pre-existing identical query.
	 * The `config` stability is guaranteed by the `strConfig` variable.
	 */
	useEffect(() => {
		const updatedQuery = new Query(classObject, config).initialize(
			queries,
			upsertQuery,
			errorHandler
		)
		setQuery(updatedQuery)
		return () => updatedQuery.release()
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [...dependencies, classObject, errorHandler, upsertQuery])
	return query
}

function queriesReady(Component) {
	function WrapperComponent(props) {
		const context = useContext(QueriesContext)
		return <Component {...props} {...context} />
	}
	hoistNonReactStatics(WrapperComponent, Component)
	return WrapperComponent
}

export {
	QueriesProvider,
	QueriesConsumer,
	QueriesContext,
	useParseQuery,
	queriesReady,
}

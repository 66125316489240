import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
	PINChatBubbleAction,
	PINChatBubblePlaceholder,
	PINConversation,
	PINMessageSender,
} from "pinpo-ui-kit"
import { ConversationsContext } from "contexts/conversations/ConversationsContext"
import { withErrorManager } from "pinpo-web-framework"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { usePrevious } from "hooks/PreviousValueHook"

function ChatContent({
	engagement,
	toggleMail,
	onSendMessage,
}) {
	const container = useRef(null)
	const [messages, setMessages] = useState([])
	const oldMessages = usePrevious(messages)
	const { t } = useTranslation()
	const { getConversationByContactRequestId } = useContext(ConversationsContext)

	const scrollBottom = () => {
		if (container) {
			container.current.scrollTop = container.current.scrollHeight
		}
	}

	useEffect(() => {
		if (engagement) {
			const conversation = getConversationByContactRequestId(
				engagement?.get("contactRequest")?.id
			)
			const newMessages = (conversation && [...conversation.messages]) || []

			setMessages(newMessages)
		}
		return () => {
			setMessages([])
		}
	}, [engagement, getConversationByContactRequestId])

	useEffect(() => {
		if (messages.length > oldMessages?.length) {
			scrollBottom()
		}
	}, [oldMessages, messages])

	const contactRequest = engagement && engagement.get("contactRequest")
	const context = (contactRequest && contactRequest.getContext()) || {}

	const mail = context?.nylasMessage?.body
	const placeholderMessages = [PINMessageSender.Lead, PINMessageSender.Operator]
	const isMessagesLoaded = messages.length !== 0

	return (
		<div className="chat-content flex-shrink" ref={container}>
			{mail && (
				<PINChatBubbleAction
					sender={PINMessageSender.Lead}
					iconProps={{
						name: "mail",
						size: "lg",
						color: "ocean",
					}}
					onClick={toggleMail}
					text={t("messages.actions.mail")}
					actionText={t("general.actions.see")}
				/>
			)}
			<PINConversation
				t={t}
				messages={messages}
				leadName={engagement?.get("lead")?.identifier?.()}
				senderName={null}
				momentInstance={moment}
				errorAction={onSendMessage}
			/>
			{!isMessagesLoaded && placeholderMessages.map((sender, i) => (
				<PINChatBubblePlaceholder key={i} sender={sender}/>
			))}
		</div>
	)
}

ChatContent.labelForError = "Chat - Conversation"

ChatContent.propTypes = {
	engagement: PropTypes.object,
	toggleMail: PropTypes.func,
	onSendMessage: PropTypes.func,
}

export default withErrorManager(ChatContent)

import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "components/hoc/withTranslation"
import { QualificationFlagsGroupIdentifiers } from "pinpo-model-kit"
import { withErrorManager } from "pinpo-web-framework"

import ShimmerForm from "./ShimmerForm"

import FormFlags from "./FormFlags"

import FormInput from "components/shared/form/FormInput"
import FormResponseContainer from "components/parts/chat-room/qualification-work-space/FormResponseContainer"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"

class QualificationForm extends React.Component {

	static labelForError = "Formulaire de qualification"

	// State and Constructor

	state = {}

	// Private Methods

	getResponseFromSession = (id) => (
		this.props.getCurrentSession()
			.formResponses
			.find((r) => (r.getObject().id === id))
	)

	// Actions

	onFormInputChange = (id, value, dismissed) => {
		const response = this.getResponseFromSession(id)
		if (!response) { return }
		response.getObject().setValue(value)
		response.setDismissed(dismissed)
		// `this.props.updateFormResponse` will save response
		this.props.updateFormResponse(response)
	}

	onGeneralNoteChange = (id, value) => {
		const { engagement } = this.props.getCurrentSession()
		const contactRequest = engagement.get("contactRequest")
		contactRequest.set("notes", value)
		this.props.addParseObjectToQueueAndSave(contactRequest)
		this.props.updateEngagement(engagement)
	}

	// Lifecycle

	render() {
		const { t, onQualificationFlagsChange } = this.props
		const currentSession = this.props.getCurrentSession()
		const { className } = this.props
		if (!currentSession) { return (<ShimmerForm className={ className }/>) }
		const { engagement, formResponses } = currentSession
		return (
			<form className="qualification-form">
				{formResponses && (
					formResponses.map((formResponse) => (
						<FormResponseContainer
							key={ formResponse.getObject().id }
							formResponse={ formResponse }
							onChange={ this.onFormInputChange }
						/>
					))
				)}
				<FormFlags
					currentSession={{ ...currentSession }}
					qualificationIdentifiers={[
						QualificationFlagsGroupIdentifiers.SpecialCase
					]}
					onQualificationFlagsChange={ onQualificationFlagsChange }
				/>
				{engagement && (
					<FormInput
						id="general-note"
						type="text"
						className="general-note"
						label={ t("form.general.note.label") }
						value={ engagement.get("contactRequest").get("notes") }
						placeholder={ t("form.response.input.text.placeholder") }
						rows={ 3 }
						t={ t }
						onChange={ this.onGeneralNoteChange }
					/>
				)}
				<FormFlags
					currentSession={{ ...currentSession }}
					className="pb-lg"
					qualificationIdentifiers={[
						QualificationFlagsGroupIdentifiers.ConversationStatus
					]}
					onQualificationFlagsChange={ onQualificationFlagsChange }
				/>
			</form>
		)
	}

}
QualificationForm.propTypes = {
	getCurrentSession: PropTypes.func.isRequired,
	addParseObjectToQueueAndSave: PropTypes.func.isRequired,
	updateFormResponse: PropTypes.func.isRequired,
	onQualificationFlagsChange: PropTypes.func.isRequired,
	updateEngagement: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	formType: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
}

export default withErrorManager(withTranslation()(withCurrentEngagements(QualificationForm)))

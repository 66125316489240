import React from "react"
import { Sentry } from "pinpo-web-framework"

/*
 * WrappedComponent can use either addParseObjectToQueueAndSave
 * or autoSave with the saveCallback parameter.
 *
 * - addParseObjectToQueueAndSave case: add a ParseObject to a queue
 * then save it with Parse.Object.save() method. In this case, WrappedComponent doesn't
 * have to know the queue nor the saveCallback() to use
 *
 * - executeWhenPossible case: WrappedComponent can also use this methods if it prefers to provide
 * its own saveCallback()
 */

const withAutoSave = (WrappedComponent) => class HOCAutoSave extends React.Component {

	defaultState = {
		isSaving: false,
		queue: [],
		saveCallback: null
	}

	state = this.defaultState

	saveObjects = async () => {
		const promises = this.state.queue.map((object) => object.save())
		this.setState({ queue: [] })
		await Promise.all(promises)
	}

	addParseObjectToQueueAndSave = (object) => {
		const unsavedObject = this.state.queue.find((o) => object.id === o.id)
		let queue
		if (!unsavedObject) {
			queue = [...this.state.queue, object]
		} else {
			queue = [...this.state.queue.filter((o) => o.id !== object.id), object]
		}
		this.setState({ queue }, () => this.executeWhenPossible(this.saveObjects))
	}

	executeWhenPossible = async (saveCallback = () => null) => {
		try {
			if (!this.state.isSaving) {
				this.setState({ isSaving: true, saveCallback: null })
				await saveCallback()
				this.setState({ isSaving: false }, () => {
					if (this.state.saveCallback) {
						this.executeWhenPossible(this.state.saveCallback)
					}
				})
			} else {
				this.setState({ saveCallback })
			}
		} catch (error) {
			console.error(error)
			Sentry.report(error)
			this.setState(this.defaultState)
		}
	}

	render() {
		return (
			<WrappedComponent
				{...this.props}
				isSaving={this.state.isSaving}
				addParseObjectToQueueAndSave={this.addParseObjectToQueueAndSave}
				executeWhenPossible={this.executeWhenPossible}
			/>
		)
	}

}

export { withAutoSave }

import React, { useCallback, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { PopIn } from "components/shared/ui/PopIn"
import DayPicker from "react-day-picker"
import MomentLocaleUtils from "react-day-picker/moment"
import "react-day-picker/lib/style.css"
import "moment/locale/fr"
import { Colors, PINButton } from "pinpo-ui-kit"
import { LoadingView, withErrorManager } from "pinpo-web-framework"
import RoundedIconButton from "components/shared/RoundedIconButton"

import { Icon } from "@iconify/react"
import calendarMonth from "@iconify/icons-mdi/calendar-month"
import moment from "moment-timezone"
import { usePromiseResolver } from "../../../../hooks/PromiseResolver"
import { fetchAvailableTimeSlotsForMonth } from "../../../../services/TimeSlotsService"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { CurrentEngagementsContext } from "contexts/current-engagements/CurrentEngagementsContext"

function CalendarPopIn({ className, onClick, buttonTitle, selectedSlot }) {
	const [isPopInOpen, setIsPopInOpen] = useState(false)

	const onCloseAction = useCallback(() => setIsPopInOpen(false), [])

	const onClickOnButton = useCallback(() => (
		setIsPopInOpen((prevIsOpen) => !prevIsOpen)
	), [setIsPopInOpen])

	const onClickOnSlot = useCallback((slot) => {
		onCloseAction()
		onClick(slot)
	}, [onCloseAction, onClick])

	const button = !buttonTitle
		? <RoundedIconButton
			onClick={onClickOnButton}
			icon={(
				<Icon
					style={{ color: Colors.mustaYellow, fontSize: "1.8rem" }}
					icon={calendarMonth}
				/>
			)}
			color="mustaYellow"
			size="sm"
			className="mv-xxxs"
		/>
		: <PINButton
			small
			onClick={onClickOnButton}
			type="button"
			outline
		>{buttonTitle}</PINButton>

	return (
		<div className={`${className || ""} position-relative calendar header-button-container d-inline-block`}>
			{ button }
			<PopIn
				isOpen={isPopInOpen}
				onCloseAction={onCloseAction}
			>
				<CalendarPopInContent onClick={onClickOnSlot} selectedSlot={selectedSlot}/>
			</PopIn>
		</div>
	)
}

CalendarPopIn.labelForError = "Calendrier"
CalendarPopIn.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	buttonTitle: PropTypes.string,
	selectedSlot: PropTypes.shape({
		startAt: PropTypes.string,
		endAt: PropTypes.string,
		duration: PropTypes.number
	})
}

function CalendarPopInContent({ onClick, selectedSlot = {} }) {
	const { getCurrentSession } = useContext(CurrentEngagementsContext)
	const currentSession = getCurrentSession()
	const currentContactRequestId = currentSession?.engagement.get("contactRequest").id
	const timezone = currentSession?.engagement
		.get("contactRequest")
		.get("qualificationFeature")
		.get("appointmentBookingServiceConfiguration")
		?.timeslotFinderConfig
		?.timezone ?? "Europe/Paris"
	const { t } = useTranslation()
	const { user } = useContext(AuthenticationContext)
	const [selectedDay, setSelectedDay] = useState(new Date())
	const [displayedMonth, setDisplayedMonth] = useState(new Date())
	const [slotsToDisplay, setSlotsToDisplay] = useState([])

	const fetchSlots = useCallback(async () => {
		if (!currentContactRequestId) { return null }
		return await fetchAvailableTimeSlotsForMonth(
			currentContactRequestId,
			displayedMonth
		)
	}, [currentContactRequestId, displayedMonth])

	const { data, isLoading } = usePromiseResolver(fetchSlots)
	const { slots, isSlotsSearchAvailable } = data || {}

	useEffect(() => {
		setSelectedDay(slots?.[0]?.startAt ?? displayedMonth)
	}, [slots, displayedMonth])

	useEffect(() => {
		const selectedDate = moment(selectedDay)
			.tz(timezone)
			.format("YYYY-MM-DD")
		setSlotsToDisplay(
			isLoading || (!slots)
				? []
				: slots?.filter((slot) => slot.date === selectedDate)
		)
	}, [isLoading, slots, selectedDay, timezone])
	return (
		<>
			<h1 className="calendar-pop-in-header">
				{t("calendarPopIn.title")}
			</h1>
			<div className="calendar-pop-in-body">
				<DayPicker
					className="datepicker"
					localeUtils={MomentLocaleUtils}
					locale={user.get("preferredLanguage")}
					numberOfMonths={1}
					modifiers={{ available: (day) => {
						if (isLoading) { return false }
						const dayDate = moment(day)
							.tz(timezone)
							.format("YYYY-MM-DD")
						return slots?.find((slot) => slot.date === dayDate)
					} }}
					selectedDays={selectedDay}
					onDayClick={setSelectedDay}
					onMonthChange={setDisplayedMonth}
				/>
				<div className="slots">
					{isSlotsSearchAvailable
						? ( // Time Slots service is configured
							<>
								<div className="day-label">
									{moment(selectedDay)
										.tz(timezone)
										.format("dddd Do MMMM")}
								</div>
								{!isLoading && (
									<div className="slots-list">
										{slotsToDisplay.map((slot) => (
											<div
												className={`slot ${onClick ? "pointer" : ""} ${selectedSlot?.startAt === slot.startAt ? "selected" : ""}`}
												key={slot.startAt.toISOString()}
												onClick={() => onClick(slot)}
											>
												{moment(slot.startAt)
													.tz(timezone)
													.format("LT")}
												<span className="slot-duration">
													{" "}- {slot.duration} {t("time.minutes")}
												</span>
											</div>
										))}
									</div>
								)}
								{!isLoading && !slotsToDisplay.length && (
									<div className="indication">
										{t("calendarPopIn.noSlotToday")}
									</div>
								)}
							</>
						)
						: ( // Time Slots service is not configured
							<div className="indication">
								{t("calendarPopIn.noConfiguration")}
							</div>
						)
					}
					{ isLoading && (
						<LoadingView size={100} className="flex-grow" />
					)}
				</div>
			</div>
		</>
	)
}

CalendarPopInContent.propTypes = {
	onClick: PropTypes.func,
	selectedSlot: PropTypes.shape({
		startAt: PropTypes.string,
		endAt: PropTypes.string,
		duration: PropTypes.number
	})
}

export default withErrorManager(CalendarPopIn)
export {
	CalendarPopInContent
}

const Config = {
	AppName: process.env.APP_NAME,
	Env: process.env.REACT_APP_MODE,
	EnableSSL: process.env.ENABLE_SSL,

	DefaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE || "fr",

	NewRelicLicenseKey: process.env.NEW_RELIC_LICENSE_KEY,

	DeprecatedSmoochAppId: process.env.REACT_APP_SMOOCH_DEPRECATED_APP_ID,
	BranchKey: process.env.REACT_APP_BRANCH_KEY,
	ParseAppId: process.env.REACT_APP_PARSE_APP_ID,
	ParseServerURL: process.env.REACT_APP_PARSE_SERVER_URL,
	ParseJSKey: process.env.REACT_APP_PARSE_JAVASCRIPT_KEY,
	ParseLiveQueryServer: process.env.REACT_APP_PARSE_LIVE_QUERY_SERVER,
	CrispSiteId: process.env.REACT_APP_CRISP_SITE_ID,

	MaxSimultaniousQualifications: parseInt(
		process.env.REACT_APP_MAX_SIMULTANIOUS_QUALIFICATIONS,
		10
	),
	QualificationBottomLimit: parseInt(process.env.REACT_APP_QUALIFICATION_BOTTOM_LIMIT, 10),
	QualificationTopLimit: parseInt(process.env.REACT_APP_QUALIFICATION_TOP_LIMIT, 10),
	QualificationMaxTime: parseInt(process.env.REACT_APP_QUALIFICATION_MAX_TIME, 10),
	QualificationAlertTime: parseInt(process.env.REACT_APP_QUALIFICATION_ALERT_TIME, 10),

	WarningAwaitingLeads: parseInt(process.env.REACT_APP_WARNING_AWAITING_LEADS, 10),

	AwaitingLeadsLoadLimit: parseInt(process.env.REACT_APP_AWAITING_LEADS_LOAD_LIMIT, 10) || 20,

	Messaging: {
		SecondsBeforeTimeout: parseInt(process.env.REACT_APP_MESSAGING_SEC_BEFORE_TIMEOUT, 10) || 30
	},

	Guru: {
		GeneralTag: process.env.REACT_APP_GURU_GENERAL_TAG,
		API: {
			Url: process.env.REACT_APP_GURU_API_URL,
			User: process.env.REACT_APP_GURU_API_USER,
			Token: process.env.REACT_APP_GURU_API_TOKEN
		}
	},

	URLs: {
		CorsProxy: `${process.env.REACT_APP_PINPO_CDN}/cors-proxy`,
		TOS: process.env.REACT_APP_OPERATOR_TOS
	},

	HelpMenu: {
		isActive: process.env.REACT_APP_HELP_MENU === "true"
	},

	RateLimit: {
		GetMessages: {
			Rate: parseInt(process.env.REACT_APP_GET_MESSAGES_RATE, 10) || 1,
			Limit: parseInt(process.env.REACT_APP_GET_MESSAGES_LIMIT, 10) || 1
		}
	},

	MinListChoice: parseInt(process.env.REACT_APP_MIN_LIST_CHOICE, 10) || 9,

	Domain: process.env.REACT_APP_DOMAIN,

	Port: process.env.PORT
}

export default Config

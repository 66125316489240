import { useState } from "react"

/**
 * Run `callback` only once. Useful to mimic use of a Class `constructor`
 * @param {function} callback
 * @returns `undefined`
 */
const useOnceBeforeRender = (callback = () => null) => {
	const [hasBeenCalled, setHasBeenCalled] = useState(false)
	if (hasBeenCalled) { return }
	callback()
	setHasBeenCalled(true)
}

export { useOnceBeforeRender }

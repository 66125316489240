import ErrorHandlingService from "../services/ErrorHandlingService"
import { useEffect, useState } from "react"

/**
 * @callback fetchCallback
 * @returns {Promise} The Promise to resolve
 */

/**
 * @callback errorHandler
 * @param {Error} The error to handle
 */

/**
 * Take a callback returning a promise in first argument that is called immediately.
 * Every time that callback change, it is called again. You should use {@link React.useCallback}
 * to ensure that this callback does not change to frequently.
 * @param {fetchCallback} fetch A callback returning a Promise.
 * @param {errorHandler} errorHandler A function called if an error occur.
 * @return {{isLoading: boolean, data: unknown}}
 */
export function usePromiseResolver(fetch, errorHandler = ErrorHandlingService.defaultErrorHandler) {
	const [data, setData] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		let isSubscribed = true
		setIsLoading(true)
		fetch()
			.then((response) => {
				if (isSubscribed) {
					setData(response)
				}
			})
			.catch(errorHandler)
			.finally(() => setIsLoading(false))
		return () => {
			isSubscribed = false
		}
	}, [fetch, errorHandler])
	return {
		data,
		isLoading,
	}
}

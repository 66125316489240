import React, { createContext, useCallback, useState } from "react"
import PropTypes from "prop-types"
import { EngagementConversation } from "pinpo-model-kit"
import ErrorHandlingService from "services/ErrorHandlingService"
import i18next from "i18next"

const AnalyticsContext = createContext({})

function AnalyticsProvider(props) {
	const [engagementConversationsCount, setEngagementConversationsCount] = useState(0)

	const loadEngagementConversationsCountForOperator = useCallback(
		async (operator, since = null) => {
			try {
				const engagementConvsCount = await EngagementConversation
					.Queries
					.conversationForOperatorSince(operator, since)
					.count()

				setEngagementConversationsCount(engagementConvsCount)
			} catch (error) {
				ErrorHandlingService.errorHandlerFactory([{
					matchError: () => true,
					getDescription: () => i18next.t("error.loadConversationCount.description"),
				}])(error)
			}
		},
		[]
	)

	const functions = {
		loadEngagementConversationsCountForOperator,
	}

	return (
		<AnalyticsContext.Provider value={{ engagementConversationsCount, ...functions }}>
			{props.children}
		</AnalyticsContext.Provider>
	)
}

AnalyticsProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export {
	AnalyticsContext,
	AnalyticsProvider,
}

import React from "react"
import PropTypes from "prop-types"
import { Colors } from "pinpo-ui-kit"
import { Icon } from "@iconify/react"

const QualificationWorkSpaceIcon = ({ name = "" }) => (
	<div className="d-flex align-items-center justify-content-center mt-lg mb-lg">
		<div className={`rounded-circle pt-md pb-md pl-md pr-md ${!name ? "shimmer-background" : "background-smoke"}`}>
			<Icon
				icon={name}
				style={{ fontSize: "4.8rem", color: Colors.coal }}
			/>
		</div>
	</div>
)
QualificationWorkSpaceIcon.propTypes = {
	name: PropTypes.string,
}

export default QualificationWorkSpaceIcon

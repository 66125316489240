import React from "react"
import PropTypes from "prop-types"
import { withAutoUpdate } from "components/hoc/AutoUpdate"
import MomentHelper from "services/helpers/MomentHelper"

const PINInterval = ({ date = null, timestamp = null, className = "" }) => {
	let interval = ""
	interval = date && MomentHelper.beautifyIntervalFromNow(date)
	interval = !interval
		? (timestamp && MomentHelper.beautifyIntervalFromNow(new Date(timestamp * 1000)))
		: interval
	return (
		<span className={ className }>
			{ interval }
		</span>
	)
}
PINInterval.propTypes = {
	date: PropTypes.object,
	timestamp: PropTypes.number,
	className: PropTypes.string,
}

export default withAutoUpdate()(PINInterval)

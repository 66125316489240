import React, { useContext } from "react"
import DashboardAnalytics from "components/parts/dashboard/welcome/DashboardAnalytics"
import DashboardIllustration from "components/parts/dashboard/welcome/DashboardIllustration"
import { withErrorManager } from "pinpo-web-framework"
import { AnalyticsProvider } from "contexts/analytics/AnalyticsContext"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { useTranslation } from "react-i18next"

const DashboardWelcome = (props) => {
	const { user } = useContext(AuthenticationContext)

	const { t } = useTranslation()

	return (
		<div className="row mt-lg">

			<div className="col-md-8 col-xs-12">
				<div className="d-flex flex-column align-items-start full-height">
					<h2 className="typo heading fs48 bold mb-no mb-no">
						{`${t("dashboard.page.title")} ${user.get("firstname")}`}
					</h2>
					<p className="mb-auto">{t("dashboard.page.text")}</p>
					<AnalyticsProvider>
						<DashboardAnalytics t={t} {...props}/>
					</AnalyticsProvider>
				</div>
			</div>
			<div className="col-md-4 col-xs-12">
				<DashboardIllustration />
			</div>

		</div>
	)
}
DashboardWelcome.labelForError = "Tableau de bord"

export default withErrorManager(DashboardWelcome)

import React from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router-dom"
import ResizeObserver from "react-resize-observer"
import { withTranslation } from "components/hoc/withTranslation"
import { withErrorManager } from "pinpo-web-framework"
import PINLink from "components/shared/PINLink"
import { Colors, PINPill } from "pinpo-ui-kit"
import { Icon } from "@iconify/react"
import HeaderButton from "./HeaderButton"
import DomainHelper from "services/helpers/DomainHelper"
import DashboardPage from "components/pages/dashboard/DashboardPage"

import ContextContent from "./ContextContent"
import HelpContent from "components/parts/chat-room/chat/help-overlay/HelpContent"
import Config from "services/Config"
import CalendarPopIn from "components/parts/chat-room/qualification-work-space/CalendarPopIn"
import { withToasts } from "contexts/ToastsManagerContext"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"
import { withConversation } from "contexts/conversations/ConversationsContext"
import { withWaitingEngagements } from "contexts/waiting-engagements/WaitingEngagementsContext"
import PulsingErrorPill from "components/shared/ui/PulsingErrorPill"

class ChatHeader extends React.Component {

	static labelForError = "Header du chat"

	// State and Constructor

	state = {
		redirect: "",
	}

	// Private Methods

	canCloseEngagement = (engagement) => (
		engagement && engagement.get("contactRequest")
			.has("lastQualificationDate")
	)

	/* eslint-disable-next-line complexity */
	computeIconsButtons = (engagement) => {
		const { redirect } = this.state
		const setRedirect = (redir) => this.setState({ redirect: redir })
		const propsRedirect = this.props.redirect
		let iconsButtons = []
		if (!engagement || redirect || propsRedirect) {
			return iconsButtons
		}
		const { t } = this.props
		const contactRequest = engagement.get("contactRequest")
		const agency = contactRequest.get("agency")
		const feature = contactRequest.get("qualificationFeature")
		const context = contactRequest.getContext() || {}

		// Temporary while waiting for full migration
		const srcAgencyMail = (context.nylasMessage && context.nylasMessage.body)
			|| (contactRequest.get("nylasMessage") && JSON.parse(contactRequest.get("nylasMessage"))
				&& JSON.parse(contactRequest.get("nylasMessage")).body)
		if (srcAgencyMail) {
			iconsButtons = [
				...iconsButtons,
				{
					name: "mail",
					action: {
						type: "overlay",
						children: <iframe
							title="Original Mail" srcDoc={srcAgencyMail}
							className="main-iframe full-width"
						/>,
						onClick: this.props.toggleMail,
						isOpen: this.props.isEmailOpen,
						iconProps: {
							icon: "mdi:email-receive",
							style: { color: Colors.mustaYellow, fontSize: "4.8rem" },
						},
						title: t("qualification.chat.room.email.title"),
					},
				},
			]
		}

		let sections = context.sections || []
		const description = engagement.get("currentConversation")
			.get("script")
			.get("description")
		if (description) {
			sections = [
				{
					title: t("chat.header.context.description.title"),
					content: description,
				},
				...sections,
			]
		}
		if (sections.length > 0) {
			iconsButtons = [
				...iconsButtons,
				{
					name: "document",
					action: {
						type: "overlay",
						children: <ContextContent sections={sections}/>,
						iconProps: {
							icon: "mdi:file-document",
							style: { color: Colors.mustaYellow, fontSize: "4.8rem" },
						},
						title: t("qualification.chat.room.context.title"),
					},
				},
			]
		}

		const faqUrl = engagement.get("currentConversation")
			.get("script")
			.get("faqUrl")
		if (faqUrl) {
			iconsButtons = [
				...iconsButtons,
				{
					name: "info",
					action: {
						type: "overlay",
						children: <iframe
							title="Q&A" src={faqUrl} className="main-iframe full-width"
						/>,
						iconProps: {
							icon: "mdi:information",
							style: { color: Colors.mustaYellow, fontSize: "4.8rem" },
						},
						title: t("qualification.chat.room.faq.title"),
					},
				},
			]
		}

		// Help button
		if (Config.HelpMenu.isActive) {
			iconsButtons.push({
				name: "help",
				action: {
					type: "overlay",
					children: <HelpContent
						agency={agency} feature={feature} engagement={engagement}
						setRedirect={setRedirect}
					/>,
					iconSmall: (
						<Icon
							icon="mdi:help-circle-outline"
							style={{ color: Colors.mustaYellow, fontSize: "1.8rem" }}
						/>
					),
					iconLarge: (
						<Icon
							icon="mdi:help-circle-outline"
							style={{ color: Colors.mustaYellow, fontSize: "4.8rem" }}
						/>
					),
					title: t("qualification.chat.room.help.title"),
				},
			})
		}

		return iconsButtons
	}

	// Lifecycle

	render() {
		const { redirect } = this.state
		const {
			onResize,
			isMessagesLiveReloadUp,
			isWaitingEngagementsLiveReloadUp,
			isLockFetching,
			t,
		} = this.props
		const currentSession = this.props.getCurrentSession()
		if (redirect && !currentSession) {
			return (<Redirect to={redirect}/>)
		}
		const { engagement } = { ...currentSession }
		const contactRequest = engagement && engagement.get("contactRequest")
		const icons = this.computeIconsButtons(engagement)
		const tags = DomainHelper.getTagsForContactRequest(contactRequest)
		return (
			<div className="chat-header">
				<ResizeObserver
					onResize={onResize}
				/>
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex">
						{icons.map((iconData, i) => (
							<HeaderButton
								key={i}
								iconData={iconData}
								className="mv-xxxs mr-sm"
							/>
						))}
						{icons.length === 0
							? [{}, {}, {}].map((emptyIcon, i) => (
								<div
									className={`rounded-icon-button rounded-circle placeholder mv-xxxs d-inline-block mr-sm`}
									key={i}
								/>
							))
							: (
								<CalendarPopIn/>
							)
						}
					</div>
					<div className="pr-sm pl-sm">
						{engagement && (
							<div className="">
								<div className="d-flex flex-wrap align-items-center">
									<Icon
										icon={
											DomainHelper
												.getDomainIcon(engagement.get("contactRequest"))
										}
										style={{ fontSize: "1.8rem", color: Colors.coal }}
									/>
									<h1 className={`ml-xs mb-no mr-sm typo semibold fs16 ellipsis`}>
										{
											engagement.get("contactRequest")
												.get("qualificationFeature")
												.get("friendlyName")
										}
									</h1>
									{tags.map((tag, i) => (
										<PINPill
											key={i} text={tag}
											className={`mv-xxxs${i < tags.length - 1 ? " mr-xs" : ""}`}
										/>
									))}
								</div>
							</div>
						)}
					</div>
					<div className="d-flex justify-content-end align-items-center flex-wrap">
						{(!isWaitingEngagementsLiveReloadUp || !isMessagesLiveReloadUp) && (
							<PulsingErrorPill className="pt-xs pb-xs mr-md">
								{t("liveQueries.failure")}
							</PulsingErrorPill>
						)}
						<PINLink
							to={DashboardPage.uri()}
							className={`rounded-circle p-xxs background-mustaYellow d-flex justify-content-center align-items-center ml-sm mv-xxxs`}
							disabled={isLockFetching}
						>
							<Icon
								icon="mdi:minus-thick"
								style={{ fontSize: "1.8rem", color: Colors.snow }}
							/>
						</PINLink>
					</div>
				</div>
			</div>
		)
	}

}
ChatHeader.propTypes = {
	redirect: PropTypes.string,
	t: PropTypes.func.isRequired,
	toggleMail: PropTypes.func.isRequired,
	onResize: PropTypes.func.isRequired,
	isEmailOpen: PropTypes.bool,
	isMessagesLiveReloadUp: PropTypes.bool,
	isWaitingEngagementsLiveReloadUp: PropTypes.bool,
	isLockFetching: PropTypes.bool,
	getCurrentSession: PropTypes.bool,
}

export default withTranslation()(withErrorManager(withToasts(withCurrentEngagements(
	withConversation(withWaitingEngagements(ChatHeader))
))))

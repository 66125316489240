import React from "react"
import PropTypes from "prop-types"
import { Colors, PINMessageSender } from "pinpo-ui-kit"
import { Icon } from "@iconify/react"
import { withErrorManager } from "pinpo-web-framework"

import FooterBubble from "components/shared/footer/FooterBubble"

class FooterCollapsed extends React.Component {

	static labelForError = "Bulles en excès"

	// State and Constructor
	static width = 52

	state = {
		isOpen: false,
		noAnim: true
	}

	// Private Methods

	iconColor = () => {
		const { collapsedEngagements } = this.props
		let color = Colors.nickel
		if (!collapsedEngagements || collapsedEngagements.length === 0) { return color }
		collapsedEngagements.forEach((engagementData) => {
			if (engagementData?.conversation?.messages) {
				const { messages } = engagementData.conversation
				const lastMessage = messages[messages.length - 1]
				if (lastMessage && lastMessage.sender === PINMessageSender.Lead) {
					color = Colors.failure
				}
			}
		})
		return color
	}

	onCollapsedChange = () => {
		this.setState((state) => ({ isOpen: !state.isOpen, noAnim: false }))
	}

	// Lifecycle

	componentDidMount() {
		this.setState({ noAnim: true })
	}

	render() {
		const { collapsedEngagements, onNewMessage, currentEngagement, isLockFetching } = this.props
		const { isOpen, noAnim } = this.state
		let collapsedClassName = `footer-collapsed ${isOpen ? "opened" : "closed"}`
		collapsedClassName = `${collapsedClassName}${noAnim ? " noanim" : ""}`
		return (
			<div className={ "footer-collapsed-container" }>
				<div className={`collapsed-button full-height`}
					onClick={ this.onCollapsedChange }
				>
					<Icon
						icon="bx:bxs-message-rounded"
						style={{
							color: this.iconColor(),
							fontSize: "3.2rem"
						}}
					/>
				</div>
				<div className={ collapsedClassName }>
					{ collapsedEngagements.map((engagementData, i) => (
						<FooterBubble
							key={ engagementData.engagement.id }
							engagement={ engagementData.engagement }
							conversation={ engagementData.conversation }
							currentEngagement={ currentEngagement }
							isCurrentEngagement={
								engagementData.engagement.id === (
									currentEngagement && currentEngagement.id
								)
							}
							onNewMessage={ onNewMessage }
							onClick={ this.onCollapsedChange }
							isLockFetching={isLockFetching}
						/>
					)) }
				</div>
			</div>
		)
	}

}
FooterCollapsed.propTypes = {
	collapsedEngagements: PropTypes.arrayOf(PropTypes.object.isRequired),
	onNewMessage: PropTypes.func.isRequired,
	currentEngagement: PropTypes.object,
	isLockFetching: PropTypes.bool.isRequired,
}

export default withErrorManager(FooterCollapsed)

import { ContactRequest, ContactRequestService } from "pinpo-model-kit"
import moment from "moment"

export async function fetchAvailableTimeSlotsForMonth(contactRequestId, monthDate) {
	const { isSlotsSearchAvailable, slots } = await ContactRequestService
		.getTimeSlotsForContactRequest(
			ContactRequest.createWithoutData(contactRequestId),
			moment(monthDate)
				.startOf("month")
				.toDate(),
			moment(monthDate)
				.endOf("month")
				.toDate(),
		)
	return {
		isSlotsSearchAvailable,
		slots: slots
			? slots.map((slot) => ({
				...slot,
				date: moment(slot.startAt).format("YYYY-MM-DD")
			}))
			: slots
	}
}

import React from "react"
import PropTypes from "prop-types"
import { Redirect, Route } from "react-router-dom"

export function ConditionalRoute(props) {
	const optionalRedirect = props.redirectPath ? <Redirect to={props.redirectPath}/> : null
	const attr = { ...props }
	delete attr.component
	return (
		<Route {...attr} render={(routeProps) => (
			props.condition ? <props.component {...routeProps} /> : optionalRedirect
		)}/>
	)
}
ConditionalRoute.propTypes = {
	redirectPath: PropTypes.string.isRequired,
	condition: PropTypes.bool.isRequired,
}

import React from "react"
import PropTypes from "prop-types"

const withBaseOverlay = (WrappedComponent) => class BaseOverlay extends React.Component {

	static propTypes = {
		isOpen: PropTypes.bool,
		containerClassName: PropTypes.string,
	}

	// State and Constructor

	state = {
		noAnim: true
	}

	// Private Methods

	// Lifecycle

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.noAnim && nextProps.isOpen !== this.props.isOpen) {
			this.setState(() => ({ noAnim: false }))
		}
	}

	render() {
		const { isOpen } = this.props
		const { noAnim } = this.state
		let containerClassName = this.props.containerClassName ?? ""
		containerClassName = `${containerClassName} ${isOpen ? "opened" : "closed"}`
		containerClassName = `${containerClassName}${noAnim ? " noanim" : ""}`
		const props = { ...this.props, containerClassName }
		return (<WrappedComponent { ...props } { ...this.state }/>)
	}

}

export { withBaseOverlay }

import React, { useContext, useState } from "react"

import { URIHelper } from "pinpo-web-framework"
import { PINButton, PINForm, PINTextInput } from "pinpo-ui-kit"
import { useTranslation } from "react-i18next"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import AppHeader from "components/parts/AppHeader"
import LightFooter from "components/parts/LightFooter"
import DisplayError from "components/shared/ui/DisplayError"
import { usePageTracking } from "hooks/PageTrackingHook"

function AdditionalInfoPage() {
	usePageTracking("AdditionalInfoPage")
	const { t } = useTranslation()
	const {
		saveAdditionalInfo,
	} = useContext(AuthenticationContext)
	const [error, setError] = useState()

	async function onSubmit({
		firstname,
		lastname,
	}) {
		try {
			await saveAdditionalInfo(firstname, lastname)
		} catch (e) {
			setError(t("authentication.login.page.error.unknown"))
		}
	}

	return (
		<div className="component container-pinpo d-flex flex-column min-full-vh pt-xl">
			<AppHeader path={AdditionalInfoPage.uri} title={null}/>
			<div className="additional-info-page justify-content-center">
				<h1 className="title">{t("authentication.additional.info.title")}</h1>
				{error && <DisplayError error={error}/>}
				<p className="sub-title">{t("authentication.additional.info.sub.title")}</p>
				<PINForm className="info-form" onSubmit={onSubmit} t={t} allRequired hiddenRequired>
					<div className="row d-flex justify-content-space-between mb-sm">
						<PINTextInput
							name="firstname"
							type="text"
							className="col-6"
							label={t("authentication.additional.info.firstname")}
						/>
						<PINTextInput
							name="lastname"
							type="text"
							className="col-6"
							label={t("authentication.additional.info.lastname")}
						/>
					</div>
					<PINButton
						type="submit"
						className="submit-button"
					>
						{t("authentication.additional.info.validate")}
					</PINButton>
				</PINForm>
			</div>
			<div className="flex-grow"/>
			<LightFooter/>
		</div>
	)
}

AdditionalInfoPage.uri = URIHelper.generate("/info")

export default AdditionalInfoPage

import { PINMessageData, PINMessageState } from "pinpo-ui-kit"

const MessageState = {
	...PINMessageState,
	Delivered: "delivered"
}

function getStateFromData(conversationMessageData) {
	const { sentAt, receivedAt, error } = conversationMessageData
	if (receivedAt) {
		return MessageState.Sent
	}
	if (error) {
		return MessageState.Error
	} else if (sentAt) {
		return MessageState.Sent
	}
	return MessageState.Sending
}

function getDate(dateStr) {
	return dateStr ? new Date(dateStr) : null
}

function getSender(emitterRole) {
	return emitterRole === "bot" ? "sender-operator" : `sender-${emitterRole}`
}

class Message extends PINMessageData {

	constructor(conversationMessageData) {
		const {
			text,
			sentAt,
			receivedAt,
			deliveredAt,
			deliveredToChannelAt,
			emitterRole,
			error,
			messageId
		} = conversationMessageData
		super(text)
		this.id = messageId
		this.receivedAt = getDate(receivedAt)
		this.deliveredToChannelAt = getDate(deliveredToChannelAt)
		this.deliveredAt = getDate(deliveredAt)
		this.emitterRole = emitterRole
		this.error = error
		this._sentAt = getDate(sentAt)
		this._state = getStateFromData(conversationMessageData)
		this._sendingId = null
		// Legacy fields
		this.sentAt = this._sentAt ? this._sentAt.getTime() / 1000 : null
		this.state = getStateFromData(conversationMessageData)
		const received = getDate(receivedAt ?? sentAt)
		this.received = received ? received.getTime() / 1000 : null
		this.sender = getSender(emitterRole)
	}

	setSendingMessageFromApp() {
		this._sendingId = `sending-${this.text}`
	}

	/**
	 * Return `true` if passed `message` is the same message sent from `this`
	 * `false` otherwise.
	 * @param {Message} message
	 * @return {Boolean}
	 */
	isSameSendingMessage(message) {
		return this._sendingId === `sending-${message.text}`
	}

}

export default Message
export {
	MessageState
}

import React, { useContext, useEffect, useState } from "react"
import { withErrorManager } from "pinpo-web-framework"
import moment from "moment"

import AnalyticsBox from "components/parts/dashboard/welcome/AnalyticsBox"
import { AnalyticsContext } from "contexts/analytics/AnalyticsContext"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { useTranslation } from "react-i18next"

function DashboardAnalytics() {
	const { t } = useTranslation()
	const { user } = useContext(AuthenticationContext)
	const {
		engagementConversationsCount,
		loadEngagementConversationsCountForOperator
	} = useContext(AnalyticsContext)
	const [analytics, setAnalytics] = useState([])

	useEffect(() => {
		const today = (new Date()).getDay()
		loadEngagementConversationsCountForOperator(
			user,
			moment()
				.subtract(today, "days")
				.toDate(),
		)
			.then(
				setAnalytics([
					{
						title: t("dashboard.analytics.qualification"),
						subtitle: t("general.date.units.week"),
						content: engagementConversationsCount,
						icon: "bx:bxs-message-rounded-check",
					},
				]),
			)
	}, [loadEngagementConversationsCountForOperator, engagementConversationsCount, t, user])

	return (
		<div className="row mb-md full-width analytics">
			{analytics.map((a, i) => (
				<div className="col-lg-6 col-md-12" key={i}>
					<AnalyticsBox
						{...a}
					/>
				</div>
			))}
		</div>
	)
}

DashboardAnalytics.labelForError = "Statistiques de l'expert"

export default withErrorManager(DashboardAnalytics)

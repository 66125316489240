import React from "react"
import PropTypes from "prop-types"
import { Trans } from "react-i18next"
import { withTranslation } from "components/hoc/withTranslation"
import i18n from "i18n"
import { PINAnchor, ErrorManager } from "pinpo-web-framework"

import ShadowedLogo from "../../shared/brand/ShadowedLogo"
import DashboardPage from "../../pages/dashboard/DashboardPage"

function reloadPage() {
	window.location.reload()
}
function CrashErrorRenderer({ children, t }) {
	// Render function for ErrorManager (using i18next)
	function renderError() {
		const mailUrl = `mailto:contact@pinpo.fr?subject=${
			encodeURIComponent("Erreur sur l'app Experts Pinpo")
		}`
		return (
			<div className="component container d-flex flex-column min-full-vh pt-xl">
				<div className="row justify-content-center mt-xl">
					<div className="d-none d-md-block col-sm-4 col-lg-2">
						<ShadowedLogo />
					</div>
					<div className="col-12 col-md-7 col-lg-5 offset-md-1 pt-xl">
						<h2 className="typo-heading-small mb-md">
							{ i18n.t("error.crash.title") }
						</h2>
						<Trans i18nKey="error.crash.text">
							<p>
								Oups ! Quelque chose ne s est pas passé comme prévu.
							</p>
							<p>
								Vous pouvez essayer de
								<span className="pinbutton inline lead" onClick={reloadPage}>
									recharger la page
								</span>.
								ou
								<PINAnchor href={DashboardPage.uri()} track>
									retourner à l accueil
								</PINAnchor>.
								Si le problème persiste,
								<PINAnchor href={mailUrl} track>contactez-nous</PINAnchor>.
							</p>
						</Trans>
					</div>
				</div>
				<div className="flex-grow"></div>
			</div>
		)
	}
	return (<ErrorManager renderError={renderError}>{children}</ErrorManager>)
}
CrashErrorRenderer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element)
	]).isRequired,
	t: PropTypes.func.isRequired
}

export default withTranslation()(CrashErrorRenderer)

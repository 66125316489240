import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import hoistNonReactStatics from "hoist-non-react-statics"
import ErrorHandlingService from "../services/ErrorHandlingService"
import uuidv4 from "uuid/v4"

const ToastsContext = createContext({})

function prepareToastText(text) {
	if (!text) {
		return null
	}
	let result = typeof text === "string" ? text : text.toString()
	result = result.replace(/^"(.*)"$/, "$1")
	return result.charAt(0)
		.toUpperCase() + result.slice(1)
}

function ToastsProvider({ children }) {
	const [toasts, setToasts] = useState([])

	const removeToast = useCallback((id) => {
		setToasts((oldToasts) => oldToasts.filter((toast) => toast.id !== id))
	}, [])

	const addToast = useCallback((type, text, subtext, duration = 10000) => {
		let toastText = text
		let toastSubtext = subtext

		if (text && typeof text === "object") {
			toastText = text.text || "Error"
			toastSubtext = text.subtext || null
		}

		const toastID = uuidv4()
		const toast = {
			type,
			text: prepareToastText(toastText),
			subtext: prepareToastText(toastSubtext),
			id: toastID,
			/* Used to maintain compatibility with PINToaster */
			creationTimestamp: toastID,
		}

		setToasts((oldToasts) => [...oldToasts, toast])

		if (duration) {
			setTimeout(() => {
				removeToast(toast.id)
			}, duration)
		}

		return () => {
			removeToast(toast.id)
		}
	}, [removeToast])

	const functions = {
		addToast,
		removeToast,
	}

	useEffect(() => {
		/*
		 * This is used to give access to the ToastsContext for classes outside
		 * of the component tree.
		 */
		ErrorHandlingService.init(addToast)
	}, [addToast])

	return (
		<ToastsContext.Provider value={{ toasts, ...functions }}>
			{children}
		</ToastsContext.Provider>
	)
}

ToastsProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

function withToasts(Component) {
	function WrapperComponent(props) {
		const context = useContext(ToastsContext)
		return (<Component {...props} {...context} />)
	}

	hoistNonReactStatics(WrapperComponent, Component)
	return WrapperComponent
}

export {
	ToastsContext,
	ToastsProvider,
	withToasts,
}

import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
	EngagementConversation,
	LeadEngagement,
	MessagingService,
	ContactRequest
} from "pinpo-model-kit"

import ErrorHandlingService from "services/ErrorHandlingService"
import i18next from "i18next"
import hoistNonReactStatics from "hoist-non-react-statics"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { useSubscriptionSecurity } from "hooks/SecureLiveQueriesHook"
import { useTranslation } from "react-i18next"
import Message from "models/Message"
import { Sentry } from "pinpo-web-framework"

const ConversationsContext = createContext({})

/**
 * Returns true if both messages have same id, false otherwise.
 * If oldMsg is the sending message of newMsg returns true.
 *
 * @param {Message} oldMsg - Message already in state.
 * @param {Message} newMsg - Message to add to state.
 * @returns {boolean}
 */
function _areMessagesIdentical(oldMsg, newMsg) {
	if (oldMsg.id != null && newMsg.id != null) {
		return oldMsg.id === newMsg.id
	}
	return oldMsg.isSameSendingMessage(newMsg)
}

function loadMessageErrorHandler(contactRequest) {
	return (error) => (
		ErrorHandlingService.errorHandlerFactory([{
			matchError: (err) => err.status === 429,
			getDescription: () => i18next.t("error.unableToGetMessages.description", {
				identifier: contactRequest.get("lead")?.identifier?.() ?? "",
			}),
			shouldBeSilent: true,
			level: "info",
		}, {
			matchError: (err) => (
				err.message.includes("ContactRequest not found")
				|| err.message.includes(`Unable to access messaging settings for ContactRequest`)
			),
			getDescription: () => i18next.t("error.unableToGetMessages.description", {
				identifier: contactRequest.get("lead")?.identifier?.() ?? "",
			}),
			shouldBeSilent: true,
			level: "info",
		}])(error)
	)
}

function ConversationsProvider({ children }) {
	const { t } = useTranslation()
	const [conversations, setConversations] = useState([])
	const [conversationsSubscription, setConversationsSubscription] = useState(null)
	const {
		user,
		isInitialized,
		logout,
	} = useContext(AuthenticationContext)

	const _loadMessagesForContactRequest = useCallback(async (contactRequest) => {
		try {
			const result = await MessagingService
				.getConversationMessages(contactRequest)
			return result.messages.map((message) => new Message(message))
		} catch (error) {
			loadMessageErrorHandler(contactRequest)(error)
			return []
		}
	}, [])

	const _formatMessagesToConversations = useCallback((contactRequest, messages) => {
		setConversations((convs) => {
			const searchResult = convs.find((c) => c.contactRequestId === contactRequest.id)
			const conversation = (searchResult || {
				messages: [],
				contactRequestId: contactRequest.id,
			})

			const messagesUnion = [...messages, ...conversation.messages]
			const outputMessages = []

			messagesUnion.forEach((message) => {
				if (!outputMessages.find((seenMsg) => (_areMessagesIdentical(message, seenMsg)))) {
					outputMessages.push(message)
				}
			})

			// By default, put at the end the ones without date
			const defaultValue = Date.now() + 1000
			conversation.messages = outputMessages.sort((a, b) => (
				(a.received || defaultValue) - (b.received || defaultValue)
			))

			return searchResult
				? convs.map((existingConversation) => (
					existingConversation.contactRequestId === conversation.contactRequestId
						? conversation
						: existingConversation
				))
				: [...convs, conversation]
		})
	}, [])

	const _loadMessagePreviewForOperator = useCallback(async (operator) => {
		try {
			const result = await MessagingService
				.getMessagesPreviewForOperator(operator)

			return result.map(({ contactRequestId, messages }) => (
				_formatMessagesToConversations(
					ContactRequest.createWithoutData(contactRequestId),
					messages.map((message) => (new Message(message)))
				)
			))
		} catch (error) {
			ErrorHandlingService.defaultErrorHandler(error)
		}
		return []
	}, [_formatMessagesToConversations])

	function _addMessageForContactRequest(contactRequest, message) {
		const updatedConversations = conversations.map((c) => {
			if (c.contactRequestId !== contactRequest.id) {
				return c
			}
			if (
				c.messages.length === 0
				|| !_areMessagesIdentical(c.messages[c.messages.length - 1], message)
			) {
				c.messages.push(message)
			}
			return c
		})
		setConversations(updatedConversations)
	}

	const loadConversationsForOperator = useCallback(async (operator) => {
		const engagementConversations = await EngagementConversation
			.Queries
			.currentConversationForOperator(operator)
			.doesNotExist("conversationSubmittedAt")
			.include(["engagement.lead", "engagement.contactRequest.qualificationFeature"])
			.find()

		for (const engagementConversation of engagementConversations) {
			const contactRequest = engagementConversation
				.get("engagement")
				.get("contactRequest")
			const messages = await _loadMessagesForContactRequest(contactRequest)
			_formatMessagesToConversations(contactRequest, messages)
		}
	}, [_formatMessagesToConversations, _loadMessagesForContactRequest])

	const loadConversationsForEngagementId = useCallback(async (engagementId) => {
		try {
			const engagement = await LeadEngagement.Queries.getEngagement(engagementId)
				.first()
			const contactRequest = engagement.get("contactRequest")

			const messages = await _loadMessagesForContactRequest(contactRequest)
			_formatMessagesToConversations(contactRequest, messages)
		} catch (error) {
			ErrorHandlingService.errorHandlerFactory([{
				matchError: (err) => err.code === 209,
				getDescription: () => t("authentication.session.expired.error"),
				getCallback: (err) => {
					if (err.code === 209) {
						logout()
					}
				},
			}, {
				matchError: () => true,
				getDescription: () => i18next.t("error.unableToLoadCurrentEngagements.description"),
			}])(error)
		}
	}, [_loadMessagesForContactRequest, _formatMessagesToConversations, logout, t])

	const subscribeToConversationForOperator = useCallback(async (operator) => {
		const newSubscription = await EngagementConversation
			.Queries
			.currentConversationForOperator(operator)
			.doesNotExist("conversationSubmittedAt")
			.subscribe()

		newSubscription.on("update", async (object) => {
			let engagement
			try {
				engagement = await LeadEngagement
					.createWithoutData(object.get("engagement").id)
					.fetchWithInclude("contactRequest")
			} catch (error) {
				Sentry.report(error)
			}

			/*
			 * If difference between last message and last update is less than 1 second,
			 * it's probably a new message.
			 */
			const isAMessageUpdate = (
				object.get("updatedAt").valueOf()
				- (object.get("lastMessageUpdate")?.valueOf() ?? 0)
			) <= 1000
			/* Check if data are still available */
			if (engagement?.has("contactRequest") && isAMessageUpdate) {
				const contactRequest = engagement.get("contactRequest")
				const messages = await _loadMessagesForContactRequest(contactRequest)
				_formatMessagesToConversations(contactRequest, messages)
			}
		})
		setConversationsSubscription((oldSubscription) => {
			if (oldSubscription) {
				oldSubscription.unsubscribe()
			}
			return newSubscription
		})
	}, [_loadMessagesForContactRequest, _formatMessagesToConversations])

	const refresh = useCallback(
		() => loadConversationsForOperator(user),
		[loadConversationsForOperator, user]
	)

	const status = useSubscriptionSecurity(conversationsSubscription, refresh)
	const isMessagesLiveReloadUp = status.isReady ? status.isUp : true

	const unsubscribeToConversationForOperator = useCallback(() => {
		setConversationsSubscription((oldConversationsSubscription) => {
			if (oldConversationsSubscription?.unsubscribe) {
				oldConversationsSubscription.unsubscribe()
			}
			return null
		})
	}, [])

	function saveDraftMessageForLead(contactRequest, draftMessage) {
		const updatedConversations = conversations.map(
			(c) => c.contactRequestId === contactRequest.id
				? {
					...c,
					draftMessage,
				}
				: c,
		)
		setConversations(updatedConversations)
	}

	async function sendMessageToLead(contactRequest, rawText) {
		const text = rawText.trim()
		const messageData = {
			text,
			emitterRole: "operator"
		}
		try {
			const message = new Message(messageData)
			message.setSendingMessageFromApp()
			_addMessageForContactRequest(contactRequest, message)

			await MessagingService.sendMessage(contactRequest, text)
		} catch (error) {
			ErrorHandlingService.errorHandlerFactory([{
				matchError: () => true,
				getDescription: () => i18next.t("error.unableToSendMessage.description", {
					trimmedText: text,
				}),
			}])(error)
			const message = new Message({
				...messageData,
				error
			})
			message.setSendingMessageFromApp()
			_addMessageForContactRequest(contactRequest, message)
		}
	}

	const getConversationByContactRequestId = useCallback((contactRequestId) => (
		conversations.find((c) => c.contactRequestId === contactRequestId)
	), [conversations])

	useEffect(() => {
		if (isInitialized && user) {
			subscribeToConversationForOperator(user)
			_loadMessagePreviewForOperator(user)
			return () => {
				unsubscribeToConversationForOperator()
			}
		}
		/* eslint-disable-next-line no-undefined */
		return undefined
	}, [
		isInitialized,
		subscribeToConversationForOperator,
		unsubscribeToConversationForOperator,
		user,
		_loadMessagePreviewForOperator
	])

	const functions = {
		loadConversationsForEngagementId,
		saveDraftMessageForLead,
		sendMessageToLead,
		getConversationByContactRequestId,
	}

	return (
		<ConversationsContext.Provider
			value={{
				conversations,
				isMessagesLiveReloadUp,
				...functions,
			}}
		>
			{children}
		</ConversationsContext.Provider>
	)
}

function withConversation(Component) {
	function WrapperComponent(props) {
		const context = useContext(ConversationsContext)
		return <Component {...props} {...context} />
	}

	hoistNonReactStatics(WrapperComponent, Component)
	return WrapperComponent
}

ConversationsProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export {
	ConversationsContext,
	ConversationsProvider,
	withConversation,
}

import { useEffect } from "react"
import { useRouteMatch } from "react-router-dom"
import { Tracking } from "pinpo-web-framework"

function usePageTracking(pageName) {
	const match = useRouteMatch()

	useEffect(() => {
		Tracking.page({ displayName: pageName, props: { match } })
	}, [match, pageName])
}

export { usePageTracking }

import React, { useCallback, useContext } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { PINButton, PINButtonStyle } from "pinpo-ui-kit"
import { withErrorManager } from "pinpo-web-framework"
import TextareaAutosize from "react-autosize-textarea"

import DisplayError from "components/shared/ui/DisplayError"
import { ConversationsContext } from "../../../../contexts/conversations/ConversationsContext"
import { replaceVariablesInString } from "../../../../utils/StringUtils"

const forbiddenChars = ["[", "]", "{", "}"]
function isDraftContainingForbiddenChars(draft) {
	for (const forbiddenChar of forbiddenChars) {
		if (draft?.includes(forbiddenChar)) {
			return true
		}
	}
	return false
}

function replaceSubstitutesIfAny(message, substitutes) {
	return replaceVariablesInString(
		message,
		substitutes,
		"{{",
		"}}"
	).result
}

function filterOnPasteText(text) {
	// Remove blank lines
	const textWithoutBlanks = text?.replace(/(\r?\n\t|\r?\n|\r\t?)/gm, " ")
	// Remove all spaces inside `{{` and `}}` if any
	let finalText = textWithoutBlanks
	const matches = textWithoutBlanks.match(/{{(.+?)}}/gm) ?? []
	for (const match of matches) {
		finalText = finalText.replace(match, match.replaceAll(" ", ""))
	}
	return finalText
}

function ChatFooter({
	engagement,
	substitutes = {},
	onDraftChange,
	onSendMessage,
}) {
	const {
		getConversationByContactRequestId,
		isMessagesLiveReloadUp
	} = useContext(ConversationsContext)
	const { t } = useTranslation()

	const onMessageChange = useCallback((message) => {
		const interpolatedMessage = replaceSubstitutesIfAny(message, substitutes)
		onDraftChange(interpolatedMessage)
	}, [substitutes, onDraftChange])

	const onChange = useCallback((event) => {
		const { value } = event.target
		onMessageChange(value)
	}, [onMessageChange])

	const onPaste = useCallback((event) => {
		event.preventDefault()
		event.stopPropagation()

		const filteredText = filterOnPasteText(event.clipboardData.getData("text") ?? "")

		/* Keep text before and after the actual selection */
		const beforeSelection = event.target.value.slice(0, event.target.selectionStart)
		const afterSelection = event.target.value.slice(event.target.selectionEnd)

		/* Set a first time this way to allow the setting of the new selection */
		event.target.value = `${beforeSelection}${filteredText}${afterSelection}`

		/* Set new selection as nothing just after the pasted text */
		event.target.selectionStart = beforeSelection.length + filteredText.length
		event.target.selectionEnd = event.target.selectionStart

		onMessageChange(`${beforeSelection}${filteredText}${afterSelection}`)
	}, [onMessageChange])

	const sendMessage = useCallback(() => {
		const contactRequest = engagement?.get("contactRequest")
		const conversation = getConversationByContactRequestId(contactRequest?.id)
		const value = conversation?.draftMessage
		if (!value) { return }
		onSendMessage(value)
		onMessageChange("")
	}, [engagement, getConversationByContactRequestId, onSendMessage, onMessageChange])

	const contactRequest = engagement?.get("contactRequest")
	const conversation = getConversationByContactRequestId(contactRequest?.id)
	const disabled = conversation ? conversation.messages.length === 0 : true
	const value = conversation ? conversation.draftMessage : ""
	const draftContainsForbiddenChars = isDraftContainingForbiddenChars(value, forbiddenChars)
	return (
		<div className="chat-footer">
			{draftContainsForbiddenChars && (
				<DisplayError className="mb-sm" error={t("chat.draft.invalidChars", {
					chars: forbiddenChars.map((c) => `"${c}"`).join(", ")
				})}/>
			)}
			<div className="chat-footer-container">
				<TextareaAutosize
					onChange={onChange}
					onPaste={onPaste}
					placeholder={t("chat.footer.input.placeholder")}
					className="input"
					rows={3}
					value={value}
					disabled={disabled}
				/>
				<div className="d-flex align-items-end">
					<div className="">
						<PINButton
							className=""
							color={PINButtonStyle.mustaYellow}
							disabled={
								!value
								|| draftContainsForbiddenChars
								|| !isMessagesLiveReloadUp
							}
							onClick={ sendMessage }
						>
							{ t("general.actions.send") }
						</PINButton>
					</div>
				</div>
			</div>
		</div>
	)
}
ChatFooter.labelForError = "Zone de saisie"
ChatFooter.propTypes = {
	onDraftChange: PropTypes.func.isRequired,
	engagement: PropTypes.object.isRequired,
	onSendMessage: PropTypes.func.isRequired,
	substitutes: PropTypes.object.isRequired
}

export default withErrorManager(ChatFooter)

import React, { Suspense } from "react"
import { BrowserRouter } from "react-router-dom"
import i18next from "i18next"
import Favicon from "react-favicon"
import { Helmet } from "react-helmet"
import { LoadingView, Segment, Sentry } from "pinpo-web-framework"

import AppRouter from "../routes/AppRouter"
import CrashErrorHandler from "./pages/errors/CrashErrorHandler"
import * as Package from "../../package.json"
import PinpoExpertAppToaster from "./parts/PinpoExpertAppToaster"
import AppContextProvider from "./hoc/AppContextProvider"
import { GenericAlerts } from "./shared/GenericAlerts"

/* Load Segment - Analytics */
if (process.env.REACT_APP_SEGMENT_KEY && process.env.REACT_APP_MODE !== "local") {
	Segment.analytics().load(process.env.REACT_APP_SEGMENT_KEY)
}

/* Environment checker */
const isProd = "production" === process.env.REACT_APP_MODE
const isDev = ["development", "staging"].includes(process.env.REACT_APP_MODE)

/* Load Sentry - Bug tracking */
const releaseSuffix = isProd ? "" : `-${process.env.REACT_APP_MODE}`
const sentryRelease = `v${Package.version}${releaseSuffix}`
if (process.env.REACT_APP_SENTRY_DSN && (isProd || isDev)) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_MODE,
		debug: process.env.REACT_APP_MODE !== "production",
		release: sentryRelease,
	})
	i18next.on("missingKey", (langs, namespace, key, res) => {
		const error = new Error(`Missing translation key "${key}"`)
		error.code = 404
		Sentry.report(error, { langs, namespace, key, res })
	})
}

const PinpoWebApp = () => (
	<Suspense fallback={(<LoadingView/>)}>
		<React.StrictMode>
			<CrashErrorHandler>
				<AppContextProvider>
					<GenericAlerts>
						<BrowserRouter>
							<div className="react-app-wrapper" version={Package.version}>
								<Helmet title="Pinpo"/>
								<PinpoExpertAppToaster/>
								<Favicon url="/favicon.png"/>
								<AppRouter/>
							</div>
						</BrowserRouter>
					</GenericAlerts>
				</AppContextProvider>
			</CrashErrorHandler>
		</React.StrictMode>
	</Suspense>
)

export default PinpoWebApp

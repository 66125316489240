import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { Redirect, withRouter } from "react-router-dom"

import { LoadingView, URIHelper } from "pinpo-web-framework"

import LoginPage from "./LoginPage"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { usePageTracking } from "hooks/PageTrackingHook"

function LogoutPage({ history }) {
	usePageTracking("LogoutPage")
	const {
		isInitialized,
		user,
		logout,
	} = useContext(AuthenticationContext)

	useEffect(() => {
		if (!isInitialized) {
			return
		}

		if (user != null) {
			logout()
		} else {
			history.replace(LoginPage.uri)
		}
	}, [history, logout, user, isInitialized])

	return (
		isInitialized && !user
			? <Redirect to="/login"/>
			: <LoadingView/>
	)
}
LogoutPage.propTypes = {
	history: PropTypes.object.isRequired,
}
LogoutPage.uri = URIHelper.generate("/logout")

export default withRouter(LogoutPage)

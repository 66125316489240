const { deepGet } = require("./ObjectUtils")

/**
 * Replace all keys between startKey and endKey in given string.
 * Use the mapObj to retrieve strings, handling dot notation.
 * String can contain conditional keys, such as OR conditions.
 * If you like to use an OR key, use the '|' separator as %key_1|key_2|...%.
 *
 * @param { string } str The string to transform
 * @param { any } object The oject containing context
 * @param { string } startKey The string used to identify the start of a variable path
 * @param { string? } endKey The string used to identify the end of a variable path. Default to
 * startKey.
 */
function replaceVariablesInString(str, object, startKey, endKey = startKey) {
	const report = { variablesCount: 0, replacementsDone: 0, allVariablesReplaced: false, result: `${str}` }
	const findRegex = new RegExp(`${startKey}.+?${endKey}`, "gm")
	const matches = str.match(findRegex) || []
	report.variablesCount = matches.length
	for (const match of matches) {
		let value
		const paths = match.slice(startKey.length, match.length - endKey.length).split("|")
		for (const path of paths) {
			value = deepGet(object, path, false)
			if (value) {
				break
			}
		}
		if (value) {
			report.result = report.result.replace(match, value)
			report.replacementsDone += 1
		}
	}
	report.allVariablesReplaced = (report.variablesCount === report.replacementsDone)

	return report
}

export {
	replaceVariablesInString
}

import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { LeadEngagement } from "pinpo-model-kit"
import { useTranslation } from "react-i18next"
import ErrorHandlingService from "services/ErrorHandlingService"
import { Sentry } from "pinpo-web-framework"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { useSubscriptionSecurity } from "../../hooks/SecureLiveQueriesHook"
import hoistNonReactStatics from "hoist-non-react-statics"
import Config from "services/Config"
import { uniqueAndSortEngagements } from "contexts/utils"
import { usePromiseQueue } from "hooks/PromiseQueueHook"

const WaitingEngagementsContext = createContext({})

function WaitingEngagementsProvider(props) {
	const { t } = useTranslation()
	const [waitingEngagements, setWaitingEngagements] = useState([])
	const [waitingEngagementsCount, setWaitingEngagementsCount] = useState(0)
	const [corruptedEngagements, setCorruptedEngagements] = useState([])
	const [subscriptionEngagements, setSubscriptionEngagements] = useState(null)
	const {
		user,
		isInitialized,
		logout,
	} = useContext(AuthenticationContext)

	const _upsertCorruptedEngagements = useCallback((engagement) => {
		setCorruptedEngagements((oldCorruptedEngagements) => {
			const newCorruptedEngagements = uniqueAndSortEngagements(
				[...oldCorruptedEngagements, engagement]
			)

			setWaitingEngagements((oldWaitingEngagements) => (
				uniqueAndSortEngagements(oldWaitingEngagements, (e) => (
					/* eslint-disable-next-line max-nested-callbacks */
					!newCorruptedEngagements.find((ce) => ce.id === e.id)
				))
			))

			return newCorruptedEngagements
		})
	}, [])

	const loadWaitingEngagements = useCallback(async () => {
		try {
			const engagements = await LeadEngagement
				.Queries
				.engagementWithoutOperator()
				.include([
					"currentConversation.script",
					"contactRequest.qualificationFeature",
					"contactRequest.agency",
				])
				.limit(Config.AwaitingLeadsLoadLimit)
				.find()
			const sortedEngagements = uniqueAndSortEngagements(engagements, (e) => (
				!corruptedEngagements.find((ce) => ce.id === e.id)
			))
			setWaitingEngagements(sortedEngagements)
		} catch (error) {
			ErrorHandlingService.errorHandlerFactory([{
				matchError: (err) => err.code === 209,
				getDescription: () => t("authentication.session.expired.error"),
				getCallback: (err) => {
					if (err.code === 209) {
						logout()
					}
				},
			}, {
				matchError: () => true,
				getDescription: () => t("error.unableToLoadUnhandledEngagements.description"),
			}])(error)
		}
	}, [t, corruptedEngagements, logout])

	const updateWaitingCount = useCallback(async () => {
		const count = await LeadEngagement
			.Queries
			.engagementWithoutOperator()
			.count()
		setWaitingEngagementsCount(count - corruptedEngagements.length)
	}, [corruptedEngagements])

	const {
		queuePromise
	} = usePromiseQueue({ limit: 1, maxLength: 2 })
	const loadAndCountWaitingEngagements = useCallback(() => {
		queuePromise(async () => {
			await Promise.all([loadWaitingEngagements(), updateWaitingCount()])
		})
	}, [loadWaitingEngagements, updateWaitingCount, queuePromise])

	const subscribeWaitingEngagements = useCallback(async () => {
		const newSubscription = await LeadEngagement
			.Queries
			.engagementWithoutOperator()
			.subscribe()

		newSubscription.on("create", loadAndCountWaitingEngagements)
		newSubscription.on("enter", loadAndCountWaitingEngagements)
		newSubscription.on("leave", loadAndCountWaitingEngagements)
		newSubscription.on("delete", loadAndCountWaitingEngagements)

		setSubscriptionEngagements((oldSubscription) => {
			if (oldSubscription) {
				oldSubscription.unsubscribe()
			}
			return newSubscription
		})
	}, [loadAndCountWaitingEngagements])

	const status = useSubscriptionSecurity(
		subscriptionEngagements,
		loadAndCountWaitingEngagements,
	)
	const isWaitingEngagementsLiveReloadUp = status.isReady ? status.isUp : true

	const unsubscribeWaitingEngagements = useCallback(() => {
		setSubscriptionEngagements((oldSubscriptionEngagements) => {
			if (oldSubscriptionEngagements?.unsubscribe) {
				oldSubscriptionEngagements.unsubscribe()
			}
			return null
		})
	}, [])

	const flagCorruptedEngagement = useCallback((engagement) => {
		const error = new Error(`Engagement ${engagement.id} is corrupted`)
		Sentry.report(error)
		_upsertCorruptedEngagements(engagement)
	}, [_upsertCorruptedEngagements])

	const functions = {
		flagCorruptedEngagement,
	}

	useEffect(() => {
		if (isInitialized && user) {
			loadAndCountWaitingEngagements()
			subscribeWaitingEngagements()
			return () => {
				unsubscribeWaitingEngagements()
			}
		}
		/* eslint-disable-next-line no-undefined */
		return undefined
	}, [
		user,
		isInitialized,
		loadAndCountWaitingEngagements,
		subscribeWaitingEngagements,
		unsubscribeWaitingEngagements,
	])

	return (
		<WaitingEngagementsContext.Provider
			value={{
				waitingEngagements,
				waitingEngagementsCount,
				isWaitingEngagementsLiveReloadUp,
				...functions,
			}}
		>
			{props.children}
		</WaitingEngagementsContext.Provider>
	)
}

WaitingEngagementsProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

function withWaitingEngagements(Component) {
	function WrapperComponent(props) {
		const context = useContext(WaitingEngagementsContext)
		return <Component {...props} {...context} />
	}

	hoistNonReactStatics(WrapperComponent, Component)
	return WrapperComponent
}

export {
	WaitingEngagementsContext,
	WaitingEngagementsProvider,
	withWaitingEngagements,
}

import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { URIHelper } from "pinpo-web-framework"

import HeaderAndQualficationFooter from "components/shared/HeaderAndQualficationFooter"

import DashboardWelcome from "components/parts/dashboard/welcome/DashboardWelcome"
import WaitingList from "components/parts/dashboard/waiting-list/WaitingList"
import { CurrentEngagementsContext } from "contexts/current-engagements/CurrentEngagementsContext"
import { usePageTracking } from "hooks/PageTrackingHook"

function DashboardPage({ history }) {
	usePageTracking("DashboardPage")

	const title = "Pinpo - Dashboard"

	const { resetCurrentSession } = useContext(CurrentEngagementsContext)

	useEffect(() => {
		resetCurrentSession()
	}, [resetCurrentSession])

	return (
		<HeaderAndQualficationFooter
			title={title}
			className="dashboard"
			contentClassName="container d-flex flex-column min-full-vh pb-md"
			path={history.location.pathname}
		>

			<div className="flex-grow">
				<DashboardWelcome/>
				<WaitingList/>
			</div>

		</HeaderAndQualficationFooter>

	)
}
DashboardPage.propTypes = {
	history: PropTypes.object.isRequired,
}
DashboardPage.uri = URIHelper.generate("/dashboard")
DashboardPage.displayName = "DashboardPage"

export default DashboardPage

import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import { LoadingView } from "pinpo-web-framework"
import LoginPage from "components/pages/authentication/LoginPage"
import LogoutPage from "components/pages/authentication/LogoutPage"
import AdditionalInfoPage from "components/pages/authentication/AdditionalInfoPage"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { GuardsRoute } from "./GuardsRoute"
import DashboardPage from "../../components/pages/dashboard/DashboardPage"

function PrivateRoute(props) {
	const {
		isInitialized,
		user,
		setPostLoginRedirection,
		isProfileComplete,
	} = useContext(AuthenticationContext)

	useEffect(() => {
		if (!user && props.path !== LogoutPage.uri) {
			setPostLoginRedirection(props.location.pathname + props.location.search)
		}
	}, [user, setPostLoginRedirection, props])

	return (
		(isInitialized)
			? (
				<GuardsRoute guards={
					[{
						condition: !user,
						uri: LoginPage.uri({ token: null }),
					}, {
						condition: user && !isProfileComplete,
						uri: AdditionalInfoPage.uri(),
					}, {
						condition: (
							user
							&& isProfileComplete
							&& props.path === AdditionalInfoPage.uri()
						),
						uri: DashboardPage.uri(),
					}]
				} {...props}/>
			)
			: <LoadingView/>
	)
}
PrivateRoute.propTypes = {
	path: PropTypes.string.isRequired,
	location: PropTypes.object.isRequired,
}

export default withRouter(PrivateRoute)

import { withTranslation } from "react-i18next"
import hoistNonReactStatics from "hoist-non-react-statics"

function withTranslationWithStatic(...args) {
	return function fixedHOC(WrappedComponent) {
		return hoistNonReactStatics(
			withTranslation(...args)(WrappedComponent),
			WrappedComponent
		)
	}
}

export { withTranslationWithStatic as withTranslation }

import React from "react"
import PropTypes from "prop-types"
import { withErrorManager } from "pinpo-web-framework"

import ShimmerForm from "./ShimmerForm"
import QualificationForm from "./QualificationForm"
import QualificationWorkSpaceIcon from "./QualificationWorkSpaceIcon"
import { withAutoSave } from "components/hoc/AutoSave"
import QualificationFlagsHelper from "services/helpers/QualificationFlagsHelper"
import IdentityForm from "./IdentityForm"
import ReviewForm from "./ReviewForm"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"

class FormContainer extends React.Component {

	static labelForError = "Formulaires de qualification"

	// State and Constructor

	state = {}

	// Private Methods

	onQualificationFlagsChange = (id, value) => {
		const currentSession = this.props.getCurrentSession()
		const flags = QualificationFlagsHelper.getFlagsToSaveAfterChange(
			[...currentSession.qualificationFlags], id, value
		)
		const { engagement } = currentSession
		const contactRequest = engagement.get("contactRequest")
		contactRequest.set("flags", flags)
		this.props.addParseObjectToQueueAndSave(contactRequest)
		this.props.updateQualificationFlags(id, value)
		this.props.updateEngagement(engagement)
	}

	iconNameForType = (formType) => {
		const typeMap = {
			identity: "mdi:account",
			qualification: "mdi:format-list-bulleted",
			validation: "mdi:star",
			review: "mdi:note-edit"
		}
		return typeMap[formType]
	}

	// Lifecycle

	componentDidUpdate(prevProps) {
		const prevIsSaving = prevProps.isSaving
		const { isSaving } = this.props
		if (isSaving !== prevIsSaving) {
			this.props.onSaveChange(isSaving)
		}
	}

	render() {
		const currentSession = this.props.getCurrentSession()
		const { formType } = this.props
		let { className } = this.props
		if (!currentSession) { return (<ShimmerForm className={ className }/>) }
		className = `${className ?? ""} qualification-form-container ${formType}`
		const attr = {
			onQualificationFlagsChange: this.onQualificationFlagsChange,
			...this.props
		}
		return (
			<div className={ className }>
				<QualificationWorkSpaceIcon
					name={ this.iconNameForType(formType) }
				/>
				{
					formType === "identity" && <IdentityForm { ...attr }/>
				}
				{
					formType === "qualification" && <QualificationForm { ...attr }/>
				}
				{
					formType === "review" && <ReviewForm { ...attr }/>
				}
			</div>
		)
	}

}
FormContainer.propTypes = {
	getCurrentSession: PropTypes.func.isRequired,
	addParseObjectToQueueAndSave: PropTypes.func.isRequired,
	updateQualificationFlags: PropTypes.func.isRequired,
	updateEngagement: PropTypes.func.isRequired,
	onSaveChange: PropTypes.func.isRequired,
	isSaving: PropTypes.bool,
	formType: PropTypes.string.isRequired,
	className: PropTypes.string,
}

export default withErrorManager(withCurrentEngagements(withAutoSave(FormContainer)))

import React from "react"

// A import ovalShadow from "pinpo-ui-kit/lib/assets/images/utils/oval-shadow.png"

// A import { PINIcon } from "pinpo-ui-kit"

const ShadowedLogo = () => (
	<div className="component">
		<img
			src={"#" /* PINIcon.getDataForIconAndColor("hoomi", "hooli")*/ }
			alt="Logo Pinpo"
			className="full-width"
		/>
		<img
			src={ "#" /* A ovalShadow */ } alt=""
			className="full-width mt-lg"
		/>
	</div>
)

export default ShadowedLogo

import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "components/hoc/withTranslation"
import { Colors, PINButton, PINButtonStyle } from "pinpo-ui-kit"
import { Icon } from "@iconify/react"
import { withErrorManager } from "pinpo-web-framework"

import { withAutoUpdate } from "components/hoc/AutoUpdate"
import MomentHelper from "services/helpers/MomentHelper"
import Config from "services/Config"
import DomainHelper from "services/helpers/DomainHelper"

class FooterEngagementAction extends React.Component {

	static labelForError = "Prise en charge d'un lead"

	// State and Constructor

	state = {}

	// Private Methods

	// Lifecycle

	render() {
		const { engagement, onTakeEngagement, disabled, t } = this.props

		if (!engagement) { return (<ActionPlaceholder />) }
		const iconTitleProps = {
			icon: DomainHelper.getDomainIcon(
				engagement.get("contactRequest")
			),
			style: {
				fontSize: "2rem",
				color: Colors.coal
			}
		}
		const lastActivityDate = engagement.get("contactRequest").get("lastActivity")
		const secondsInterval = MomentHelper.momentIntervalFromNow(lastActivityDate).asSeconds()
		let iconTimeProps = {
			icon: "mdi:clock-outline",
			style: { fontSize: "1.6rem", color: Colors.success }
		}
		if (secondsInterval >= Config.QualificationAlertTime) {
			iconTimeProps = {
				icon: "mdi:fire",
				style: { fontSize: "1.6rem", color: Colors.warning },
			}
		}
		if (secondsInterval >= Config.QualificationMaxTime) {
			iconTimeProps = {
				icon: "mdi:fire",
				style: { fontSize: "1.6rem", color: Colors.failure },
			}
		}
		return (
			<div className="footer-action-container d-flex align-items-center ml-sm">
				<div className="rounded-circle background-nickel s-md mr-md"/>
				<div className="footer-action d-flex flex-column">
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex align-items-end name-content name-action">
							<Icon { ...iconTitleProps }/>
							<span className="name ml-xs">
								{
									engagement
										.get("contactRequest")
										.get("qualificationFeature")
										.get("friendlyName")
								}
							</span>
						</div>
						<Icon { ...iconTimeProps } />
					</div>
					<PINButton
						className="no-outline"
						onClick={ () => onTakeEngagement(engagement) }
						color={ PINButtonStyle.failure }
						disabled={ disabled }
						small
					>
						{ t("footer.action.take.engagement.action") }
					</PINButton>
				</div>
			</div>
		)
	}

}
FooterEngagementAction.propTypes = {
	engagement: PropTypes.object,
	onTakeEngagement: PropTypes.func,
	disabled: PropTypes.bool,
	t: PropTypes.func.isRequired,
}

const ActionPlaceholder = () => (
	<div className="footer-action-container d-flex align-items-center ml-sm">
		<div className="rounded-circle background-nickel s-md mr-md" />
		<div
			className="footer-action placeholder d-flex align-items-center justify-content-center"
		>
			<Icon
				icon="bx:bxs-message-rounded"
				style={{
					color: Colors.nickel,
					fontSize: "3.2rem"
				}}
			/>
		</div>
	</div>
)

export default withTranslation()(
	withErrorManager(withAutoUpdate()(FooterEngagementAction))
)

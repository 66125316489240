import React from "react"
import PropTypes from "prop-types"
import { Icon } from "@iconify/react"
import { withErrorManager } from "pinpo-web-framework"
import { Colors } from "pinpo-ui-kit"

class ChatNotifications extends React.Component {

	static labelForError = "Notifications de conversation"

	static propTypes = {
		updateNotification: PropTypes.func.isRequired,
		engagement: PropTypes.object,
		chatNotifications: PropTypes.arrayOf(PropTypes.object)
	}

	// State and Constructor

	state = {
		notificationsClosing: []
	}

	// Private Methods

	onClickOnNotification = (notification) => {
		const { updateNotification } = this.props
		this.setState((state) => ({
			notificationsClosing: [...state.notificationsClosing, notification.id]
		}))
		setTimeout(() => updateNotification({ ...notification, closed: true }), 500)
	}

	// Lifecycle

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const { engagement } = this.props
		const nextEngagement = nextProps.engagement
		if (!engagement && nextEngagement) {
			this.setState({ notificationsClosing: [] })
		}
	}

	render() {
		const { chatNotifications } = this.props
		if (!chatNotifications) { return <div className="d-none" /> }
		const { notificationsClosing } = this.state
		return (
			<div className="chat-notifications-container">
				{ chatNotifications
					.filter((notification) => !notification.closed)
					.map((notification, i) => (
						<div
							className={`chat-notification ${notification.id} ${notificationsClosing.includes(notification.id) ? " closed" : ""}`}
							key={i}
						>
							<div className="d-flex">
								<div className={`delimiter mr-sm background-${notification.color ?? "mustaYellow"}`}/>
								<span className="text">{ notification.text }</span>
							</div>
							<div className="pointer"
								onClick={ () => this.onClickOnNotification(notification) }
							>
								<Icon
									icon="mdi:close"
									style={{ color: Colors.coal, fontSize: "1.4rem" }}
								/>
							</div>
						</div>
					))}
			</div>
		)
	}

}

export default withErrorManager(ChatNotifications)

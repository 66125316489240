import i18next from "i18next"

class DomainHelper {

	static getDomainIcon() {
		return "mdi:domain"
	}

	static getTagsForContactRequest(contactRequest) {
		if (!contactRequest) { return [] }

		const agencyName = contactRequest.get("agency")?.get("name")
		const tags = contactRequest.get("qualificationFeature")?.get("tags") || []

		const conversation = contactRequest.get("operatorEngagement")?.get("currentConversation")
		const type = conversation?.get("type")
		const typeKey = type ? `operator.engagement.tag.type.${type}` : ""
		const status = conversation?.get("status")
		const statusKey = status ? `operator.engagement.tag.status.${status}` : ""
		const translatedKeys = [typeKey, statusKey]
			.filter((key) => key)
			.map((key) => i18next.t(key))

		return [agencyName, ...tags, ...translatedKeys]
	}

}

export default DomainHelper

import moment from "moment"
const IllustrationsJSON = require("assets/data/illustrations.json")
class IllustrationServices {

	static getIllustration() {
		const totalIllustrations = IllustrationsJSON.length
		const weekDurationOfIllustration = 52 / totalIllustrations
		const currentWeekNumber = moment().week()
		const indexOfIllustration = Math.floor(currentWeekNumber / weekDurationOfIllustration)

		const illustrationData = IllustrationsJSON[indexOfIllustration]

		const illustrationDataPlaceholder = {
			author: "Lana Marandina",
			authorUrl: "https://dribbble.com/lanamarandina",
			illustrationUrl: "https://cdn.dribbble.com/users/1461762/screenshots/3826770/missing_summer.png"
		}

		return illustrationData || illustrationDataPlaceholder
	}

}

export default IllustrationServices

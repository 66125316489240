import React, { useCallback, useContext, useState } from "react"
import { Redirect } from "react-router-dom"
import { Icon } from "@iconify/react"
import { Colors } from "pinpo-ui-kit"
import { ErrorManager, Sentry, withErrorManager } from "pinpo-web-framework"

import ChatRoomPage from "components/pages/chat-room/ChatRoomPage"
import WaitingBox from "./WaitingBox"
import Config from "services/Config"
import { WaitingEngagementsContext } from "contexts/waiting-engagements/WaitingEngagementsContext"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { useTranslation } from "react-i18next"
import { CurrentEngagementsContext } from "contexts/current-engagements/CurrentEngagementsContext"

function WaitingList() {
	const { t } = useTranslation()
	const { user } = useContext(AuthenticationContext)
	const [redirect, setRedirect] = useState("")
	const {
		waitingEngagements,
		waitingEngagementsCount,
		flagCorruptedEngagement,
	} = useContext(WaitingEngagementsContext)
	const {
		takeCurrentEngagementForEngagementIdAndOperator,
		isLockFetching,
	} = useContext(CurrentEngagementsContext)
	const assignEngagement = useCallback(async (engagement) => {
		await takeCurrentEngagementForEngagementIdAndOperator(engagement.id, user, (error) => {
			if (!error) {
				setRedirect(ChatRoomPage.uri({ engagementId: engagement.id }))
			}
		})
	}, [
		takeCurrentEngagementForEngagementIdAndOperator,
		user,
	])
	const ignoreCorruptedEngagement = useCallback((error, info, engagement) => {
		Sentry.report(error, info)
		flagCorruptedEngagement(engagement)
	}, [flagCorruptedEngagement])
	if (redirect) {
		return (<Redirect to={redirect}/>)
	}
	const color = waitingEngagementsCount < Config.WarningAwaitingLeads
		? "mustaYellow"
		: "failure"
	return (
		<div className="row">
			<div className="waiting-list flex-column p-4">
				<div className="title-container d-flex align-items-baseline">
					<div className="d-flex align-items-center">
						<Icon
							icon="mdi:star"
							style={{ fontSize: "2rem", color: Colors.coal }}
						/>
						<h1 className="ml-sm typo fs18 mb-no">
							{t("dashboard.waiting.list.title")}
						</h1>
					</div>
					<div className="ml-sm counter-container d-flex align-items-center">
						<span className={`mr-xs color-${color}`}>
							{waitingEngagementsCount}
						</span>
						<Icon
							icon="mdi:account"
							style={{ fontSize: "2rem", color: Colors[color] }}
						/>
					</div>
				</div>
				<div className="d-flex full-vw mt-sm">
					{waitingEngagements.map((engagement, i) => (
						<ErrorManager
							key={engagement.id}
							reportError={(error, info) => (
								ignoreCorruptedEngagement(error, info, engagement)
							)}
							labelForError={WaitingBox.labelForError}
						>
							<WaitingBox
								engagement={engagement}
								onClick={assignEngagement}
								active={(i === 0) && (!isLockFetching)}
								t={t}
							/>
						</ErrorManager>
					))}
				</div>
			</div>
		</div>
	)
}

WaitingList.labelForError = "Leads en attente de qualification"

export default withErrorManager((WaitingList))


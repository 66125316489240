import React, { useCallback, useContext, useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { withErrorManager } from "pinpo-web-framework"
import ChatRoomPage from "components/pages/chat-room/ChatRoomPage"
import FooterView from "components/shared/footer/FooterView"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { WaitingEngagementsContext } from "contexts/waiting-engagements/WaitingEngagementsContext"
import { CurrentEngagementsContext } from "contexts/current-engagements/CurrentEngagementsContext"
import useCurrentEngagementsWithConversations from "contexts/useCurrentEngagementsWithConversations"
import { AudioNotificationsContext } from "contexts/notifications/AudioNotificationsContext"

function FooterContainer() {
	const { waitingEngagements } = useContext(WaitingEngagementsContext)
	const {
		getCurrentSession,
		takeCurrentEngagementForEngagementIdAndOperator,
		isLockFetching,
	} = useContext(CurrentEngagementsContext)
	const { playNotification } = useContext(AudioNotificationsContext)
	const { user } = useContext(AuthenticationContext)
	const [waitingEngagementsCount, setWaitingEngagementsCount] = useState(
		(waitingEngagements || []).length
	)
	const [redirect, setRedirect] = useState("")

	const onNewMessage = useCallback(() => {
		playNotification("new-message")
	}, [playNotification])

	const onNewLead = useCallback(() => {
		playNotification("new-lead")
	}, [playNotification])

	const onTakeEngagement = async (engagement) => {
		await takeCurrentEngagementForEngagementIdAndOperator(engagement.id, user, (error) => {
			if (!error) {
				setRedirect(ChatRoomPage.uri({ engagementId: engagement.id }))
			}
		})
	}

	const currentEngagementsWithConversation = useCurrentEngagementsWithConversations()

	useEffect(() => {
		setRedirect("")
	}, [redirect])

	useEffect(() => {
		const count = (waitingEngagements || []).length
		if (waitingEngagementsCount < count) {
			onNewLead()
		}
		setWaitingEngagementsCount(count)
	}, [waitingEngagements, onNewLead, waitingEngagementsCount])

	const currentSession = getCurrentSession()
	const childProps = {
		engagements: currentEngagementsWithConversation || [],
		currentEngagement: currentSession && currentSession.engagement,
		state: {
			waitingEngagementsCount,
			redirect,
		},
		onNewMessage,
		waitingEngagement: waitingEngagements[0] || null,
		onTakeEngagement,
		isLockFetching,
	}
	return (
		(redirect)
			? <Redirect to={redirect}/>
			: <FooterView {...childProps} />
	)
}

FooterContainer.labelForError = "Liste des qualifications en cours"
FooterContainer.placeHolder = <div className="footer-placeholder"/>

export default withErrorManager(FooterContainer)

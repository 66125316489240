import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { URIHelper, Validator } from "pinpo-web-framework"
import DisplayError from "components/shared/ui/DisplayError"
import { useTranslation } from "react-i18next"
import { PINButton, PINForm, PINTextInput } from "pinpo-ui-kit"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import LightFooter from "components/parts/LightFooter"
import AppHeader from "components/parts/AppHeader"
import { usePageTracking } from "hooks/PageTrackingHook"
import { withToasts } from "contexts/ToastsManagerContext"

function PasswordChangePage({ addToast }) {
	usePageTracking("PasswordChangePage")
	const { t } = useTranslation()
	const { changePassword } = useContext(AuthenticationContext)
	const [error, setError] = useState()

	async function onSubmit({ email }) {
		try {
			await changePassword(email)
			addToast("success", t("authentication.password.change.success", { email }), null, 10000)
		} catch (e) {
			setError(t("authentication.password.change.incorrect.email"))
		}
	}

	return (
		<div className="component container-pinpo d-flex flex-column min-full-vh pt-xl">
			<AppHeader path={PasswordChangePage.uri} title={null}/>
			<div className="password-change-page justify-content-center">
				<h1 className="title">{t("authentication.password.change.title")}</h1>
				{error && <DisplayError error={error}/>}
				<p className="sub-title">{t("authentication.password.change.use.mail")}</p>
				<PINForm className="password-change-form" onSubmit={onSubmit} t={t}>
					<PINTextInput
						name="email"
						type="email"
						validation={(value) => Validator.email(value, t)}
						placeholder={t("authentication.password.change.email")}
						hiddenRequired
					/>
					<PINButton
						type="submit"
						className="submit-button"
					>
						{t("authentication.password.change.connexion")}
					</PINButton>
				</PINForm>
			</div>
			<div className="flex-grow"/>
			<LightFooter/>
		</div>
	)
}
PasswordChangePage.propTypes = {
	addToast: PropTypes.func.isRequired,
}

PasswordChangePage.uri = URIHelper.generate("/forgot")

export default withToasts(PasswordChangePage)

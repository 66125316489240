function uniqueAndSortEngagements(engagementsArray, filterCallback = () => true) {
	// Unique
	return Array.from(new Set(engagementsArray.map((a) => a.id)))
		// Rebuild an array of engagements
		.map((id) => engagementsArray.find((a) => a.id === id))
		// Filter if any
		.filter(filterCallback)
		// Finally, sort
		.sort((e1, e2) => {
			try {
				return e1.get("contactRequest").get("lastActivity")
					- e2.get("contactRequest").get("lastActivity")
			} catch (_) {
				return 0
			}
		})
}

export {
	uniqueAndSortEngagements
}

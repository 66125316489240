class ParseObject {

	_parseObject = null

	constructor(parseObject) {
		this._parseObject = parseObject
	}

	getObject() {
		return this._parseObject
	}

	setObject(parseObject) {
		this._parseObject = parseObject
	}

}

export default ParseObject

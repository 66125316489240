import React from "react"
import PropTypes from "prop-types"
import { withErrorManager } from "pinpo-web-framework"

class TextInput extends React.Component {

	static labelForError = "Champ de formulaire"

	state = {
		keyPressError: false
	}

	// Private Methods

	setKeyPressError = () => {
		const { keyPressError } = this.state
		// Do nothing if already true
		if (keyPressError) { return }
		this.setState({ keyPressError: true })
		setTimeout(() => {
			this.setState({ keyPressError: false })
		}, 3000)
	}

	/**
	 * Will check if value is not undefined, null or "".
	 * If value is 0 it should return false.
	 */
	isEmpty = (value) => {
		const { type } = this.props
		switch (type) {
		case "number":
			return value == null || value === ""
		default:
			return !(value && value.length > 0)
		}
	}

	extraAttributes = (props) => {
		let attr = {}
		const { type, isInt } = props
		/* Allow floats to be handled */
		if (type === "number") {
			attr = {
				...attr,
				step: isInt ? 1 : 0.01
			}
		}
		return attr
	}

	// Actions

	/**
	 * Set state when a forbidden key is pressed.
	 * E.g. when a letter key is pressed when input type is number
	 */
	onKeyPress = (event) => {
		const { type } = this.props
		if (type === "number" && isNaN(parseInt(event.key, 10))) {
			this.setKeyPressError()
		}
	}

	onChange = (event) => {
		this.setState({ keyPressError: false })
		const { type } = this.props
		const eventValue = event.target.value
		const value = type === "email" ? eventValue.toLowerCase() : eventValue
		this.props.onChange(value)
	}

	// Lifecycle

	render() {
		const { keyPressError } = this.state
		const { valid, rows, value } = this.props
		const attr = {
			...this.props,
			...this.extraAttributes(this.props),
			value: this.props.value ?? "",
		}
		delete attr.valid
		delete attr.t
		let className = `input input-text`
		if (keyPressError) {
			className += " key-pressed-error"
		} else if (!this.isEmpty(value)) {
			className += valid ? " valid" : " error"
		}
		return this.props.type === "text"
			? (
				<textarea
					{ ...attr }
					onChange={this.onChange}
					rows={ rows ?? 1 }
					className={ className }
					onKeyPress={ this.onKeyPress }
				/>
			)
			: (
				<input
					{ ...attr }
					onChange={this.onChange}
					className={ className }
					onKeyPress={ this.onKeyPress }
					/* Block scrolling in number input */
					onWheel={ (event) => (event.currentTarget.blur()) }
				/>
			)
	}

}
TextInput.propTypes = {
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	valid: PropTypes.bool,
	rows: PropTypes.number,
	value: PropTypes.string,
}

export default withErrorManager(TextInput)

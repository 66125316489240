import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ErrorManager } from "pinpo-web-framework"

import AppHeader from "components/parts/AppHeader"
import FooterContainer from "components/shared/footer/FooterContainer"

const HeaderAndQualficationFooter = (props) => {
	const className = props.className || ""
	const title = props.title || ""
	const path = props.path || ""
	const contentClassName = props.contentClassName || ""
	return (
		<div className={`component ${className}`}>
			{ title && (
				<Helmet title={title} />
			)}
			<AppHeader path={ path }/>
			<ErrorManager labelForError="Page de qualification" >
				<div className={ contentClassName }>
					{ AppHeader.placeHolder }
					{ props.children }
					{ FooterContainer.placeHolder }
				</div>
			</ErrorManager>
			<FooterContainer />
		</div>
	)
}
HeaderAndQualficationFooter.propTypes = {
	className: PropTypes.string,
	contentClassName: PropTypes.string,
	title: PropTypes.string,
	path: PropTypes.string,
	children: PropTypes.node,
}

export default HeaderAndQualficationFooter

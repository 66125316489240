import React from "react"
import PropTypes from "prop-types"

function LightFooter({ className }) {
	return (
		<footer className={`row ${className || ""}`}>
			<p className="col text-center typo fs12 color-nickel mb-md mt-lg">
				Copyright © Pinpo {(new Date()).getFullYear()}
			</p>
		</footer>
	)
}
LightFooter.propTypes = {
	className: PropTypes.string,
}

export default LightFooter

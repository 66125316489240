import React from "react"
import Sound from "react-sound"
import PropTypes from "prop-types"
import { useOnceBeforeRender } from "hooks/ConstructorHooks"

function PINSound({ onFinishedPlaying, name, play }) {
	useOnceBeforeRender(() => {
		// Prevent from having humongous logs
		window.soundManager.setup({ debugMode: false })
	})
	return (
		<Sound
			autoLoad={true}
			playStatus={play ? "PLAYING" : "STOPPED"}
			url={`/sounds/${name}.mp3`}
			onFinishedPlaying={onFinishedPlaying}
		/>
	)
}

PINSound.propTypes = {
	play: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onFinishedPlaying: PropTypes.func
}

export default PINSound

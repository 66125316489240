import React from "react"
import PropTypes from "prop-types"
import QualificationWorkSpaceIcon from "./QualificationWorkSpaceIcon"

const ShimmerForm = ({ className }) => (
	<div className={ className }>
		<QualificationWorkSpaceIcon name="" />
		<div className="full-width full-height">
			<div className="shimmer-background content-100 mb-md"></div>
			<div className="shimmer-background content-75 mb-md"></div>
			<div className="shimmer-background content-90"></div>
		</div>
	</div>
)
ShimmerForm.propTypes = {
	className: PropTypes.string,
}

export default ShimmerForm

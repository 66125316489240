import React from "react"
import PropTypes from "prop-types"
import { Icon } from "@iconify/react"
import { withErrorManager } from "pinpo-web-framework"
import { Colors } from "pinpo-ui-kit"

const AnalyticsBox = (props) => {
	const { icon, title, subtitle, content, className } = props
	return (
		<div className={`dashboard-analytics-box ${className || ""}`}>
			<div className="main-content ellipsis mr-xs">
				<h2 className="title mb-no">{ title }</h2>
				<p className="subtitle mb-no">{ subtitle }</p>
				<span className="content">{ content }</span>
			</div>
			<div className="icon-content">
				<Icon icon={icon} style={{ fontSize: "4.8rem", color: Colors.snow }}/>
			</div>
		</div>
	)
}
AnalyticsBox.labelForError = "Widget statistique"
AnalyticsBox.propTypes = {
	className: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
}

export default withErrorManager(AnalyticsBox)

import React, { useContext } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import PublicReservedRoute from "./route_components/PublicReservedRoute"
import PrivateRoute from "./route_components/PrivateRoute"

import SignupPage from "components/pages/authentication/signup/SignupPage"
import LoginPage from "components/pages/authentication/LoginPage"
import LogoutPage from "components/pages/authentication/LogoutPage"
import PasswordChangePage from "components/pages/authentication/PasswordChangePage"
import CallbackCrossAuthPage from "components/pages/authentication/CallbackCrossAuthPage"

import DashboardPage from "components/pages/dashboard/DashboardPage"
import ChatRoomPage from "components/pages/chat-room/ChatRoomPage"
import ManualAssignmentPage from "components/pages/manual-assignment/ManualAssignmentPage"

import LeadMessengerPage from "components/pages/lead-messenger/LeadMessengerPage"

import Error404Page from "components/pages/errors/Error404Page"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import AdditionalInfoPage from "components/pages/authentication/AdditionalInfoPage"

function AppRouter() {
	const { postLoginRedirection } = useContext(AuthenticationContext)

	return (
		<Switch>
			{ /** Real Home Page is the Dashboard **/}
			<Redirect exact from="/" to={DashboardPage.uri()}/>

			{ /** Authentication  **/}
			<PublicReservedRoute
				exact path={SignupPage.uri()} component={SignupPage}
				redirectPath={postLoginRedirection || DashboardPage.uri()}
			/>
			<PublicReservedRoute
				exact path={LoginPage.uri()} component={LoginPage}
				redirectPath={postLoginRedirection || DashboardPage.uri()}
			/>

			<Route exact path={LogoutPage.uri()} component={LogoutPage}/>
			<Route exact path={PasswordChangePage.uri()} component={PasswordChangePage}/>

			<Route exact path={CallbackCrossAuthPage.uri()} component={CallbackCrossAuthPage}/>

			<PrivateRoute exact path={AdditionalInfoPage.uri()} component={AdditionalInfoPage}/>

			{ /** Dashboard  **/}
			<PrivateRoute exact path={DashboardPage.uri()} component={DashboardPage}/>

			{ /** ChatRoom  **/}
			<PrivateRoute exact noMessenger path={ChatRoomPage.uri()} component={ChatRoomPage}/>

			{ /** ManualAssignmentPage  **/}
			<PrivateRoute
				exact
				noMessenger
				path={ManualAssignmentPage.uri()}
				component={ManualAssignmentPage}
			/>

			{ /** LeadMessenger **/}
			<Route exact noMessenger path={LeadMessengerPage.uri()} component={LeadMessengerPage}/>

			{ /** No match **/}
			<Route component={Error404Page}/>
		</Switch>
	)
}

export default AppRouter

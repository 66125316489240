import React, { useEffect, useContext, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { PINButton } from "pinpo-ui-kit"
import { LoadingView, withErrorManager } from "pinpo-web-framework"

import { withBaseOverlay } from "./BaseOverlay"
import Config from "services/Config"
import { CurrentEngagementsContext } from "contexts/current-engagements/CurrentEngagementsContext"
import { useTranslation } from "react-i18next"

async function getBlobUrl(scriptUrl) {
	const url = `${Config.URLs.CorsProxy}/${scriptUrl}`
	try {
		const response = await fetch(url, { method: "GET" })
		const blob = await response.blob()
		return URL.createObjectURL(blob)
	} catch (error) {
		console.error(error)
		return null
	}
}

function ScriptOverlay({
	containerClassName,
}) {
	const { t } = useTranslation()
	const [iframeURL, setIframeURL] = useState(null)
	const { getCurrentSession } = useContext(CurrentEngagementsContext)
	const currentSession = getCurrentSession()
	const scriptURL = currentSession
		?.engagement
		?.get("currentConversation")
		?.get("script")
		?.get("pdfUrl")

	const reloadScript = useCallback(async () => {
		if (scriptURL) {
			// Set iframe url to `null` to unmount object and correctly reload script.
			setIframeURL(null)
			const blobURL = await getBlobUrl(scriptURL)
			setIframeURL(blobURL)
		}
	}, [scriptURL])

	/**
	 * Reload script whenever scriptURL changes.
	 */
	useEffect(() => {
		let isSubscribed = true
		if (scriptURL) {
			getBlobUrl(scriptURL).then((blobURL) => {
				if (isSubscribed) {
					setIframeURL(blobURL)
				}
			})
		}
		return () => {
			// Set iframe url to `null` to unmount object and correctly reload view.
			setIframeURL(null)
			isSubscribed = false
		}
	}, [scriptURL, reloadScript])

	return (
		<div className={`script-overlay ${containerClassName ?? ""}`}>
			<div className="full-height full-width">
				<LoadingView
					className="loading-script"
					size={100}
				/>
				{ iframeURL
					? <object
						aria-label="Script overlay"
						data={iframeURL}
						type="application/pdf"
						height="100%"
						width="100%"
						style={{ zIndex: "1010" }}
						id="script-iframe"
					/>
					: null
				}
				<PINButton
					className="refresh-button no-outline"
					onClick={reloadScript}
					small
				>
					{ t("general.actions.refresh") }
				</PINButton>
			</div>
		</div>
	)
}
ScriptOverlay.propTypes = {
	containerClassName: PropTypes.string,
}
ScriptOverlay.labelForError = "Script de qualification"

export default withErrorManager(withBaseOverlay(ScriptOverlay))

import React from "react"
import PropTypes from "prop-types"

import Linkify from "react-linkify"
import { withErrorManager } from "pinpo-web-framework"

const ContextContent = (props) => {
	const { sections } = props
	return (
		<div className="context-content pb-md">
			{ sections.map((section, i) => (
				<section className="section pt-md" key={ i }>
					<h1 className="typo-heading-small mb-sm">{ section.title }</h1>
					<Linkify properties={{ target: "_blank", rel: "noopener noreferrer" }}>
						<p style={{ whiteSpace: "pre-wrap" }}>{ section.content }</p>
					</Linkify>
					{ i < sections.length - 1 && (
						<div className="delimiter"></div>
					)}
				</section>
			)) }
		</div>
	)
}
ContextContent.labelForError = "Information de contexte"
ContextContent.propTypes = {
	sections: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default withErrorManager(ContextContent)

import React, { useCallback, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { LoadingView, withErrorManager } from "pinpo-web-framework"
import { Colors, PINButton, PINPill } from "pinpo-ui-kit"
import { Icon } from "@iconify/react"
import { withTranslation } from "components/hoc/withTranslation"

import GuruService from "services/GuruService"
import Config from "services/Config"
import { useArrayProp } from "hooks/ObjectPropHook"
import GuruSearchCard from "./GuruSearchCard"
import DashboardPage from "components/pages/dashboard/DashboardPage"
import { ToastsContext, withToasts } from "contexts/ToastsManagerContext"
import i18next from "i18next"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"
import { usePromiseResolver } from "hooks/PromiseResolver"

function HelpContent({ feature, engagement, t, resignEngagement, setRedirect }) {
	const featureGuruTags = useArrayProp(feature?.get("guruTags") ?? [])
	const [searchTerms, setSearchTerms] = useState("")
	const { addToast } = useContext(ToastsContext)

	const searchCards = useCallback(async () => {
		const tags = [...featureGuruTags, Config.Guru.GeneralTag]
			.filter((i) => (Boolean(i)))
		return await GuruService.fetchCardsForTagsAndTerms(tags, searchTerms)
	}, [featureGuruTags, searchTerms])

	const {
		data,
		error,
		isLoading
	} = usePromiseResolver(searchCards)

	/**
	 * Display toast if an error is thrown.
	 */
	useEffect(() => {
		if (error || data?.error) {
			addToast(
				"error",
				i18next.t("general.error"),
				i18next.t("service.guru.request.error"),
				5000,
			)
		}
	}, [data?.error, error, addToast])

	return (
		<div className="help-content">
			{ error
				? (
					<div className="guru-error mt-md">
						<Icon
							icon="mdi:close-octagon"
							style={{ color: Colors.failure, fontSize: "2.4rem" }}
						/>
						<span>{t("service.guru.request.error")}</span>
					</div>
				)
				: (
					<div className="d-flex align-items-center">
						<GuruSearchCard className="flex-grow" onSearchChange={setSearchTerms}/>
						{isLoading && <LoadingView size={44} className="ml-xs"/>}
					</div>
				)
			}
			{/* Need to use this fallback here as Guru can return "" inside `cards` */}
			{(data?.cards || []).map((card) => (
				<HelpCard card={card} key={card.id} />
			))}
			<ResignCardWrapped
				t={t}
				resignEngagement={resignEngagement}
				engagement={engagement}
				addToast={addToast}
				setRedirect={setRedirect}
			/>
		</div>
	)
}
HelpContent.propTypes = {
	feature: PropTypes.object,
	engagement: PropTypes.object,
	t: PropTypes.func.isRequired,
	resignEngagement: PropTypes.func.isRequired,
	addToast: PropTypes.func.isRequired,
	setRedirect: PropTypes.func.isRequired
}
HelpContent.labelForError = "Menu d'aide"

function HelpCard({ card }) {
	return (
		<div className="help-card">
			<div className="help-card-header">
				<h3 className="help-card-title">{card.preferredPhrase}</h3>
				<div className="help-card-tags">
					{(card.tags || []).map((tag) => (
						<PINPill className="ml-sm" key={tag.id} text={tag.value} />
					))}
				</div>
			</div>
			<div className="help-card-content" dangerouslySetInnerHTML={{ __html: card.content }}/>
		</div>
	)
}
HelpCard.propTypes = {
	card: PropTypes.object.isRequired,
}

function ResignCard({ t, resignEngagement, engagement, addToast, setRedirect }) {
	const [reason, setReason] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = useCallback(async () => {
		setIsLoading(true)
		setRedirect(DashboardPage.uri({}))
		await resignEngagement(engagement, true, reason, (error) => {
			if (error) {
				setIsLoading(false)
				setRedirect()
			}
		})
	}, [engagement, reason, resignEngagement, setRedirect])

	return (
		<div className="resign-card">
			<h3 className="resign-card-title">{t("chat.help.resign.title")}</h3>
			<p className="resign-card-disclosure">{t("chat.help.resign.disclosure")}</p>
			<p className="resign-card-legend">{t("chat.help.resign.legend")}</p>
			<textarea
				className="resign-card-reason"
				onChange={(e) => (setReason(e.target.value))}
			>
				{reason}
			</textarea>
			<div className="button-wrapper">
				<PINButton color="lead" onClick={onSubmit} disabled={!reason || isLoading}>
					{t("chat.help.resign.validation")}
				</PINButton>
			</div>
		</div>
	)
}
ResignCard.propTypes = {
	engagement: PropTypes.object,
	t: PropTypes.func.isRequired,
	resignEngagement: PropTypes.func.isRequired,
	addToast: PropTypes.func.isRequired,
	setRedirect: PropTypes.func.isRequired
}
ResignCard.labelForError = "Carte de libération du lead"
const ResignCardWrapped = withErrorManager(ResignCard)

export default withErrorManager(withToasts(withCurrentEngagements(withTranslation()(HelpContent))))

import React from "react"
import PropTypes from "prop-types"
import { Colors } from "pinpo-ui-kit"
import { withErrorManager } from "pinpo-web-framework"
import { Icon } from "@iconify/react"

class OpenChoicesInput extends React.Component {

	static labelForError = "Champ de formulaire"

	// State and Constructor

	state = {
		value: ""
	}

	// Private Methods

	onKeyPress = (event) => { if (event.key === "Enter") { this.onAddChoice() } }

	onInputChange = (event) => {
		const { value } = event.target
		this.setState({ value })
	}

	onAddChoice = () => {
		if (!this.state.value) { return }
		const value = this.props.value ?? []
		const newValue = [...value, this.state.value]
		this.setState({ value: "" })
		this.props.onChange(newValue)
	}

	onDeleteChoice = (choice) => {
		const value = this.props.value || []
		let newValue = value.filter((c) => c !== choice)
		if (newValue.length === 0) { newValue = null }
		this.props.onChange(newValue)
	}

	// Lifecycle

	render() {
		const className = "open-choices-container"
		const { value, id } = this.props
		return (
			<div className={ className } id={ id }>
				<div className="d-flex align-items-center open-choices-input-container">
					<input
						className="full-width input input-open-choices"
						type="text"
						value={ this.state.value }
						placeholder={ this.props.placeholder }
						onChange={ this.onInputChange }
						onKeyPress={ this.onKeyPress }
					/>
					<div
						className="pointer"
						onClick={ this.onAddChoice }
					>
						<Icon
							icon="mdi:plus-circle"
							style={{
								color: this.state.value ? Colors.nightBlack : Colors.silver,
								fontSize: "1.8rem"
							}}
						/>
					</div>
				</div>
				{ value && (
					<div
						className="open-choices-list d-flex flex-wrap mt-xs"

					>
						{ value.map((choice, index) => (
							<span
								key={ index }
								className={`pinpill color-moon d-flex align-items-center mr-xs mb-xs`}
							>
								<span className="mr-xs">{ choice }</span>
								<div
									className="pointer"
									onClick={ () => this.onDeleteChoice(choice) }
								>
									<Icon
										icon="mdi:close"
										style={{
											color: Colors.nickel,
											fontSize: "1.4rem"
										}}
									/>
								</div>
							</span>
						)) }
					</div>
				)}
			</div>
		)
	}

}
OpenChoicesInput.propTypes = {
	id: PropTypes.string,
	value: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
}

export default withErrorManager(OpenChoicesInput)

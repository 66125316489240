import React, { useEffect, useState } from "react"
import { PINAnchor, withErrorManager } from "pinpo-web-framework"
import PropTypes from "prop-types"
import IllustrationServices from "services/IllustrationServices"

const DashboardIllustration = ({ className }) => {
	const [illustrationData, setIllustrationData] = useState({})
	useEffect(() => {
		setIllustrationData(IllustrationServices.getIllustration())
	}, [])
	const { illustrationUrl, author, authorUrl } = illustrationData

	return (
		<div className={`dashboard-illustration-container ${className || ""}`}>
			<div className="dashboard-illustration-frame">
				<img src={illustrationUrl} alt="Illustration of the Week" className="illusration"/>
			</div>
			<div className="text-right">
				<PINAnchor
					href={authorUrl}
					target="_blank"
				>
					{ author }
				</PINAnchor>
			</div>
		</div>
	)
}
DashboardIllustration.labelForError = "Illustration d'accueil"
DashboardIllustration.propTypes = {
	className: PropTypes.string
}

export default withErrorManager(DashboardIllustration)

import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { WaitingEngagementsContext } from "contexts/waiting-engagements/WaitingEngagementsContext"
import { ConversationsContext } from "contexts/conversations/ConversationsContext"
import { useSubscriptionAlert } from "hooks/SecureLiveQueriesHook"
import { useNetworkStatus } from "hooks/NetworkStatusHook"

export function GenericAlerts({ children }) {
	const { isWaitingEngagementsLiveReloadUp } = useContext(WaitingEngagementsContext)
	const { isMessagesLiveReloadUp } = useContext(ConversationsContext)
	const { t } = useTranslation()

	useSubscriptionAlert(
		[isWaitingEngagementsLiveReloadUp, isMessagesLiveReloadUp],
		t("liveQueries.description")
	)
	useNetworkStatus(true)

	return children
}

import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Icon } from "@iconify/react"
import { Tracking, withErrorManager } from "pinpo-web-framework"
import LogoutPage from "components/pages/authentication/LogoutPage"
import { useTranslation } from "react-i18next"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { Colors } from "pinpo-ui-kit"

function UserMenuItem() {
	const { t } = useTranslation()
	const { user } = useContext(AuthenticationContext)
	const [state, setState] = useState({
		open: false,
		openClass: "new",
	})
	const node = useRef(null)
	const handleDocumentClick = useCallback((event) => {
		if (node.current.contains(event.target)) {
			return
		}
		toggleActions()
	}, [])

	useEffect(() => {
		if (state.open) {
			Tracking.track("Open the user menu", {})
			document.addEventListener("click", handleDocumentClick, false)
		} else {
			document.removeEventListener("click", handleDocumentClick, false)
		}

		return () => {
			if (state.open) {
				document.removeEventListener("click", handleDocumentClick, false)
			}
		}
	}, [handleDocumentClick, state.open])

	function toggleActions() {
		setState((currentState) => ({
			open: !currentState.open,
			openClass: !currentState.open ? "open" : "closed",
		}))
	}

	return (
		<div
			className={`user-menu-item ${state.openClass}`}
			onClick={toggleActions}
			ref={node}
		>
			<div className="user-menu-item-static">
				<span className="mr-sm user-menu-name">
					{`${user.get("firstname")} ${user.get("lastname")[0]}`}
				</span>
				<div className="user-menu-item-icon">
					<Icon
						icon="mdi:account"
						style={{ fontSize: "2rem" }}
						color={Colors.snow}
					/>
				</div>
			</div>
			<ul className="user-menu-item-actions">
				<li className="user-menu-item-action">
					<Link to={LogoutPage.uri()} className="user-menu-item-action full-width">
						<span>{t("general.logout")}</span>
						<Icon
							icon="mdi:power-standby"
							style={{ fontSize: "2rem" }}
							color={Colors.coal}
						/>
					</Link>
				</li>
			</ul>
		</div>
	)
}

UserMenuItem.labelForError = "Menu utilisateur"

export default withErrorManager(UserMenuItem)

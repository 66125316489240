import React from "react"
import { Redirect, Route } from "react-router-dom"
import PropTypes from "prop-types"

export function GuardsRoute(props) {
	const { guards, path } = props
	let forcedRedirect = null

	for (const guard of guards) {
		if (guard.condition && path !== guard.uri) {
			forcedRedirect = <Redirect to={guard.uri}/>
			break
		}
	}
	const attr = { ...props }
	delete attr.component
	return (
		<Route {...attr} render={(routeProps) => (
			forcedRedirect != null ? forcedRedirect : <props.component {...routeProps} />
		)}/>
	)
}

GuardsRoute.propTypes = {
	guards: PropTypes.arrayOf(PropTypes.object).isRequired,
	path: PropTypes.string.isRequired,
}

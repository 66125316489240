import { Sentry } from "pinpo-web-framework"
import i18next from "i18next"
import Parse from "parse"

let addToast = null

/**
 * Details about an error and how to display it to the user.
 * @typedef {Object} ErrorDetails
 * @property {ErrorDetails~matchError} matchError Used to determine is this
 * details match a given error.
 * @property {ErrorDetails~getDetailsText} [getTitle] Used to define a title for
 * the toast displaying the error to the user.
 * @property {ErrorDetails~getDetailsText} [getDescription] Used to define a
 * description for the toast displaying the error to the user.
 * @property {ErrorDetails~getCallback} [getCallback] Used to define a
 * callback that should be called after the handling the error.
 * @property {ErrorDetails~getCode} [getCode] Used to extract a code from the
 * original error that will be displayed next to the default toast title.
 * @property {number} [displayDuration=5000] The duration in milliseconds that
 * the toast will be displayed to user.
 * @property {boolean} [shouldBeSilent=false] Define if the error should be
 * silent to the user.
 * @property {level} [level="error"] The level of this error (visible in
 * Sentry).
 */

/**
 * A callback that shall determine is an error matches this details.
 * @callback ErrorDetails~matchError
 * @param {Error} error The original error object
 * @param {number} [guessedCode] The code that as be extract from the error
 */
/**
 * Allow to define a string that can be based on the original error,
 * guessedCode or context provided.
 * @callback ErrorDetails~getDetailsText
 * @param {Error} error The original error object
 * @param {Object} [context] Some context about where this error happened
 */
/**
 * Allow to extract a code from the original error.
 * @callback ErrorDetails~getCode
 * @param {Error} error The original error object
 */
const DefaultErrorsDetailsList = [{
	matchError: (_error, code) => [
		Parse.Error.INVALID_SESSION_TOKEN,
		Parse.Error.INVALID_ACL,
		Parse.Error.TIMEOUT,
		Parse.Error.CONNECTION_FAILED,
		Parse.Error.USERNAME_TAKEN,
		Parse.Error.EMAIL_NOT_FOUND,
		Parse.Error.SCRIPT_FAILED,
	].includes(code),
	getDescription: (_error, code) => i18next.t(`error.code.${code}.description`),
}]

const ErrorHandlingService = {
	getErrorDetailsFromList(error, errorsDetailsList, context) {
		let guessedCode = parseInt(error.message?.match(/^\w+ (\d+):/)?.[1], 10)
		if (Number.isNaN(guessedCode)) {
			guessedCode = error.code
		}
		const errorDetails = errorsDetailsList
			.find((item) => item.matchError(error, guessedCode))

		const code = errorDetails?.getCode?.(error) ?? guessedCode

		/* Code 0 will never be displayed on purpose */
		const title = errorDetails?.getTitle?.(error, code, context)
			?? i18next.t("error.generic.title", { code: code || "" }).trim()
		const description = errorDetails?.getDescription?.(error, code, context)
			?? i18next.t("error.generic.description")
		const displayDuration = errorDetails?.displayDuration
			?? 5000

		const callback = errorDetails?.getCallback?.(error, code, context)

		return {
			title,
			description,
			displayDuration,
			shouldBeSilent: errorDetails?.shouldBeSilent ?? false,
			level: errorDetails?.level ?? "error",
			callback,
		}
	},
	/**
	 * Generate an error handler based on a list of errors details and a
	 * context.
	 * @param {ErrorDetails[]} [handledErrorsDetails=[]] A list of errors details.
	 * @param {Object} [context] A context used to precise where, when or what about
	 * the error is happening.
	 * @returns {function} An error handler.
	 */
	errorHandlerFactory(handledErrorsDetails = [], context = null) {
		/**
		 * Add default errors at the end of the list. This way, errors coming
		 * from the provided list will be used before the defaults ones.
		 */
		const errorsDetailsList = [...handledErrorsDetails, ...DefaultErrorsDetailsList]
		return function errorHandler(error) {
			const errorDetails = ErrorHandlingService
				.getErrorDetailsFromList(error, errorsDetailsList, context)

			Sentry.report(error, { level: errorDetails.level })

			if (!errorDetails.shouldBeSilent) {
				if (addToast != null && typeof addToast === "function") {
					addToast(
						"error",
						errorDetails.title,
						errorDetails.description,
						errorDetails.displayDuration,
					)
				}
			}
			const errorCallback = errorDetails.callback
			if (errorCallback != null && typeof errorCallback === "function") {
				errorCallback()
			}
		}
	},
	defaultErrorHandler(error) {
		return ErrorHandlingService.errorHandlerFactory()(error)
	},
	init(addToastFunction) {
		addToast = addToastFunction
	},
	shouldDisplay404(...errors) {
		return errors.reduce((should, error) => (error && error.code === 101) || should, false)
	}
}

export default ErrorHandlingService

import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "components/hoc/withTranslation"
import { withErrorManager } from "pinpo-web-framework"
import { PINButtonStyle } from "pinpo-ui-kit"

import FormInput from "components/shared/form/FormInput"

class FormFlags extends React.Component {

	static labelForError = "Tags de qualification"

	// State and Constructor

	state = {}

	// Private Methods

	onQualificationFlagsChange = (id, value) => {
		this.props.onQualificationFlagsChange(id, value)
	}

	displayFlags = (flags, onChange) => {
		const { t, qualificationIdentifiers } = this.props
		const flagsToDisplay = [...flags]
			.filter((section) => (qualificationIdentifiers.includes(section.key)))
		return flagsToDisplay.map((section, index) => {
			const attr = {
				onChange,
				id: section.key,
				label: t(section.translationKey),
				type: "choices",
				simple: section.simple,
				oneLine: section.oneLine,
				value: section.value,
				choices: section.choices,
				t
			}
			return (
				<FormInput
					key={ index }
					{ ...attr }
					showStar
					reload
					colorSelected={PINButtonStyle.success}
				/>
			)
		})
	}

	// Lifecycle

	render() {
		const { currentSession, className = "" } = this.props
		if (!currentSession) { return <div/> }
		const { qualificationFlags } = currentSession
		return (
			<div className={`flags-form ${className}`}>
				{ this.displayFlags(qualificationFlags, this.onQualificationFlagsChange) }
			</div>
		)
	}

}
FormFlags.propTypes = {
	onQualificationFlagsChange: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	qualificationIdentifiers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
	currentSession: PropTypes.object.isRequired,
	className: PropTypes.string,
}

export default withErrorManager(withTranslation()(FormFlags))

import { useState, useContext, useEffect, useCallback } from "react"
import { ToastsContext } from "contexts/ToastsManagerContext"
import { useTranslation } from "react-i18next"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"

function secureSubscription(subscription, onConnectionChange) {
	const open = () => {
		onConnectionChange(true)
	}
	const close = () => {
		onConnectionChange(false)
	}
	const error = () => {
		onConnectionChange(false)
	}
	subscription.on("open", open)
	subscription.on("close", close)
	subscription.on("error", error)

	return () => {
		subscription.removeListener("open", open)
		subscription.removeListener("close", open)
		subscription.removeListener("error", error)
	}
}

function useSubscriptionSecurity(subscription, refresh) {
	const [justChanged, setJustChanged] = useState(false)
	const [isLive, setIsLive] = useState(false)
	const [isReady, setIsReady] = useState(false)
	const [isOnline, setIsOnline] = useState(true)
	const isUp = isOnline && isLive

	/**
	 * Be careful, a full cycle might happen between execution of `setIsLive`
	 * and execution of `setJustChanged`.
	 */
	const onSubscriptionChange = useCallback((value) => {
		setIsLive(value)
		if (isReady) {
			setJustChanged(true)
		}
	}, [isReady])

	useEffect(() => {
		if (subscription) {
			return secureSubscription(subscription, onSubscriptionChange)
		}
		/* eslint-disable-next-line no-undefined */
		return undefined
	}, [onSubscriptionChange, subscription])

	/* Control network connection */
	useEffect(() => {
		const onOnline = () => {
			setIsOnline(true)
			setJustChanged(true)
		}
		const onOffline = () => {
			setIsOnline(false)
			setJustChanged(true)
		}

		window.addEventListener("online", onOnline)
		window.addEventListener("offline", onOffline)

		return () => {
			window.removeEventListener("online", onOnline)
			window.removeEventListener("offline", onOffline)
		}
	}, [])

	/* Add delay before first check (preventing double notification at launch) */
	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsReady(true)
		}, 5000)
		return () => clearTimeout(timeout)
	}, [])

	useEffect(() => {
		if (isReady && justChanged && isUp) {
			refresh()
			setJustChanged(false)
		}
	}, [isUp, justChanged, isReady, refresh])

	/* Needed to always maintain a correct state, since `set` function are asynchronous, */
	useEffect(() => {
		if (isReady && justChanged && !isUp) {
			setJustChanged(false)
		}
	}, [isUp, justChanged, isReady])

	return { isReady, isUp }
}

function useSubscriptionAlert(subscriptionsStatus = [], description) {
	const { t } = useTranslation()
	const [removeDownToast, setRemoveDownToast] = useState(null)
	const { addToast } = useContext(ToastsContext)
	const { user, isInitialized } = useContext(AuthenticationContext)
	const isAuthenticated = Boolean(user) && isInitialized
	const [isLive, setIsLive] = useState(false)
	const [isReady, setIsReady] = useState(false)

	useEffect(() => {
		if (!isAuthenticated) {
			return
		}
		const newIsReady = subscriptionsStatus
			.reduce((isValid, status) => isValid && status != null, true)
		setIsReady(newIsReady)
		if (newIsReady) {
			setIsLive(
				subscriptionsStatus.reduce((isValid, status) => isValid && status, true)
			)
		}
	/* Checking elements of array instead of array itself */
	/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [...subscriptionsStatus, isAuthenticated])

	/* Act on up state after a down time */
	useEffect(() => {
		if (isReady && isLive && removeDownToast) {
			removeDownToast()
			setRemoveDownToast(null)
			addToast("info", description, t("liveQueries.subscription.up"), 10000)
		}
	}, [isLive, isReady, description, addToast, t, removeDownToast])

	/* Act on down state after a up time or the 5 first seconds of delay */
	useEffect(() => {
		if (isReady && !isLive && !removeDownToast) {
			const removeToastFn = addToast(
				"warning",
				description,
				t("liveQueries.subscription.down"),
				null
			)
			setRemoveDownToast(() => removeToastFn)
		}
	}, [isLive, isReady, removeDownToast, description, addToast, t])
}

export {
	useSubscriptionSecurity,
	useSubscriptionAlert,
}

import React, { useContext } from "react"
import { PINToaster } from "pinpo-ui-kit"
import { ToastsContext } from "contexts/ToastsManagerContext"
import { withErrorManager } from "pinpo-web-framework"

function PinpoExpertAppToaster() {
	const {
		removeToast,
		toasts,
	} = useContext(ToastsContext)
	return (
		<PINToaster onToastClose={removeToast} toasts={toasts}/>
	)
}

PinpoExpertAppToaster.renderError = () => (null)

export default withErrorManager(PinpoExpertAppToaster)


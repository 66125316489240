import ParseObject from "./ParseObject"

class Review extends ParseObject {

	_flagList = []

	constructor(parseReview, flagList = []) {
		super(parseReview)
		this.setFlagList(flagList)
	}

	getFlagList() {
		return this._flagList
	}

	setFlagList(flagList = []) {
		this._flagList = flagList.filter((flag) => (flag.key))
	}

}

export default Review

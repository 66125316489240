import React from "react"
import PropTypes from "prop-types"
import { PINButton, PINButtonStyle } from "pinpo-ui-kit"
import { withErrorManager } from "pinpo-web-framework"

import TextInput from "./TextInput"
import ChoicesInput from "./ChoicesInput"
import OpenChoicesInput from "./OpenChoicesInput"
import DateInput from "./DateInput"

/**
 * Component holding all input types.
 * PropsTypes are [here]{@link FormInputProps}
 */
class FormInput extends React.Component {

	static labelForError = "Champ de formulaire"

	// Constructor & State
	state = {
		value: this.props.value,
		dismissed: this.props.dismissed
	}

	// Private Methods

	getInputFromType = () => {
		let attr = {
			id: this.props.id,
			value: this.state.value,
			type: this.props.type,
			choices: this.props.choices,
			onChange: this.onChange,
			t: this.props.t,
		}

		switch (this.props.type) {
		case "choices":
			attr = {
				...attr,
				simple: this.props.simple,
				oneLine: this.props.oneLine,
				colorSelected: this.props.colorSelected,
			}
			return <ChoicesInput { ...attr }/>
		case "choice":
			attr = {
				...attr,
				simple: true,
				colorSelected: this.props.colorSelected,
			}
			return <ChoicesInput { ...attr }/>

		case "boolean":
			attr = {
				...attr,
				boolean: true,
				t: this.props.t
			}
			return <ChoicesInput { ...attr }/>

		case "email":
		case "number":
		case "text":
			attr = {
				...attr,
				disabled: this.props.disabled,
				valid: this.validator(this.state.value),
				placeholder: this.props.placeholder,
				rows: this.props.rows,
				isInt: this.props.isInt
			}
			return <TextInput { ...attr} />

		case "open-choices":
			attr = {
				...attr,
				placeholder: this.props.placeholder
			}
			return <OpenChoicesInput {...attr}/>

		case "date":
			attr = {
				...attr,
				valid: this.validator(this.state.value),
				isMeeting: this.props.isMeeting
			}
			return <DateInput {...attr} />

		default:
			return (
				<div className="typo-body-small">
					{`Type ${this.props.type} n'est pas encore pris en charge.`}
				</div>
			)
		}
	}

	getOptionalIconProps = () => ({
		name: "clear",
		color: this.state.dismissed ? "pressure" : "granit",
		size: "sm"
	})

	// Actions

	validator = (value) => this.props.validator ? this.props.validator(value) : true

	dismissInput = () => {
		this.onChange(null, true)
	}

	onChange = (newValue, dismissed = false) => {
		const { disabled } = this.props
		if (disabled) { return }
		let value = null
		if (!dismissed) { value = newValue }
		if (this.props.type === "choice") {
			value = (value && value[0]) ? value[0] : ""
		}
		this.setState({ value, dismissed })
		this.props.onChange(this.props.id, value, dismissed)
	}

	// Lifecycle

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.reload) {
			this.setState({
				value: nextProps.value,
				dismissed: nextProps.dismissed
			})
		}
	}

	/* eslint-disable-next-line complexity */
	render() {
		const input = this.getInputFromType()
		const {
			className,
			label,
			description,
			id,
			light,
			t,
			showStar,
			dismissable,
		} = this.props
		const usedClassName = className ?? ""
		return (
			<div className={`form-input-container ${usedClassName}`}>
				<div className="mb-xs">
					<div className="d-flex justify-content-between">
						<div className="d-flex align-items-center">
							<label
								htmlFor={ id }
								className={`form-label mr-sm mb-no ${light ? "light" : ""}`}
							>
								{label}
								{showStar && <span className="color-failure"> *</span>}
							</label>
						</div>
					</div>
					{description && (
						<p
							className="typo fs12 color-nickel mt-xxs"
							style={{ fontSize: "1rem", lineHeight: "1.5rem" }}
						>
							{description}
						</p>
					)}
				</div>

				{ input }

				{dismissable && (
					<div className="d-flex align-items-center justify-content-end">
						<PINButton
							className={`no-outline typo regular dismiss-button ${!this.state.dismissed && " background-smoke color-nightBlack"}`}
							color={
								this.state.dismissed
									? PINButtonStyle.mustaYellow
									: PINButtonStyle.nickel
							}
							small
							onClick={ this.dismissInput }
							type="button"
						>
							{ t("form.input.dismiss.button") }
						</PINButton>
					</div>
				)}
			</div>
		)
	}

}
FormInput.propTypes = {
	value: PropTypes.any,
	type: PropTypes.string.isRequired,
	dismissed: PropTypes.bool,
	validator: PropTypes.func,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	choices: PropTypes.arrayOf(PropTypes.string.isRequired),
	simple: PropTypes.bool,
	label: PropTypes.string,
	description: PropTypes.string,
	id: PropTypes.string,
	light: PropTypes.bool,
	t: PropTypes.func.isRequired,
	oneLine: PropTypes.bool,
	placeholder: PropTypes.string,
	rows: PropTypes.number,
	reload: PropTypes.bool,
	className: PropTypes.string,
	showStar: PropTypes.bool,
	dismissable: PropTypes.bool,
	colorSelected: PropTypes.oneOf(Object.values(PINButtonStyle)),
	isMeeting: PropTypes.bool,
	isInt: PropTypes.bool,
}

export default withErrorManager(FormInput)

import React, { useContext } from "react"
import { withRouter } from "react-router-dom"

import { ConditionalRoute } from "./ConditionalRoute"

import { LoadingView } from "pinpo-web-framework"
import { AuthenticationContext } from "../../contexts/authentication/AuthenticationContext"

function PublicReservedRoute(props) {
	const {
		user,
		isInitialized,
	} = useContext(AuthenticationContext)

	return (
		(isInitialized)
			? <ConditionalRoute condition={!user} {...props} />
			: <LoadingView/>
	)
}

export default withRouter(PublicReservedRoute)

import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "components/hoc/withTranslation"
import { withErrorManager } from "pinpo-web-framework"

import FormInput from "components/shared/form/FormInput"
import ShimmerForm from "./ShimmerForm"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"

function shouldDisableField(key) {
	return key === "email" || key === "phone"
}

class IdentityForm extends React.Component {

	static labelForError = "Formulaire d'identité"

	// State and Constructor

	state = {}

	// Private Methods

	computeIdentityFields = (lead) => {
		const { t } = this.props
		const fields = ["firstname", "lastname", "phone", "email"]
		return fields.map((key) => ({
			id: key,
			value: lead?.get(key) ?? "",
			label: t(`form.identity.${key}`),
			type: key === "email" ? "email" : "text",
			onChange: this.onChange,
			light: true,
			disabled: !lead || shouldDisableField(key)
		}))
	}

	onChange = (id, value) => {
		const { engagement } = this.props.getCurrentSession()
		if (!engagement) { return }
		engagement.get("lead").set(id, value)
		this.props.addParseObjectToQueueAndSave(engagement.get("lead"))
		this.props.updateEngagement(engagement)
	}

	// Lifecycle

	render() {
		let { className } = this.props
		const currentSession = this.props.getCurrentSession()
		if (!currentSession || !currentSession.engagement) {
			return (<ShimmerForm className={ className }/>)
		}
		const fields = this.computeIdentityFields(currentSession?.engagement?.get("lead"))
		className = `${className ?? ""} identity-form`
		return (
			<div className={ className }>
				<div className="d-flex flex-wrap">
					{ fields.map((field, index) => (
						<FormInput
							key={ index }
							{ ...field }
							className="col-6 p-2"
						/>
					)) }
				</div>
			</div>
		)
	}

}
IdentityForm.propTypes = {
	t: PropTypes.func.isRequired,
	getCurrentSession: PropTypes.func.isRequired,
	addParseObjectToQueueAndSave: PropTypes.func.isRequired,
	updateEngagement: PropTypes.func.isRequired,
	className: PropTypes.string,
}

export default withErrorManager(withTranslation()(withCurrentEngagements(IdentityForm)))

import React, { useCallback, useState, useContext, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { CurrentEngagementsContext } from "contexts/current-engagements/CurrentEngagementsContext"
import { withErrorManager } from "pinpo-web-framework"
import CalendarPopIn from "components/parts/chat-room/qualification-work-space/CalendarPopIn"
import moment from "moment-timezone"
import { PINButton } from "pinpo-ui-kit"
import DisplayError from "../ui/DisplayError"

const inputDateFormat = "YYYY-MM-DDTHH:mm"

function determineError(value, t) {
	if (!value) { return null }
	const now = moment().add(-1, "minute")
	const twoHoursFromNow = moment().add(2, "hours")
	if (moment(value).isBefore(now)) {
		return t("form.input.date.error.past")
	} else if (moment(value).isBefore(twoHoursFromNow)) {
		return t("form.input.date.error.close")
	}
	return null
}

function DateInput({
	value,
	t,
	valid,
	onChange,
	isMeeting = false
}) {
	const { getCurrentSession } = useContext(CurrentEngagementsContext)
	const currentSession = getCurrentSession()
	const [selectedSlot, setSelectedSlot] = useState(null)
	const dateInput = useRef(null)

	const isServiceUp = currentSession?.engagement
		.get("contactRequest")
		.get("qualificationFeature")
		.get("appointmentBookingServiceConfiguration")
		?.isActive ?? false

	const timezone = currentSession?.engagement
		.get("contactRequest")
		.get("qualificationFeature")
		.get("appointmentBookingServiceConfiguration")
		?.timeslotFinderConfig
		?.timezone ?? "Europe/Paris"

	const shouldDisplayCalendar = isMeeting && isServiceUp

	const error = determineError(value, t)

	const onDatePickerChange = useCallback((dateStr) => {
		setSelectedSlot(null)
		const date = dateStr
			? moment.tz(dateStr, inputDateFormat, timezone).toDate()
			: null
		onChange(date)
	}, [onChange, timezone])

	const onAvailableSlotSelected = useCallback((slot) => {
		setSelectedSlot(slot)
		onChange(new Date(slot.startAt))
	}, [onChange])

	const showPicker = useCallback(() => {
		dateInput?.current?.showPicker?.()
	}, [dateInput])

	/**
	 * Reset selected slot when value is cleared.
	 */
	useEffect(() => {
		if (!value) {
			setSelectedSlot(null)
		}
	}, [value])

	const inputValue = value
		? moment(value)
			.tz(timezone)
			.format(inputDateFormat)
		: ""

	const calendarToggleTitle = t("form.input.date.toggle.calendar")
	return (
		<div className="input input-date">
			{error && <DisplayError error={error} className="p-sm mb-sm mt-xs small"/> }
			<div className="d-flex">
				{
					!shouldDisplayCalendar
						? <div className="">
							<PINButton
								small
								onClick={showPicker}
								type="button"
								outline
								className="mr-sm calendar-toggle"
							>{calendarToggleTitle}</PINButton>
						</div>
						: <CalendarPopIn
							className="mr-sm calendar-toggle"
							buttonType="regular"
							buttonTitle={calendarToggleTitle}
							onClick={(slot) => onAvailableSlotSelected(slot)}
							selectedSlot={selectedSlot}
						/>
				}
				<input
					className={`input input-text ${valid && "valid"}`}
					value={inputValue}
					type="datetime-local"
					onChange={(event) => onDatePickerChange(event.target.value)}
					onClick={(event) => event.preventDefault()}
					onKeyDown={(event) => event.preventDefault()}
					ref={dateInput}
				/>
			</div>
		</div>
	)
}
DateInput.propTypes = {
	className: PropTypes.string,
	valid: PropTypes.bool,
	t: PropTypes.func,
	value: PropTypes.any,
	onChange: PropTypes.func,
	isMeeting: PropTypes.bool
}

export default withErrorManager(DateInput)

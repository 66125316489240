import React from "react"
import PropTypes from "prop-types"
import { PINButton, PINButtonStyle, PINSelectInput } from "pinpo-ui-kit"
import { withErrorManager } from "pinpo-web-framework"

import Config from "services/Config"

class ChoicesInput extends React.Component {

	static labelForError = "Champ de formulaire"

	// State and Constructor

	// Private Methods

	onChange = (choice) => {
		const { value, simple, boolean, t } = this.props
		let newValue

		if (boolean) {
			newValue = this.getBooleanNewValue(value, choice, t)
		} else {
			const aValue = value || []
			newValue = []

			if (simple) {
				newValue = this.getSimpleChoiceNewValue(aValue, choice)
			} else {
				newValue = this.getMultipleChoiceNewValue(aValue, choice)
			}
		}

		this.props.onChange(newValue)
	}

	getBooleanNewValue = (value, choice, t) => {
		let booleanValue
		if (typeof value !== "boolean") {
			booleanValue = choice === t("general.yes")
		} else if (
			(value && choice === t("general.yes"))
			|| (!value && choice === t("general.no"))
		) {
			booleanValue = null
		} else {
			booleanValue = choice === t("general.yes")
		}
		return booleanValue
	}

	/**
	 * Always return an array
	 */
	getSimpleChoiceNewValue = (value, choice) => {
		if (value.includes(choice)) {
			/*
			 * Means that we want to unselect this choice, but there is just one
			 * choice (simple) => return an empty array
			 */
			return []
		}
		return [choice]
	}

	getMultipleChoiceNewValue = (value, choice) => {
		if (value.includes(choice)) {
			return value.filter((c) => c !== choice)
		}
		return [...value, choice]
	}

	computeChoicesAndValueForBoolean = (booleanValue, t) => {
		const choices = [t("general.yes"), t("general.no")]
		let value = []
		if (typeof booleanValue === "boolean") {
			value = booleanValue ? [t("general.yes")] : [t("general.no")]
		}
		return { choices, value }
	}

	// Lifecycle

	render() {
		const { choices, value, oneLine, id, boolean, simple, t, colorSelected } = this.props
		const className = "input input-choices d-flex flex-wrap justify-content-between"
		let someChoices = choices
		let aValue = value
		if (boolean) {
			const result = this.computeChoicesAndValueForBoolean(value, t)
			someChoices = result.choices
			aValue = result.value
		}
		if (someChoices.length >= Config.MinListChoice && simple) {
			const options = someChoices.map((choice) => ({
				label: choice.text || choice,
				value: choice.id || choice
			}))
			return (
				<PINSelectInput
					options={options}
					emptySelection={ t("form.response.input.choices.placeholder") }
					value={aValue}
					onChange={(_, val) => this.onChange(val)}
					isSearchable
				/>
			)
		}
		aValue = aValue ?? []
		return (
			<div className={className} id={id}>
				{someChoices.map((choice, index) => (
					<PINButton
						key={index}
						color={
							aValue.indexOf(choice.id || choice) < 0
								? PINButtonStyle.nickel
								: (colorSelected ?? PINButtonStyle.mustaYellow)
						}
						small={!oneLine}
						className={
							`choice ${oneLine ? "one-line" : ""} ${aValue.indexOf(choice.id || choice) < 0 ? "background-smoke color-nightBlack" : ""}`
						}
						type="button"
						onClick={() => this.onChange(choice.id || choice)}
					>
						{choice.text || choice}
					</PINButton>
				)) }
			</div>
		)
	}

}
ChoicesInput.propTypes = {
	value: PropTypes.array,
	simple: PropTypes.bool,
	boolean: PropTypes.bool,
	t: PropTypes.func.isRequired,
	onChange: PropTypes.func,
	choices: PropTypes.array,
	id: PropTypes.string.isRequired,
	oneLine: PropTypes.bool,
	colorSelected: PropTypes.oneOf(Object.values(PINButtonStyle)),
}

export default withErrorManager(ChoicesInput)

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { withTranslation } from "components/hoc/withTranslation"
import { LoadingView, Tracking, URIHelper } from "pinpo-web-framework"
import { Redirect } from "react-router-dom"
import { PINButton, PINButtonStyle, PINTabs } from "pinpo-ui-kit"

import DashboardPage from "components/pages/dashboard/DashboardPage"
import FormContainer from "components/parts/chat-room/qualification-work-space/FormContainer"

import ChatContainer from "components/parts/chat-room/chat/ChatContainer"

import ScriptOverlay from "components/shared/overlays/ScriptOverlay"
import FooterContainer from "components/shared/footer/FooterContainer"
import ChatHeader from "components/parts/chat-room/chat/ChatHeader"
import ValidationBar from "components/parts/chat-room/qualification-work-space/ValidationBar"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { withToasts } from "contexts/ToastsManagerContext"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"
import { withConversation } from "contexts/conversations/ConversationsContext"

class ChatRoomPage extends React.Component {

	static uri = URIHelper.generate("/lead/qualification", ["engagementId"])

	displayName = "ChatRoomPage"

	state = {
		notFound: false,
		isEmailOpen: false,
		scriptOverlay: { isOpen: false },
		isSaving: false,
		scriptToggledOnce: false,
		isRecapOverlayOpen: false,
		redirect: "",
		headerHeight: 0,
		selectedTab: 0,
	}

	// Private methods

	handleFetchError = (error) => {
		// Not Found, Redirect To Dashboard
		if (error.code === 404) {
			this.setState({ notFound: true })
		}
	}

	getTabs = (t) => {
		const {
			isSaving,
			scriptOverlay,
		} = this.state
		const loadingViewClassName = `full-height ml-auto saving-animation${isSaving ? "" : " hidden"}`
		const headerClassName = scriptOverlay.isOpen ? "color-silver" : ""
		const currentSession = this.props.getCurrentSession()
		const isReviewEnabled = Boolean(currentSession && currentSession.review)
		const defaultTabs = [{
			title: t("chat.room.identity.form.title"),
			id: "identity-form",
			headerClassName,
			component: FormContainer,
			componentProps: {
				formType: "identity",
				onSaveChange: this.onSaveChange,
			},
		}, {
			title: t("chat.room.qualification.form.title"),
			id: "qualification-form",
			headerClassName,
			component: FormContainer,
			componentProps: {
				formType: "qualification",
				onSaveChange: this.onSaveChange,
			},
		}]
		const additionalComponents = [{
			title: "loading-view",
			component: LoadingView,
			componentProps: {
				className: loadingViewClassName,
				size: 40,
			},
			isHeaderOnly: true,
		}, {
			title: "script-button",
			component: this.scriptButton,
			isHeaderOnly: true,
		}]
		let tabs = [...defaultTabs]
		if (isReviewEnabled) {
			tabs = [...tabs, {
				id: "review-form",
				title: t("chat.room.review.form.title"),
				headerClassName,
				component: FormContainer,
				componentProps: {
					formType: "review",
					onSaveChange: this.onSaveChange,
				},
			}]
		}
		tabs = [...tabs, ...additionalComponents]
		return tabs
	}

	// Actions

	onHeaderResize = (rect) => {
		this.setState((state) => (
			state.headerHeight !== rect.height
				? { headerHeight: rect.height }
				: state
		))
	}

	onTabChange = (component, index) => {
		this.setState({
			selectedTab: index,
			scriptOverlay: { isOpen: false },
		})
	}

	toggleScript = () => {
		this.setState((state) => ({
			scriptOverlay: { isOpen: !state.scriptOverlay.isOpen }
		}))
	}

	toggleMail = () => {
		this.setState((state) => ({
			isEmailOpen: !state.isEmailOpen
		}))
	}

	onSaveChange = (isSaving = false) => {
		this.setState({ isSaving })
	}

	resignEngagement = () => {
		const { isSaving } = this.state
		if (isSaving) {
			return
		}
		this.setState({ isSaving: true })
		const currentSession = this.props.getCurrentSession()
		if (!currentSession) {
			return
		}
		const { engagement } = currentSession
		if (!engagement) {
			return
		}
		this.props.resignEngagement(engagement)
		this.setState({ redirect: DashboardPage.uri() })
	}

	completeEngagement = (sendNotification) => {
		const { isSaving } = this.state
		if (isSaving) {
			return
		}
		const { t } = this.props
		const { engagement } = this.props.getCurrentSession()
		if (!sendNotification && !window.confirm(t("engagement.close.confirm.text"))) {
			return
		}
		this.setState({
			isSaving: true,
			isRecapOverlayOpen: false,
		})
		this.props.completeEngagement(engagement, sendNotification)
		const redirect = DashboardPage.uri()
		this.setState({ redirect })
	}

	onOpenRecapOverlay = () => {
		this.setState({ isRecapOverlayOpen: true })
	}

	cancelRecap = () => {
		this.setState({ isRecapOverlayOpen: false })
	}

	// Components Methods

	scriptButton = () => {
		const { isOpen } = this.state.scriptOverlay
		const { t } = this.props
		return (
			<PINButton
				className={`script-button ${isOpen && "is-open"}`}
				color={PINButtonStyle.Info}
				onClick={this.toggleScript}
				outline={isOpen}
				small
			>
				{t("script.button")}
			</PINButton>
		)
	}

	// Lifecycle

	async componentDidMount() {
		Tracking.page(this)
		const {
			user,
		} = this.context
		const { engagementId } = this.props.match.params
		await this.loadData(engagementId, user)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const nextCurrentSession = nextProps.getCurrentSession()
		if (!nextCurrentSession) {
			return { scriptOverlay: { isOpen: false } }
		} else if (
			nextCurrentSession
			&& nextCurrentSession.engagement
			&& !prevState.scriptToggledOnce
		) {
			return {
				scriptToggledOnce: true,
				scriptOverlay: { isOpen: true },
			}
		}
		return null
	}

	componentDidUpdate = async (prevProps, prevState) => {
		const { engagementId } = prevProps.match.params
		const { engagementId: newEngagementId } = this.props.match.params
		const {
			user,
		} = this.context
		const {
			toasts,
		} = this.props
		// Session fetching
		if (engagementId !== newEngagementId) {
			await this.loadData(newEngagementId, user)
		}
		// Toasts reset
		let {
			isSaving,
			redirect,
		} = this.state
		const prevToasts = prevProps.toasts
		if (toasts.length > prevToasts.length && [...toasts].pop().type === "error"
			&& isSaving && redirect) {
			isSaving = false
			redirect = ""
			this.setState({
				isSaving,
				redirect,
			})
		}
	}

	loadData = async (engagementId, user) => {
		const {
			fetchSessionForOperatorAndEngagementId,
			loadConversationsForEngagementId,
		} = this.props
		await fetchSessionForOperatorAndEngagementId(user, engagementId, this.handleFetchError)
		await loadConversationsForEngagementId(engagementId)
	}

	render() {
		const {
			notFound,
			selectedTab,
			scriptOverlay,
			redirect,
			headerHeight,
		} = this.state
		if (notFound) {
			return <Redirect to={DashboardPage.uri()}/>
		}
		const currentSession = this.props.getCurrentSession()
		if (redirect) {
			return <Redirect to={redirect}/>
		}
		const { t } = this.props
		const identifier = (
			currentSession
			&& currentSession.engagement
			&& currentSession.engagement.get("lead")
			&& currentSession.engagement.get("lead")
				.identifier()
		)
		const title = identifier || t("chat.room.page.title")
		return (
			<div className="component chat-room">
				<Helmet title={title}/>
				<ChatHeader
					toggleMail={this.toggleMail}
					onResize={this.onHeaderResize}
					{...this.state}
				/>
				<div
					className="d-flex flex-column chat-room-container"
					style={{ height: `calc(100vh - ${headerHeight}px` }}
				>
					<div className="row ml-no mr-no chat-room-content">
						<div className="col-6 p-0 full-height">
							<ChatContainer toggleMail={this.toggleMail}/>
						</div>
						<div className="col-6 qualification-work-space p-0 full-height">
							<PINTabs
								className="full-height"
								contentClassName="full-height"
								tabs={this.getTabs(t)}
								selectedTab={selectedTab}
								onChange={this.onTabChange}
							/>
							<ValidationBar
								currentSession={currentSession}
								onComplete={this.completeEngagement}
								onResign={this.resignEngagement}
							/>
							<ScriptOverlay {...scriptOverlay} />
						</div>
					</div>
					{FooterContainer.placeHolder}
					<FooterContainer/>
				</div>
			</div>
		)
	}

}
ChatRoomPage.propTypes = {
	getCurrentSession: PropTypes.func.isRequired,
	resignEngagement: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	completeEngagement: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	toasts: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	fetchSessionForOperatorAndEngagementId: PropTypes.func.isRequired,
	loadConversationsForEngagementId: PropTypes.func.isRequired,
}

ChatRoomPage.contextType = AuthenticationContext

export default withTranslation()(withToasts(withCurrentEngagements(withConversation(ChatRoomPage))))

import React from "react"
import { AudioNotificationsProvider } from "contexts/notifications/AudioNotificationsContext"
import { ToastsProvider } from "contexts/ToastsManagerContext"
import { AuthenticationProvider } from "contexts/authentication/AuthenticationContext"
import { QueriesProvider } from "contexts/QueriesContextManager"
import { WaitingEngagementsProvider } from "contexts/waiting-engagements/WaitingEngagementsContext"
import { ConversationsProvider } from "contexts/conversations/ConversationsContext"
import { CurrentEngagementsProvider } from "contexts/current-engagements/CurrentEngagementsContext"
import PropTypes from "prop-types"

function AppContextProvider({ children }) {
	return (
		<AudioNotificationsProvider>
			<ToastsProvider>
				<AuthenticationProvider>
					<QueriesProvider>
						<WaitingEngagementsProvider>
							<CurrentEngagementsProvider>
								<ConversationsProvider>
									{children}
								</ConversationsProvider>
							</CurrentEngagementsProvider>
						</WaitingEngagementsProvider>
					</QueriesProvider>
				</AuthenticationProvider>
			</ToastsProvider>
		</AudioNotificationsProvider>
	)
}

AppContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export default AppContextProvider

import React, { Component } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Smooch from "smooch"
import Branch from "branch-sdk"
import { URIHelper, LoadingView, Sentry, Tracking } from "pinpo-web-framework"
import { withTranslation } from "components/hoc/withTranslation"
import Config from "services/Config"
import SmoochCustomTexts from "services/SmoochConfig"
import Error404Page from "components/pages/errors/Error404Page"
import { withToasts } from "contexts/ToastsManagerContext"

Branch.init(process.env.REACT_APP_BRANCH_KEY, {}, null)

class LeadMessengerPage extends Component {

	static uri = URIHelper.generate("/conversation")

	displayName = "LeadMessengerPage"

	state = {
		isLoading: true,
		error: false
	}

	componentDidMount() {
		Tracking.track(this)
		const locale = Config.DefaultLanguage
		Branch.data((error, data) => {
			const userId = data.data_parsed.smoochUserId
			const jwt = data.data_parsed.smoochJWT
			const appId = data.data_parsed.appId || Config.DeprecatedSmoochAppId
			if (userId && jwt && appId) {
				// PersonalizeTexts
				if (data.data_parsed.headerText) {
					SmoochCustomTexts.headerText = data.data_parsed.headerText
				}
				// Open Smooch Chat
				try {
					Smooch.init({
						appId,
						userId,
						jwt,
						locale,
						customText: SmoochCustomTexts,
						embedded: true
					}).then(() => {
						this.setState(() => ({ isLoading: false }))
					})
					Smooch.render(document.getElementById("card-smooch"))
				} catch (err) {
					this.handleError(err)
				}
			} else {
				this.handleError(error)
			}
		})
	}

	handleError = (error) => {
		const { t, addToast } = this.props
		if (error) {
			console.error(error)
			Sentry.report(error)
		}
		this.setState(() => ({ error: true }))
		addToast("error", t("general.error"), t("lead.messenger.page.error.text"))
	}

	render() {
		const { t } = this.props
		const { error, isLoading } = this.state
		if (error) { return (<Error404Page { ...this.props }/>) }
		return (
			<div id="card-smooch" className="card-smooch">
				<Helmet
					title={t("lead.messenger.page.title")}
					link={[
						{ rel: "favicon", href: "/messenger.png" }
					]}
				/>
				{isLoading && (
					<LoadingView />
				)}
			</div>
		)
	}

}
LeadMessengerPage.propTypes = {
	t: PropTypes.func.isRequired,
	addToast: PropTypes.func.isRequired,
}

export default withTranslation()(withToasts(LeadMessengerPage))

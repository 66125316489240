import React from "react"
import PropTypes from "prop-types"
import RoundedIconButton from "components/shared/RoundedIconButton"
import OverlayCard from "components/shared/overlays/OverlayCard"

class HeaderButton extends React.Component {

	// State and Constructor

	state = {
		isOpen: false
	}

	// Private Methods

	onClick = () => {
		const { type, onClick } = this.props.iconData.action
		if (type === "overlay") { this.setState({ isOpen: true }) }
		if (onClick) { onClick() }
	}

	onClose = () => {
		const { onClick } = this.props.iconData.action
		this.setState({ isOpen: false })
		if (onClick) { onClick() }
	}

	// Lifecycle

	componentDidMount() {
		/* eslint-disable-next-line new-cap */
		this.UNSAFE_componentWillReceiveProps(this.props)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { isOpen } = nextProps.iconData.action
		if (typeof isOpen === "boolean") {
			this.setState((prevState) => {
				if (prevState.isOpen !== isOpen) {
					return { isOpen }
				}
				return {}
			})
		}
	}

	render() {
		const { iconData, className } = this.props
		return (
			<div className="header-button-container d-inline-block">
				<RoundedIconButton
					name={iconData.action?.iconProps?.icon}
					icon={iconData.action?.iconSmall}
					color="mustaYellow"
					size="sm"
					className={className}
					onClick={this.onClick}
				/>
				{ iconData.action.type === "overlay" && (
					<OverlayCard
						onClose={ this.onClose }
						{ ...this.state }
						className=""
						{ ...iconData.action}
					/>
				)}
			</div>
		)
	}

}
HeaderButton.propTypes = {
	className: PropTypes.string,
	iconData: PropTypes.object.isRequired,
}

export default HeaderButton

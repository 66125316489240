import React, { useState, useEffect, createContext, useCallback } from "react"
import PropTypes from "prop-types"
import PINSound from "components/shared/PINSound"

const AudioNotificationsContext = createContext({})

function AudioNotificationsProvider({ children }) {
	const [hasUserInteracted, setHasUserInteracted] = useState(false)
	const [soundName, setSoundName] = useState(null)
	useEffect(() => {
		function onClick() {
			if (!hasUserInteracted) {
				setHasUserInteracted(true)
			}
		}
		if (!hasUserInteracted) {
			window.document.addEventListener("click", onClick)
		}
		return () => {
			window.document.removeEventListener("click", onClick)
		}
	}, [hasUserInteracted])

	const playNotification = useCallback((name) => {
		if (!soundName) {
			setSoundName(name)
		}
	}, [soundName])

	const onFinishedPlaying = () => {
		setSoundName(null)
	}

	return (
		<AudioNotificationsContext.Provider value={{ playNotification }}>
			<>
				{children}
				<PINSound
					name="notification2"
					onFinishedPlaying={ onFinishedPlaying }
					play={ hasUserInteracted && soundName === "new-message" }
				/>
				<PINSound
					name="notification"
					onFinishedPlaying={ onFinishedPlaying }
					play={ hasUserInteracted && soundName === "new-lead" }
				/>
			</>
		</AudioNotificationsContext.Provider>
	)
}

AudioNotificationsProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export {
	AudioNotificationsProvider,
	AudioNotificationsContext
}

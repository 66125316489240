import React, { useContext, useState } from "react"

import { URIHelper } from "pinpo-web-framework"
import { PINButton, PINForm, PINTextInput } from "pinpo-ui-kit"
import { useTranslation } from "react-i18next"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import AppHeader from "components/parts/AppHeader"
import LightFooter from "components/parts/LightFooter"
import DisplayError from "components/shared/ui/DisplayError"
import { usePageTracking } from "hooks/PageTrackingHook"

function SignupPage() {
	usePageTracking("SignupPage")
	const { t } = useTranslation()
	const {
		signup,
		login,
	} = useContext(AuthenticationContext)
	const [error, setError] = useState()

	async function onSubmit({
		email,
		password,
	}) {
		try {
			await signup(email, password)
			await login(email, password)
		} catch (e) {
			if (e.code === "invalid_signup") {
				setError(t("authentication.signup.page.error.invalid.credentials"))
			} else if (e.code === "invalid_password") {
				setError(t("authentication.signup.page.error.invalid.password"))
			}
		}
	}

	return (
		<div className="component container-pinpo d-flex flex-column min-full-vh pt-xl">
			<AppHeader path={SignupPage.uri} title={null}/>
			<div className="signup-page justify-content-center">
				<h1 className="title">{t("authentication.signup.title")}</h1>
				{error && <DisplayError error={error}/>}
				<p className="sub-title">{t("authentication.signup.use.mail")}</p>
				<PINForm
					className="signup-form"
					onSubmit={onSubmit}
					t={t}
					allRequired
					hiddenRequired
				>
					<PINTextInput
						name="email"
						type="email"
						placeholder={t("authentication.signup.email")}
					/>
					<PINTextInput
						name="password"
						type="password"
						placeholder={t("authentication.signup.password")}
					/>
					<PINButton
						type="submit"
						className="submit-button"
					>
						{t("authentication.signup.connexion")}
					</PINButton>
				</PINForm>
				<p className="mt-5 legal">{t("authentication.signup.legal")}</p>
			</div>
			<div className="flex-grow"/>
			<LightFooter/>
		</div>
	)
}

SignupPage.uri = URIHelper.generate("/signup")

export default SignupPage

import { useContext } from "react"
import { CurrentEngagementsContext } from "contexts/current-engagements/CurrentEngagementsContext"
import { ConversationsContext } from "contexts/conversations/ConversationsContext"

function useCurrentEngagementsWithConversations() {
	const { currentEngagementsForOperator } = useContext(CurrentEngagementsContext)
	const { conversations } = useContext(ConversationsContext)

	return currentEngagementsForOperator
		.filter((engagement) => (
			engagement.get("currentOperator") && engagement.get("currentConversation")
		))
		.map((engagement) => {
			const conversation = conversations.find((c) => (
				engagement.get("contactRequest")?.id === c.contactRequestId
			))
			return {
				engagement,
				conversation,
			}
		})
}

export default useCurrentEngagementsWithConversations

import React from "react"
import PropTypes from "prop-types"
import { Colors, PINButton, PINButtonStyle, PINPill } from "pinpo-ui-kit"
import { Icon } from "@iconify/react"

import { withAutoUpdate } from "components/hoc/AutoUpdate"
import DomainHelper from "services/helpers/DomainHelper"
import PINInterval from "components/shared/PINInterval"
import Config from "services/Config"
import MomentHelper from "services/helpers/MomentHelper"

const WaitingBox = ({ engagement, active, t, onClick }) => {
	const className = `waiting-box col-md-5 col-xl-4 shadow-heavy ${active ? " active" : ""}`
	const iconTitleProps = {
		icon: DomainHelper.getDomainIcon(engagement.get("contactRequest")),
		style: { fontSize: "2.4rem", color: Colors.coal },
	}
	const contactRequest = engagement.get("contactRequest")
	const lastActivityDate = contactRequest.get("lastActivity")
	const secondsInterval = MomentHelper.momentIntervalFromNow(lastActivityDate).asSeconds()
	let iconTimeProps = {
		icon: "mdi:clock-outline",
		style: { fontSize: "1.6rem", color: Colors.success }
	}
	if (secondsInterval >= Config.QualificationAlertTime) {
		iconTimeProps = {
			icon: "mdi:fire",
			style: { fontSize: "1.6rem", color: Colors.warning }
		}
	}
	if (secondsInterval >= Config.QualificationMaxTime) {
		iconTimeProps = {
			icon: "mdi:fire",
			style: { fontSize: "1.6rem", color: Colors.failure }
		}
	}
	const tags = DomainHelper.getTagsForContactRequest(contactRequest)
	return (
		<div className={ className }>
			<div className="title-row d-flex justify-content-between align-items-center">
				<div className="mb-sm d-flex align-items-center">
					<Icon { ...iconTitleProps }/>
					<h1 className="ml-xs mb-no typo fs24 semibold">
						{
							engagement
								.get("contactRequest")
								.get("qualificationFeature")
								.get("friendlyName")
						}
					</h1>
				</div>
				<div className="d-flex align-items-end">
					<PINInterval
						date={ lastActivityDate }
						className="typo fs12 semibold mr-xs"
					/>
					<Icon {...iconTimeProps} />
				</div>
			</div>

			<div className="pills-container">
				{ tags.map((tag, i) => (
					<PINPill
						className={`mb-xs d-inline-block ${i < tags.length - 1 ? "mr-xs" : ""}`}
						text={ tag }
						key={ i }
					/>
				)) }
			</div>

			<p
				className="typo fs12 color-granit pt-sm pb-sm"
			>
				{
					engagement
						.get("currentConversation")
						.get("script")
						.get("context")
				}
			</p>

			<div className="d-flex justify-content-end">
				<PINButton
					className="ml-auto"
					color={ PINButtonStyle.Success }
					disabled={ !active }
					onClick={ () => onClick(engagement) }
				>
					{ t("waiting.list.box.engage.button") }
				</PINButton>
			</div>
		</div>
	)
}
WaitingBox.labelForError = "Lead en attente"
WaitingBox.propTypes = {
	engagement: PropTypes.object.isRequired,
	active: PropTypes.bool,
	t: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
}

export default withAutoUpdate()(WaitingBox)

import React, { Component } from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import { Redirect } from "react-router-dom"

import { LoadingView, Tracking, URIHelper } from "pinpo-web-framework"
import { withTranslation } from "components/hoc/withTranslation"

import { LeadEngagement, QualificationService } from "pinpo-model-kit"

import ChatRoomPage from "components/pages/chat-room/ChatRoomPage"
import DashboardPage from "components/pages/dashboard/DashboardPage"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import { withToasts } from "contexts/ToastsManagerContext"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"

class ManualAssignmentPage extends Component {

	static uri = URIHelper.generate("/lead/manual-assignment")

	state = {
		redirectUrl: "",
	}

	async componentDidMount() {
		Tracking.page(this)
		await this.triggerManualAssignment()
	}

	async triggerManualAssignment() {
		let redirectUrl = ""
		const { user } = this.context
		const {
			t,
			takeCurrentEngagementForEngagementIdAndOperator,
			addToast,
		} = this.props
		try {
			if (!user.isAdmin) {
				throw new Error(`Only administrators are allowed to assign themselves conversations this way`)
			}
			const { engagementId } = queryString.parse(this.props.location.search)
			if (!engagementId) {
				throw new Error("Param engagementId is missing")
			}
			const engagement = LeadEngagement.createWithoutData(engagementId)
			await engagement.fetch()
			if (engagement && engagement.get("conversationPending") === true) {
				await takeCurrentEngagementForEngagementIdAndOperator(
					engagement.id,
					user,
					(error) => {
						if (error) {
							addToast(
								"error",
								t("manual.assignment.error.text"),
								error.message,
								5000
							)
						}
					}
				)
			} else {
				await QualificationService.manuallyAssignEngagement(engagement)
				redirectUrl = ChatRoomPage.uri({ engagementId })
			}
		} catch (error) {
			addToast("error", t("manual.assignment.error.text"), error.toString(), 5000)
			redirectUrl = DashboardPage.uri({})
		}

		this.setState({ redirectUrl })
	}

	render() {
		const { redirectUrl } = this.state
		return (
			redirectUrl
				? <Redirect to={redirectUrl}/>
				: <LoadingView/>
		)
	}

}
ManualAssignmentPage.propTypes = {
	t: PropTypes.func.isRequired,
	takeCurrentEngagementForEngagementIdAndOperator: PropTypes.func.isRequired,
	addToast: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
}
ManualAssignmentPage.contextType = AuthenticationContext

export default withTranslation()(withCurrentEngagements(withToasts(ManualAssignmentPage)))

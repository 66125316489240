import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { withTranslation } from "components/hoc/withTranslation"

import { Tracking } from "pinpo-web-framework"

import ShadowedLogo from "components/shared/brand/ShadowedLogo"
import LightFooter from "components/parts/LightFooter"

import DashboardPage from "components/pages/dashboard/DashboardPage"

class Error404Page extends React.Component {

	displayName = "Error404Page"

	componentDidMount() {
		Tracking.page(this)
	}

	render() {
		const { t } = this.props
		return (
			<div className="component container d-flex flex-column full-vh pt-xl">
				<div className="row justify-content-center mt-xl">
					<div className="d-none d-md-block col-sm-4 col-lg-2">
						<ShadowedLogo />
					</div>
					<div className="col-12 col-md-7 col-lg-5 offset-md-1 pt-xl">
						<h2 className="typo-heading-small mb-md">
							{ t("error.404.title") }
						</h2>
						<p className="mb-no">
							{ t("error.404.text") }
						</p>
						<Link to={ DashboardPage.uri({}) }>{ t("error.404.goHome") }</Link>
					</div>
				</div>
				<div className="flex-grow"></div>
				<LightFooter />
			</div>
		)
	}

}
Error404Page.propTypes = {
	t: PropTypes.func.isRequired,
}

export default withTranslation()(Error404Page)

import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "components/hoc/withTranslation"
import { PINButton, PINButtonStyle } from "pinpo-ui-kit"
import { withErrorManager } from "pinpo-web-framework"

import FormInput from "components/shared/form/FormInput"
import { withCurrentEngagements } from "contexts/current-engagements/CurrentEngagementsContext"

class ReviewForm extends React.Component {

	static labelForError = "Formulaire de review"

	// State and Constructor

	state = {}

	// Private Methods

	isDone = () => {
		const currentSession = this.props.getCurrentSession()
		return currentSession.review.getObject().get("status") === "done"
	}

	// Actions

	onChange = (id, value) => {
		// Do nothing is review is done
		if (this.isDone()) { return }
		const { review } = this.props.getCurrentSession()
		let flags = review.getObject().get("flags") || []
		if (value) {
			flags = [...flags, id]
		} else {
			flags = flags.filter((flag) => (flag !== id))
		}
		review.getObject().set("flags", flags)
		this.updateReview(review)
	}

	onCancel = () => (this.setReviewStatus("pending"))

	onDone = () => (this.setReviewStatus("done"))

	setReviewStatus = (status) => {
		const { review, engagement } = this.props.getCurrentSession()
		review.getObject().set("status", status)
		engagement.get("contactRequest").set("reviewStatus", status)
		engagement.get("contactRequest").set("reviewFlags", (status === "done"
			? review.getObject().get("flags")
			: []
		))
		this.props.addParseObjectToQueueAndSave(engagement.get("contactRequest"))
		this.updateReview(review)
	}

	updateReview = (review) => {
		this.props.addParseObjectToQueueAndSave(review.getObject())
		this.props.updateEngagementReview(review)
	}

	// Lifecycle

	render() {
		const { getCurrentSession, t } = this.props
		const { review } = getCurrentSession()
		return (
			<form className="qualification-form">
				{
					review.getFlagList().map((flag) => (
						<FormInput
							{ ...flag }
							key={ flag.key }
							id={ flag.key }
							onChange={ this.onChange }
							value={ (review.getObject().get("flags") || []).includes(flag.key) }
							t={ t }
							disabled={ this.isDone() }
							type="boolean"
						/>
					))
				}
				<div className="mb-lg" >
					<div className="mt-md d-flex justify-content-center align-items-center">
						<PINButton
							className="no-outline mr-sm"
							color={ PINButtonStyle.Destructive }
							type="button"
							outline
							large
							onClick={ this.onCancel }
							disabled={ !this.isDone() }
						>
							{ t("general.cancel") }
						</PINButton>
						<PINButton
							className="no-outline"
							color={ PINButtonStyle.Success }
							type="button"
							large
							disabled={ this.isDone() }
							onClick={ this.onDone }
						>
							{ t("review.form.submit.button") }
						</PINButton>
					</div>
				</div>
			</form>
		)
	}

}
ReviewForm.propTypes = {
	getCurrentSession: PropTypes.func.isRequired,
	addParseObjectToQueueAndSave: PropTypes.func.isRequired,
	updateEngagementReview: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
}

export default withErrorManager(withTranslation()(withCurrentEngagements(ReviewForm)))

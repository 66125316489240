import React from "react"
import { Icon } from "@iconify/react"
import PropTypes from "prop-types"

const ExpendableIconButton = ({
	className = "",
	text,
	icon,
	style,
	onClick,
	disabled = false,
	expend = false
}) => {
	const computedClassName = `${style} ${className} ${disabled ? "disabled" : ""} ${expend ? "hovered" : ""}`
	return (
		<button
			className={`expendable-icon-button pinbutton ${computedClassName}`}
			disabled={disabled}
			hover={true}
			onClick={onClick}
		>
			<div className="icon d-flex align-items-center m-auto">
				<Icon
					icon={icon}
					width="2rem"
					height="2rem"
					color="#FFFFFF"
				/>
			</div>
			<span className="text">{text}</span>
		</button>
	)
}

ExpendableIconButton.propTypes = {
	className: PropTypes.string,
	style: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	icon: PropTypes.element.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	expend: PropTypes.bool
}

export default ExpendableIconButton

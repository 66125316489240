import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { PINMessage, PINMessageSender, Colors } from "pinpo-ui-kit"
import { Icon } from "@iconify/react"
import { LeadEngagement } from "pinpo-model-kit"
import { withErrorManager } from "pinpo-web-framework"
import PINLink from "components/shared/PINLink"

import ChatRoomPage from "components/pages/chat-room/ChatRoomPage"
import PINInterval from "components/shared/PINInterval"
import DashboardPage from "components/pages/dashboard/DashboardPage"
import { useTranslation } from "react-i18next"

function FooterBubble({
	engagement,
	isCurrentEngagement,
	conversation = {},
	isLockFetching,
	onNewMessage,
}) {
	const [_messages, setMessages] = useState([])
	const { messages = [] } = conversation

	useEffect(() => {
		if (_messages.length < messages.length) {
			const aLastMessage = [...messages].pop()
			if (aLastMessage?.sender === PINMessageSender.Lead && _messages.length > 0) {
				onNewMessage()
			}
			setMessages(messages)
		}
	}, [_messages, messages, onNewMessage])

	const { t } = useTranslation()

	const link = isCurrentEngagement
		? DashboardPage.uri()
		: ChatRoomPage.uri({ engagementId: engagement.id })
	const usedMessage = getLastMessage(conversation)
	return (
		<div className="footer-bubble-container full-height mr-sm d-inline-block">
			<PINLink
				disabled={isLockFetching}
				to={link}
			>
				<div className={`engagement-bubble full-height full-width`}>
					<div className={`name-content d-flex justify-content-between align-items-end pl-xs pr-xs`}>
						<span className="name ellipsis">
							{engagement?.get("lead")?.identifier?.() ?? t("general.loading")}
						</span>
						<span className="timer d-flex align-items-center">
							<PINInterval
								timestamp={usedMessage?.received}
								className="ellipsis mr-xs"
							/>
							<Icon {...getClockIconProps(usedMessage)}/>
						</span>
					</div>
					<div
						className={`message-content ellipsis radius-sm ${usedMessage ? usedMessage.sender : "shimmer-background-stream"} ${isCurrentEngagement ? "current" : ""}`}
					>
						<span>{usedMessage?.text}</span>
					</div>
				</div>
			</PINLink>
		</div>
	)
}
FooterBubble.labelForError = "Bulle de lead"
FooterBubble.propTypes = {
	engagement: PropTypes.instanceOf(LeadEngagement),
	isCurrentEngagement: PropTypes.bool.isRequired,
	isLockFetching: PropTypes.bool.isRequired,
	onNewMessage: PropTypes.func,
	conversation: PropTypes.shape({
		messages: PropTypes.arrayOf(PINMessage),
		contactRequestId: PropTypes.string.isRequired
	})
}

function getLastMessage(conversation = {}) {
	const { messages } = conversation
	if (!messages?.length) { return null }
	const receivedMessages = messages.filter((msg) => (msg.received))
	return messages[receivedMessages.length - 1]
}

function getClockIconProps(message) {
	const iconColor = (message && message.sender === PINMessageSender.Lead)
		? Colors.failure
		: Colors.nightBlack
	return { icon: "mdi:clock-outline", style: { color: iconColor, fontSize: "1.6rem" } }
}

export default withErrorManager(FooterBubble)

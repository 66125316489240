import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Tracking } from "pinpo-web-framework"
import { Colors } from "pinpo-ui-kit"
import PINLink from "components/shared/PINLink"
import { Icon } from "@iconify/react"

import DashboardPage from "../pages/dashboard/DashboardPage"
import UserMenuItem from "./UserMenuItem"
import { useTranslation } from "react-i18next"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import LoginPage from "components/pages/authentication/LoginPage"
import SignupPage from "components/pages/authentication/signup/SignupPage"
import AdditionalInfoPage from "components/pages/authentication/AdditionalInfoPage"
import { WaitingEngagementsContext } from "contexts/waiting-engagements/WaitingEngagementsContext"
import { ConversationsContext } from "contexts/conversations/ConversationsContext"
import PulsingErrorPill from "components/shared/ui/PulsingErrorPill"

import pinpoLogo from "../../assets/images/logo/pinpo-corp-strike.svg"

function AppHeader({
	path,
	title = "app.header.home",
}) {
	const { t } = useTranslation()
	const {
		user,
		isProfileComplete,
	} = useContext(AuthenticationContext)
	const { isWaitingEngagementsLiveReloadUp } = useContext(WaitingEngagementsContext)
	const { isMessagesLiveReloadUp } = useContext(ConversationsContext)

	function isActive(uri) {
		return uri === path ? " active" : ""
	}

	function onClick(name) {
		Tracking.track(name)
	}

	function defineTopRightLink() {
		if (path === LoginPage.uri) {
			return (
				<PINLink to={SignupPage.uri({})}>
					{t("authentication.app.header.signup.cta")}
				</PINLink>
			)
		} else if (path === AdditionalInfoPage.uri) {
			return null
		}
		return (
			<PINLink to={LoginPage.uri({})}>
				{t("authentication.app.header.signin.cta")}
			</PINLink>
		)
	}

	return (
		<div className="component">
			<header
				className="navbar navbar-expand-md fixed-top navbar-light app-header"
				onClick={onClick}
			>
				<button
					className="navbar-toggler" type="button" data-toggle="collapse"
					data-target="#app-navigation" aria-controls="app-navigation"
					aria-expanded="false" aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"/>
				</button>
				<div className="navbar-brand app-header-logo">
					<PINLink to={DashboardPage.uri({})} track>
						<img src={pinpoLogo} alt="" height="47rem"/>
					</PINLink>
				</div>
				<div
					className="collapse navbar-collapse app-header-center-menu"
					id="app-navigation"
				>
					<div className="navbar-nav app-header-center-menu-items-wrapper">
						<PINLink
							className={`mr-lg nav-item nav-link app-header-menu-item${
								isActive(DashboardPage.uri({}))
							}`}
							to={DashboardPage.uri({})}
						>
							{title && <span>{t(title)}</span>}
						</PINLink>
					</div>
					<div className="navbar-nav app-header-right-menu">
						{user != null && isProfileComplete
							? (
								<>
									{(
										!isWaitingEngagementsLiveReloadUp
										|| !isMessagesLiveReloadUp
									) && (
										<PulsingErrorPill className="mr-md">
											{t("liveQueries.failure")}
										</PulsingErrorPill>
									)}
									<UserMenuItem user={user}/>
									<Icon
										icon="ic:baseline-settings"
										style={{ fontSize: "2.4rem" }}
										color={Colors.silver}
									/>
								</>
							)
							: defineTopRightLink()
						}
					</div>
				</div>
			</header>
		</div>
	)
}
AppHeader.labelForError = "Header"
AppHeader.placeHolder = (<div className="app-header-placeholder"/>)
AppHeader.propTypes = {
	path: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}

export default AppHeader

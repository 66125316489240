import { useEffect } from "react"
import auth0 from "auth0-js"
import { URIHelper } from "pinpo-web-framework"

function CallbackCrossAuthPage() {
	useEffect(() => {
		const auth0Client = new auth0.WebAuth({
			domain: "auth.pinpo.fr",
			redirectUri: `${window.location.origin}/login`,
			clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
		})
		auth0Client.crossOriginVerification()
	}, [])
	return null
}

CallbackCrossAuthPage.uri = URIHelper.generate("/callback-cross-auth")

export default CallbackCrossAuthPage

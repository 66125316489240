import React from "react"
import { useTranslation } from "react-i18next"
import { PINButtonStyle } from "pinpo-ui-kit"
import PropTypes from "prop-types"
import ExpendableIconButton from "components/shared/ExpendableIconButton"
import QualificationFlagsHelper from "services/helpers/QualificationFlagsHelper"
import FormResponseHelper from "services/helpers/FormResponseHelper"
import {
	EngagementConversation,
	LeadEngagement,
	ContactRequestEvent,
	ContactRequest
} from "pinpo-model-kit"
import _ from "lodash"
import { useParseQuery } from "contexts/QueriesContextManager"
const helper = new QualificationFlagsHelper()

function usePreviousConversation(engagement) {
	const query = useParseQuery(EngagementConversation, {
		/* Temporary add cacheLifetime to prevent query from running twice */
		cacheLifetime: { amount: 1, unit: "hour" },
		filters: { engagement: LeadEngagement.createWithoutData(engagement.id) },
		includes: ["formResponses.field"]
	}, [engagement.id])
	return query?.value[1]
}

function useLeadQualifiedEvents(contactRequest) {
	const query = useParseQuery(ContactRequestEvent, {
		cacheLifetime: { amount: 1, unit: "hour" },
		filters: {
			contactRequest: ContactRequest.createWithoutData(contactRequest.id),
			eventId: ContactRequestEvent.EventEnum.LeadQualified.value
		},
	}, [contactRequest.id])
	return query?.value
}

function areFormResponsesEquals(currentConversation, previousConversation, isFirstConversation) {
	if (!currentConversation || !previousConversation || isFirstConversation) { return false }

	const currentResponses = (currentConversation.get("formResponses") || [])
		.filter((response) => !response.isEmpty())

	const previousResponses = (previousConversation.get("formResponses") || [])
		.filter((response) => !response.isEmpty())

	return currentResponses
		.reduce((prev, response, index) => {
			const previousResponse = previousResponses[index]
			if (Array.isArray(response.getValue())) {
				return prev && _.isEqual(
					response.getValue(),
					(previousResponse && previousResponse.getValue())
				)
			}
			return prev && response.getValue() === (previousResponse && previousResponse.getValue())
		}, true)
}

function ValidationBarContainer({ className, currentSession, onResign, onComplete }) {
	const leadQualifiedEvents = useLeadQualifiedEvents(
		currentSession?.engagement.get("contactRequest")
	)
	const isFirstConversation = leadQualifiedEvents?.length === 0
	const engagement = currentSession?.engagement
	const isFormComplete = (
		helper.areQualificationFlagsValid(currentSession?.qualificationFlags || [])
		&& FormResponseHelper.areFormResponsesValid(currentSession?.formResponses || [])
	)
	const previousConversation = usePreviousConversation(engagement)
	const hasNoNewInformation = areFormResponsesEquals(
		engagement?.get("currentConversation"),
		previousConversation,
		isFirstConversation
	)
	return (
		<ValidationBarUI
			className={className}
			onComplete={onComplete}
			onResign={onResign}
			closeButtonExpended={isFormComplete && hasNoNewInformation}
			closeButtonDisabled={!isFormComplete || isFirstConversation}
			completeButtonExpended={isFormComplete && !hasNoNewInformation}
			completeButtonDisabled={!isFormComplete}
			resignButtonExpended={false}
			resignButtonDisabled={false}
		/>
	)
}

ValidationBarContainer.propTypes = ValidationBar.propTypes

function ValidationBarUI({
	className,
	onComplete,
	onResign,
	closeButtonExpended = false,
	closeButtonDisabled = true,
	completeButtonExpended = false,
	completeButtonDisabled = true,
	resignButtonExpended = false,
	resignButtonDisabled = true,
}) {
	const { t } = useTranslation()
	return (
		<div className={`component validation-bar ${className}`}>
			<div className="delimiter full-height d-flex align-items-center justify-content-center">
				<ExpendableIconButton
					icon="mdi:close"
					className="mr-sm"
					style={PINButtonStyle.failure}
					text={t("validation.bar.close.engagement")}
					onClick={() => onComplete(false)}
					disabled={closeButtonDisabled}
					expend={closeButtonExpended}
				/>
				<ExpendableIconButton
					icon="mdi:check"
					style={PINButtonStyle.success}
					className="mr-sm"
					text={t("validation.bar.complete.engagement")}
					onClick={() => onComplete(true)}
					disabled={completeButtonDisabled}
					expend={completeButtonExpended}
				/>
				<ExpendableIconButton
					icon="mdi:account-arrow-right"
					style={PINButtonStyle.info}
					text={t("validation.bar.resign.engagement")}
					onClick={onResign}
					disabled={resignButtonDisabled}
					expend={resignButtonExpended}
				/>
			</div>
		</div>
	)
}

ValidationBarUI.propTypes = {
	className: PropTypes.string,
	onResign: PropTypes.func,
	onComplete: PropTypes.func,
	closeButtonExpended: PropTypes.bool,
	closeButtonDisabled: PropTypes.bool,
	completeButtonExpended: PropTypes.bool,
	completeButtonDisabled: PropTypes.bool,
	resignButtonExpended: PropTypes.bool,
	resignButtonDisabled: PropTypes.bool,
}

function ValidationBar({ className, currentSession, onResign, onComplete }) {
	const isSessionReady = Boolean(currentSession?.engagement)
	return !isSessionReady
		? <ValidationBarUI className={className} engagement={{}}/>
		: <ValidationBarContainer
			className={className}
			currentSession={currentSession}
			onResign={onResign}
			onComplete={onComplete}
		/>
}

ValidationBar.propTypes = {
	className: PropTypes.string,
	currentSession: PropTypes.object,
	onResign: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
}

export default ValidationBar

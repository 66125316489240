import moment from "moment"

class MomentHelper {

	static beautifyInterval(momentTime) {
		const days = Math.floor(moment.duration(momentTime).asDays())
		const totalHours = Math.floor(moment.duration(momentTime).asHours())
		const hours = totalHours >= 24 ? (totalHours % 24) : totalHours
		const minutes = moment.utc(momentTime).format("mm")
		let string = ""
		if (days > 0) { string = `${string}${days}d ` }
		if (hours > 0) { string = `${string}${hours}h ` }
		string = `${string}${minutes}m`
		return string
	}

	static beautifyIntervalFromNow(date) {
		return this.beautifyInterval(moment(new Date()).diff(moment(date)))
	}

	static momentIntervalFromNow(date) {
		return moment.duration(moment(new Date()).diff(moment(date)))
	}

}

export default MomentHelper

import React from "react"
import PropTypes from "prop-types"
import { Icon } from "@iconify/react"
import { Colors } from "pinpo-ui-kit"

function remForSize(size) {
	const sizeMap = {
		sm: "1.8rem",
		md: "2.4rem",
		lg: "3.2rem",
	}
	return sizeMap[size]
}

const RoundedIconButton = (props) => {
	const { name, icon, color, size } = props
	const attr = { icon: name, style: { color: Colors[color], fontSize: remForSize(size) } }
	return (
		<button
			className={`pointer rounded-icon-button rounded-circle ${color} ${size} ${props.className || ""}`}
			onClick={ props.onClick }
			type="button"
		>
			{icon || (<Icon {...attr} />)}
		</button>
	)
}
RoundedIconButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	name: PropTypes.string,
	icon: PropTypes.node,
	color: PropTypes.string.isRequired,
	size: PropTypes.string.isRequired,
}

export default RoundedIconButton

import { QualificationFlagsLoader } from "pinpo-model-kit"
import i18n from "i18n"
import { ArrayHelper } from "pinpo-web-framework"
const FlagsConfig = require("assets/data/flags-config.json")

function mergeFlagsWithPreviousNAFlags(flags, previousFlags) {
	return flags.map((section) => {
		let value = [...section.value]
		const previousSection = previousFlags.find((s) => (s.key === section.key))
		const naFlag = previousSection && previousSection.value.find((f) => (f.includes("na-")))
		if (naFlag) {
			value = [...value, naFlag]
		}
		return {
			...section,
			value
		}
	})
}

/**
 * Class used to retrieve, compute, valid, group either Qualification and Conversation flags.
 */
class FlagsHelper {

	static ignoredGroups = ["internal", "status"]

	constructor() {
		this.qualificationLoader = new QualificationFlagsLoader()
	}

	/* Public static methods */

	/**
	 * Remove all N/A flags from raw flags passed as params
	 * @static
	 * @param {string[]} flags Either Qualification or Conversation flags, possibly containing N/A
	 * flags
	 * @return {string[]} flags cleaned from N/A flags
	 */
	static filterFlagsBeforeSave = (flags) => flags.filter((flag) => !flag.includes("na-"))

	static getFlagsToSaveAfterChange = (groupedFlags, id, value) => {
		const updatedFlags = FlagsHelper.updateFlagsWithValueForSection(groupedFlags, id, value)
		return this.getCleanFlagsFromGroup(updatedFlags)
	}

	/**
	 * Remove all N/A flags from groupedFlags passes as parameters.
	 * @static
	 * @param {object[]} groupedFlags Either Qualification or Conversation flags, grouped by section
	 * @return {string[]} value flags cleaned from N/A flags
	 */
	static getCleanFlagsFromGroup = (groupedFlags) => {
		let allFlags = []
		groupedFlags.forEach((section) => {
			allFlags = [
				...allFlags,
				...section.value
			]
		})
		return FlagsHelper.filterFlagsBeforeSave(allFlags)
	}

	/**
	 * Remove all N/A flags from groupedFlags passes as parameters. Return an array containing
	 * BaseFlags[]
	 *
	 * @static
	 * @param {object[]} groupedFlags
	 * @return {BaseFlag[]}
	 */
	static getCleanFlagsFromGroupWithTranslation = (groupedFlags) => {
		let allFlags = []
		groupedFlags.forEach((section) => {
			allFlags = [
				...allFlags,
				...section.flags.filter((flag) => section.value.includes(flag.value))
			]
		})
		return allFlags.filter((flag) => !flag.value.includes("na-"))
	}

	/**
	 * Update value in group, for the id passed as parameter.
	 * Won't touch the index of groups.
	 *
	 * @static
	 * @param {object[]} flags flags to update
	 * @param {string} id group id
	 * @param {string[]} value the new value for this group
	 * @return {object[]}
	 */
	static updateFlagsWithValueForSection = (flags, id, value) => {
		const oldFlags = [...flags]
		let section = oldFlags.find((sec) => sec.key === id)
		section = {
			...section,
			value
		}
		const index = oldFlags.findIndex((sec) => sec.key === id)
		return [
			...oldFlags.slice(0, index),
			section,
			...oldFlags.slice(index + 1)
		]
	}

	getQualificationFlags = (value = [], previousFlags = []) => {
		const flags = this.qualificationLoader.getFlagsNotBelongingToAllGroups([])
		return mergeFlagsWithPreviousNAFlags(this.groupByGroups(flags, value), previousFlags)
	}

	areQualificationFlagsValid = (groupedFlags = []) => {
		for (const group of groupedFlags) {
			if (group.value.length === 0 && !FlagsHelper.ignoredGroups.includes(group.key)) {
				return false
			}
		}
		return true
	}

	/**
	 * Group flags by group. Add N/A flags for each group when applicable.
	 *
	 * @private
	 * @param flags allFlags, either Conversation or Qualification flags
	 * @param value selected flags among flags
	 * @return {object[]}
	 * 		- key : unique Id of the group
	 * 		- translationKey : translationKey
	 * 		- value : selected flags
	 * 		- choices : flags available to select
	 * 		- flags : typeof BaseFlag
	 */
	groupByGroups = (flags, value = []) => ArrayHelper.groupBy(flags, (flag) => (
		flag.groups.includes("conversation-status") ? "conversation-status" : flag.groups[0]
	))
		.map((flagGrouped) => {
			const groupConfig = FlagsConfig.groups.find((group) => group.value === flagGrouped[0])
			return {
				key: flagGrouped[0],
				translationKey: `operator.flags.section.${flagGrouped[0]}`,
				flags: flagGrouped[0] !== "conversation-status"
					? [...flagGrouped[1], { value: `na-${flagGrouped[0]}`, translationKey: "general.na" }]
					: [...flagGrouped[1]],
				...groupConfig
			}
		})
		.map((section) => {
			const choices = section.flags
				.map((flag) => ({ id: flag.value, text: i18n.t(flag.translationKey) }))
			return {
				...section,
				choices,
				value: value
					.filter((v) => (
						choices
							.map((c) => c.id)
							.includes(v)
					))
			}
		})

}

export default FlagsHelper

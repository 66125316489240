import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { Tracking } from "pinpo-web-framework"

function PINLink({ to, track, onClick, disabled, className, ...attr }) {
	const onClickEnhanced = useCallback((e) => {
		if (track) {
			Tracking.track("Click on a link", { to })
		}
		e.stopPropagation()
		if (onClick) { onClick(e) }
	}, [to, track, onClick])

	return (
		<Link
			{...attr}
			to={to}
			onClick={onClickEnhanced}
			className={`pinlink ${disabled ? "disabled" : ""} ${className || ""}`}
		/>
	)
}
PINLink.propTypes = {
	to: PropTypes.string.isRequired,
	track: PropTypes.bool,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
}

export default PINLink

import React, { useEffect, useState } from "react"
import hoistNonReactStatics from "hoist-non-react-statics"

const defaultOptions = {
	interval: 1000
}

const withAutoUpdate = ({ interval = 1000 } = defaultOptions) => (WrappedComponent) => {
	function HOCAutoUpdate(props) {
		useAutoUpdate(interval)
		return (<WrappedComponent {...props} />)
	}
	hoistNonReactStatics(HOCAutoUpdate, WrappedComponent)
	return HOCAutoUpdate
}

function useAutoUpdate(timeInterval) {
	const [, setUpdate] = useState(true)

	useEffect(() => {
		const interval = setInterval(() => {
			setUpdate((v) => !v)
		}, timeInterval)
		return () => clearInterval(interval)
	}, [timeInterval])
}

export { withAutoUpdate }


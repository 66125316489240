import React, { useCallback, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Icon } from "@iconify/react"
import { Colors, PINButton, PINButtonStyle, PINInfo } from "pinpo-ui-kit"
import { withBaseOverlay } from "./BaseOverlay"

function OverlayCard({
	isOpen,
	onClose,
	onSuccess,
	successButton,
	subtitle,
	description,
	title,
	iconProps,
	iconLarge,
	children,
	noScroll,
	containerClassName,
	className
}) {
	const contentNodeRef = useRef(null)

	const handleDocumentClick = useCallback((event) => {
		if (
			(contentNodeRef.current?.contains(event.target) ?? true)
			|| (event.keyCode && event.keyCode !== 27)
		) {
			return
		}
		onClose()
	}, [onClose])

	const onCloseCallback = useCallback((event) => {
		event.stopPropagation()
		onClose()
	}, [onClose])

	const onSuccessCallback = useCallback((event) => {
		event.stopPropagation()
		onSuccess()
	}, [onSuccess])

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("click", handleDocumentClick, false)
			document.addEventListener("keydown", handleDocumentClick, false)
			return () => {
				document.removeEventListener("click", handleDocumentClick, false)
				document.removeEventListener("keydown", handleDocumentClick, false)
			}
		}
		/* eslint-disable-next-line no-undefined */
		return undefined
	}, [isOpen, handleDocumentClick])

	const aClassName = `${className ?? ""} overlay-card`
	let aContainerClassName = `${containerClassName ?? ""} overlay-card-container`
	aContainerClassName = `${aContainerClassName}${noScroll ? " noScroll" : ""}`
	return (
		<div
			className={aContainerClassName}
		>
			<div
				className={aClassName}
				ref={contentNodeRef}
			>
				<div className="d-flex justify-content-between header mb-md">
					<div>
						<span className="d-flex align-items-center">
							{iconLarge
								? iconLarge
								: (iconProps && (<Icon {...iconProps} />))
							}
							<h1 className="ml-sm mb-no typo heading semibold fs48">{title}</h1>
						</span>
						<div className="d-flex align-items-center justify-content-center">
							{subtitle && <span className="typo fs24 semibold">{subtitle}</span>}
							{description && <PINInfo id={`info-${subtitle}`} text={description} className="ml-sm"/>}
						</div>
					</div>
					<div className="d-flex flex-column align-items-end">
						<div
							className="d-flex align-items-end mb-auto pointer"
							onClick={onCloseCallback}
						>
							<Icon
								icon="mdi:close"
								style={{ color: Colors.failure, fontSize: "2.8rem" }}
							/>
						</div>
						{successButton && (
							<PINButton
								className="mt-sm"
								color={PINButtonStyle.mustaYellow}
								onClick={onSuccessCallback}
								type="button"
							>
								{successButton}
							</PINButton>
						)}
					</div>
				</div>
				<div className="content">
					{children}
				</div>
			</div>
		</div>
	)
}
OverlayCard.propTypes = {
	className: PropTypes.string,
	containerClassName: PropTypes.string,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	successButton: PropTypes.node,
	subtitle: PropTypes.string,
	description: PropTypes.string,
	title: PropTypes.string.isRequired,
	iconProps: PropTypes.object,
	iconLarge: PropTypes.bool,
	children: PropTypes.node.isRequired,
	noScroll: PropTypes.bool,
}

export default withBaseOverlay(OverlayCard)

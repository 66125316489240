import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { PINTextInput, PINButton, Colors } from "pinpo-ui-kit"
import { Icon } from "@iconify/react"
import { withErrorManager } from "pinpo-web-framework"

function GuruSearchCard({ onSearchChange, className = "" }) {
	const [searchTerms, setSearchTerms] = useState("")
	const [timeoutFn, setTimeoutFn] = useState(null)

	const onChange = useCallback((_, value) => {
		setSearchTerms(value)
		if (!onSearchChange) { return }
		if (timeoutFn) { clearTimeout(timeoutFn) }
		setTimeoutFn(setTimeout(() => { onSearchChange(value) }, 200))
	}, [onSearchChange, timeoutFn])
	const clearField = useCallback((e) => {
		setSearchTerms("")
		onSearchChange("")
	}, [onSearchChange])
	return (
		<div className={`guru-search-bar ${className}`}>
			<Icon
				className="search-button"
				icon="mdi:magnify"
			/>
			<PINTextInput className="search-input" onChange={onChange} value={searchTerms}/>
			<PINButton
				className={`search-clear ${searchTerms !== "" ? "" : "hidden"}`}
				onClick={clearField}
				inline
			>
				<Icon
					icon="mdi:close-circle"
					style={{
						color: Colors.silver,
						fontSize: "1.8rem"
					}}
				/>
			</PINButton>
		</div>
	)
}
GuruSearchCard.propTypes = {
	onSearchChange: PropTypes.func,
	className: PropTypes.string
}
GuruSearchCard.labelForError = "Carte Guru"

export default withErrorManager(GuruSearchCard)

/**
 * Return the value from the object for the specified path.
 *
 * @throws
 * @param {any} object - The object from which get data.
 * @param {string} path - The path to data, using dot notation.
 * @param {boolean?} shouldThrow - Should the function throw if the path don't lead to data.
 * @returns {any} The value of the object for the specified path.
 */
function deepGet(object, path, shouldThrow = true) {
	let currentValue = object
	const keys = path.split(".")
	let prevKey = null
	let prevValue = null
	/* eslint-disable-next-line */ // ESLint bug
	for (const key of keys) {
		const decodedKey = key.replace("%2E%", ".")
		/* Wrong path - return or throw */
		if (!currentValue) {
			if (shouldThrow) {
				throw Error(
					`Cannot retrieve ${decodedKey} attribute ${prevKey ? ` for ${prevKey}` : ""}${prevKey && prevValue.id ? ` of id ${prevValue.id}` : ""}`
				)
			}
			return null
		}
		/* Get next attribute */
		prevValue = currentValue
		currentValue = currentValue[decodedKey]
		prevKey = decodedKey
	}
	return currentValue
}

/**
 * Set the specified value on the object at the specified path.
 * If something is already present, will replace the current value.
 *
 * @param {any} object - The object on which set data.
 * @param {any} value - The value to set.
 * @param {string} path - The path indicating where to set the data, using dot notation.
 * @returns {object} The mutated object.
 */
function deepSet(object = {}, value = null, path) {
	let currentObject = object
	const keys = path.split(".")
	let i = 0
	for (const key of keys) {
		const decodedKey = key.replace("%2E%", ".")
		/* Get next attribute */
		if (i === keys.length - 1) {
			currentObject[decodedKey] = value
		} else {
			currentObject[decodedKey] = { ...(currentObject[decodedKey] || {}) }
		}
		currentObject = currentObject[decodedKey]
		i = i + 1
	}
	return object
}

/**
 * Returns all paths for the specified object in an array.
 *
 * @param {object} object - The object for which retrieve path.
 * @param {string} [prevPath] - A path prefix to use.
 * @returns {string[]} The paths existing.
 */
function getAllPaths(object, prevPath = "") {
	if (!object || Object.keys(object).length === 0) { return [prevPath] }
	let keys = []
	Object.keys(object).forEach((key) => {
		const newPath = (prevPath !== "" ? (`${prevPath}.`) : "") + key
		if (typeof object[key] !== "object") {
			keys = [...keys, newPath]
		} else {
			keys = [...keys, ...getAllPaths(object[key], newPath)]
		}
	})
	return keys
}

export { deepGet, deepSet, getAllPaths }

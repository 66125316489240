/* eslint-disable max-len */

const SmoochCustomTexts = {
	headerText: "Conversation",
	inputPlaceholder: "Saisissez votre message...",
	sendButtonText: "Envoyer",
	introductionText: "Nous allons approfondir ensemble votre projet et vos critères d'achat afin de mieux traiter votre demande.",
	introAppText: "Vous pouvez continuer cette conversation sur votre application préférée.",
	settingsHeaderText: "Paramètres",
	actionPaymentError: "Une erreur est survenue pendant la tentative de paiement.<br/>Veuillez essayer avec un autre moyen de paiement.",
	actionPaymentCompleted: "Votre paiement a été accepté",
	actionPostbackError: "Une erreur est survenue, veuillez réessayer plus tard.",
	messageError: "Impossible d’envoyer ce message, veuillez réessayer.",
	invalidFileError: "Seules les images sont supportées. Choisissez un fichier ayant l’une des extensions suivantes : jpg, jpeg, png, gif, ou bmp.",
	messageIndicatorTitleSingular: "({count}) Nouveau message",
	messageIndicatorTitlePlural: "({count}) Nouveaux messages",
	connectNotificationText: "Soyez averti depuis vos autres apps lorsque vous recevez un message.",
	notificationSettingsChannelsTitle: "Autres canaux",
	notificationSettingsChannelsDescription: "Vous pouvez aussi communiquer avec nous depuis vos autres apps ou services.",
	notificationSettingsConnectedAs: "Connecté en tant que {username}",
	notificationSettingsConnected: "Connecté",
	connectNotificationSingleText: "Soyez averti lorsque vous recevez un message.",
	connectNotificationSingleButtonText: "Activer les notifications <name>",
	wechatQRCodeError: "Une erreur est survenue pendant la récupération de ce WeChat QR code. Veuillez réessayer.",
	messengerChannelDescription: "Connectez votre compte Facebook Messenger pour être averti quand vous recevez une réponse et continuer la discussion sur Facebook Messenger.",
	frontendEmailChannelDescription: "Pour continuer la discussion par email, il vous suffit d'envoyer un message sur notre adresse email et nous vous répondrons dans les plus brefs délais : ",
	smsChannelDescription: "Ajoutez votre numéro de téléphone pour être averti lorsque vous recevez une réponse.",
	smsChannelPendingDescription: "Vérifiez vos messages sur {number} pour confirmer l'ajout de votre numéro de téléphone.",
	telegramChannelDescription: "Connectez votre compte Telegram pour être averti quand vous recevez une réponse et continuer la discussion sur Telegram",
	wechatChannelDescriptionMobile: "Connectez votre compte WeChat pour être averti quand vous recevez une réponse et continuer la discussion sur WeChat. Pour commencer, enregistrez ce QR code et importez-le sur <a href=‘weixin://dl/scan’>QR code scanner</a>.",
	wechatChannelDescription: "Connectez votre compte WeChat pour être averti quand vous recevez une réponse et continuer la discussion sur WeChat",
	lineChannelDescription: "Pour discuter avec nous en utilsant LINE, scannez ce QR code en utilisant l'application LINE et envoyez-nous un message.",
	viberChannelDescriptionMobile: "Connectez votre compte Viber pour être averti quand vous recevez une réponse et continuer la discussion sur Viber. Pour commencer, installez l'app Viber et cliquez sur connexion.",
	viberChannelDescription: "Connectez votre compte Viber pour être averti quand vous recevez une réponse et continuer la discussion sur Viber. Pour commencer, scannez le QR code en utilisant l'app Viber.",
	smsTooManyRequestsError: "Une connexion à ce numéro a été demandé récemment. Essayez à nouveau dans {seconds} secondes.",
	smsBadRequestError: "Nous n'avons pas pu communiquer avec ce numéro. Essayez de nouveau ou utilisez un nouveau numéro",
	smsUnhandledError: "Une erreur est survenue, veuillez réessayer.",
	smsPingChannelError: "L'envoi d'un message sur votre numéro n'a pas fonctionné.",
	smsLinkCancelled: "La connexion à {appUserNumber} a été annulée.",
	smsLinkPending: "En attente",
	smsStartTexting: "Commencer avec les SMS",
	smsChangeNumber: "Changer mon numéro",
	smsSendText: "Envoyez-moi un message",
	smsContinue: "Continuer",
	smsCancel: "Annuler",
	transferError: "Une erreur s'est produite lors de la génération du lien. Veuillez réessayer.",
	fetchingHistory: "Récupération de l'historique...",
	fetchHistory: "Voir plus",
	clickToRetry: "Message non délivré. Cliquez pour réessayer.",
	tapToRetry: "Message non délivré. Tapez pour réessayer.",
	locationSendingFailed: "Impossible d'envoyer votre localisation",
	locationServicesDenied: "Ce site web ne peut pas accéder à votre localisation. Veuillez saisir directement votre localisation.",
	locationNotSupported: "Ce site Web ne peut pas accéder à votre localisation. Autorisez l'accès dans vos paramètres ou saisissez directement votre localisation.",
	locationSecurityRestriction: "Votre navigateur ne prend pas en charge les services de localisation, ou ils ont été désactivés. Veuillez saisir votre localisation à la place."
}

export default SmoochCustomTexts

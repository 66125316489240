import React from "react"
import ReactDOM from "react-dom"

import "./i18n"
import "./modelInitializer"

import { unregister } from "./registerServiceWorker"

import PinpoWebApp from "./components/PinpoWebApp"

import "./styles/main.scss"

ReactDOM.render((
	<PinpoWebApp />
), document.getElementById("root"))
/* We doesn't need offline */
unregister()

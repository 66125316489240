import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { withErrorManager } from "pinpo-web-framework"

function PopIn({ isOpen, left, children, className = "", onCloseAction }) {
	const [animatedClass, setAnimatedClass] = useState("")
	const contentNode = useRef(null)

	useEffect(() => {
		if (!isOpen) { return () => null }

		setAnimatedClass("animated")

		function handleDocumentClick(event) {
			if (!contentNode.current || contentNode.current.contains(event.target)) { return }
			if (onCloseAction) { onCloseAction() }
		}

		document.addEventListener("click", handleDocumentClick, false)
		return () => {
			document.removeEventListener("click", handleDocumentClick, false)
		}
	}, [isOpen, onCloseAction])

	return (
		<div
			className={ `popin-wrapper ${animatedClass} ${left ? "left" : "right"} ${isOpen ? "open" : "close"} ${className}` }
			ref={contentNode}
		>
			{children}
		</div>
	)
}
PopIn.labelForError = "Pop-In"
PopIn.propTypes = {
	isOpen: PropTypes.bool,
	left: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	onCloseAction: PropTypes.func,
}
const WrappedPopIn = withErrorManager(PopIn)

export { WrappedPopIn as PopIn }

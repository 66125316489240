import React from "react"
import { Trans } from "react-i18next"
import { withTranslation } from "components/hoc/withTranslation"
import { withErrorManager } from "pinpo-web-framework"

function ChatOptOutView() {
	return (
		<div className="chat-opt-out-view">
			<Trans className="chat-opt-out-view" i18nKey="qualification.chat.room.optOutWarning">
				<div className="opt-out-title">
					Ce prospect a demandé à ne plus être contacté.
				</div>
				<div className="opt-out-message">
					Vous n&apos;avez plus la possibilité d&apos;envoyer des messages à sa
					destination. Clôturez la qualification après avoir rempli les informations que
					vous avez éventuellement récupérées.
				</div>
			</Trans>
		</div>
	)
}
ChatOptOutView.labelForError = "Indication opt-out"

export default withErrorManager(withTranslation()(ChatOptOutView))

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-xhr-backend"

const isDebug = process.env.REACT_APP_MODE !== "production" && !process.env.REACT_APP_NO_I18N_DEBUG

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		lng: "fr",
		fallbackLng: "fr",
		ns: ["translations"],
		defaultNS: "translations",
		debug: isDebug,
		saveMissing: isDebug,
		interpolation: {
			escapeValue: false
		},
		// "." by default lead to a js object path reading of the key
		keySeparator: "/"
	})

export default i18n

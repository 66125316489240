import { useState } from "react"

function parseObjectsAreUpToDate(newParseObj, oldObj) {
	const test = (
		newParseObj.id === (oldObj || {}).id // Same id
		&& newParseObj.updatedAt === (oldObj || {}).updatedAt // Same update time
		&& newParseObj.dirtyKeys().length === 0 // And sync with the server
	)
	return test
}

function dateObjectsAreUpToDate(newDateObj, oldObj) {
	return (oldObj instanceof Date && (
		newDateObj.toISOString() === oldObj.toISOString()
		|| newDateObj.valueOf() === oldObj.valueOf()
	))
}

function proceedToCheckOperation(newObj, oldObj, noDeepCheckWarning) {
	let isUpToDate = (newObj === oldObj) // Standard equality

	/* Parse Object are detected this way because we don't have access to base class */
	if (newObj && typeof newObj._getStateIdentifier === "function") {
		isUpToDate = parseObjectsAreUpToDate(newObj, oldObj)
	} else if (newObj instanceof Date) {
		isUpToDate = dateObjectsAreUpToDate(newObj, oldObj)
	} else if (newObj != null && typeof newObj === "object" && noDeepCheckWarning) {
		noDeepCheckWarning()
	}

	return isUpToDate
}

function useObjectProp(objectProp) {
	const [object, setObject] = useState(objectProp)
	const keys = objectProp ? Object.keys(objectProp) : []
	const prevKeys = object ? Object.keys(object) : []

	if (keys.length !== prevKeys.length) {
		setObject(objectProp)
	} else {
		/* Check potential updates */
		for (const key of keys) {
			const isUpToDate = proceedToCheckOperation(objectProp[key], object[key], () => {
				console.warn(`The \`useObjectProp\` hook doesn't deeply check the equality of sub-objects.`)
				console.trace(`Key \`${key}\` of the object hasn't been deep checked.`, objectProp)
			})

			if (!isUpToDate) {
				setObject(objectProp)
			}
		}
	}

	return object
}

function useArrayProp(arrayProp) {
	const [array, setArray] = useState(arrayProp)
	let isUpToDate = (array === arrayProp)

	if (array && arrayProp) {
		isUpToDate = (array.length === arrayProp.length)
		arrayProp.forEach((item, index) => {
			isUpToDate = isUpToDate && proceedToCheckOperation(item, array[index])
		})
	}

	if (!isUpToDate) {
		setArray(arrayProp)
	}

	return array
}

export { useObjectProp, useArrayProp }

import React from "react"
import PropTypes from "prop-types"
import ResizeObserver from "react-resize-observer"
import { withErrorManager } from "pinpo-web-framework"

import FooterBubble from "components/shared/footer/FooterBubble"
import FooterEngagementAction from "components/shared/footer/FooterEngagementAction"
import FooterCollapsed from "components/shared/footer/FooterCollapsed"

class FooterView extends React.Component {

	static labelForError = "Pied de page de qualification"

	// State and Constructor

	state = {
		footerViewWidth: 0
	}

	// Private Methods

	onResize = (rect) => {
		this.setState({ footerViewWidth: rect.width })
	}

	// Lifecycle

	render() {
		const { engagements, currentEngagement, isLockFetching,
			onNewMessage, waitingEngagement, onTakeEngagement } = this.props
		const openedEngagementsNumber = (
			((this.state.footerViewWidth - FooterCollapsed.width) / 245)
			|| 1
		)
		const openedEngagements = engagements.slice(0, openedEngagementsNumber)
		const collapsedEngagements = engagements.slice(openedEngagementsNumber) || []
		return (
			<footer className="footer-container full-vw d-flex justify-content-between">
				<div className="d-flex footer-conversation full-width">
					{ openedEngagements.map((engagementData, i) => (
						<FooterBubble
							key={ engagementData.engagement.id }
							engagement={ engagementData.engagement }
							conversation={ engagementData.conversation }
							currentEngagement={ currentEngagement }
							isCurrentEngagement={
								engagementData.engagement.id === (
									currentEngagement && currentEngagement.id
								)
							}
							isLockFetching={isLockFetching}
							onNewMessage={ onNewMessage }
						/>
					)) }
					{ collapsedEngagements.length > 0 && (
						<FooterCollapsed
							collapsedEngagements={ collapsedEngagements }
							currentEngagement={ currentEngagement }
							onNewMessage={ onNewMessage }
							isLockFetching={isLockFetching}
						/>
					)}
					<ResizeObserver
						onResize={ this.onResize }
					/>
				</div>
				<FooterEngagementAction
					key={waitingEngagement?.id}
					engagement={waitingEngagement}
					onTakeEngagement={onTakeEngagement}
					disabled={isLockFetching}
				/>
			</footer>
		)
	}

}
FooterView.propTypes = {
	engagements: PropTypes.array,
	currentEngagement: PropTypes.object,
	isLockFetching: PropTypes.bool,
	onNewMessage: PropTypes.func,
	onTakeEngagement: PropTypes.func,
	waitingEngagement: PropTypes.object,
}

export default withErrorManager(FooterView)

import { Validator } from "pinpo-web-framework"

class FormResponseHelper {

	static validatorForField(field) {
		if (field.has("regexValidator")) {
			return regexValidator(field.get("regexValidator"))
		} else if (field.get("type") === "email") {
			return Validator.email
		} else if (field.get("type") === "number") {
			return numberValidator(
				field.get("minNumber"),
				field.get("maxNumber"),
				field.get("isInt")
			)
		} else if (field.get("type") === "date") {
			return (value) => value instanceof Date
		}
		return null
	}

	static areFormResponsesValid(formResponsesRedux) {
		for (const formResponse of formResponsesRedux) {
			if (!FormResponseHelper.isFormResponseValid(formResponse)) {
				return false
			}
		}
		return true
	}

	static isFormResponseValid(formResponse) {
		const parseResponse = formResponse.getObject()
		const dismissed = formResponse.getDismissed()
		const validator = (
			FormResponseHelper.validatorForField(parseResponse.get("field"))
			|| (() => true)
		)
		if (parseResponse.get("field").get("required") === true) {
			return FormResponseHelper.isRequiredResponseValid(parseResponse, dismissed, validator)
		}
		return FormResponseHelper.isRegularResponseValid(parseResponse, validator)
	}

	static isRequiredResponseValid(parseResponse, dismissed, validator) {
		if (dismissed) {
			return true
		}
		return !parseResponse.isEmpty() && validator(parseResponse.getValue())
	}

	static isRegularResponseValid(parseResponse, validator) {
		return parseResponse.isEmpty() || validator(parseResponse.getValue())
	}

}

function regexValidator(regexStr) {
	const regex = new RegExp(regexStr)
	return (value) => (typeof value === "string" && Boolean(value.match(regex)))
}

function numberValidator(min, max, isInt) {
	const cleanLimitValue = (limitValue, value) => (
		typeof limitValue === "number" ? limitValue : value
	)
	const validator = isInt === true ? Validator.integer : Validator.float
	return (value) => (
		validator(value)
		&& cleanLimitValue(min, value) <= value
		&& cleanLimitValue(max, value) >= value
	)
}

export default FormResponseHelper

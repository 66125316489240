import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"

import { PINAnchor, URIHelper } from "pinpo-web-framework"
import { PINButton, PINForm, PINTextInput } from "pinpo-ui-kit"
import { useTranslation } from "react-i18next"
import { AuthenticationContext } from "contexts/authentication/AuthenticationContext"
import AppHeader from "components/parts/AppHeader"
import LightFooter from "components/parts/LightFooter"
import PasswordChangePage from "components/pages/authentication/PasswordChangePage"
import DisplayError from "components/shared/ui/DisplayError"
import { usePageTracking } from "hooks/PageTrackingHook"

function LoginPage(props) {
	usePageTracking("LoginPage")
	const { t } = useTranslation()
	const {
		login,
		authSuccessHandling,
		become,
	} = useContext(AuthenticationContext)
	const [error, setError] = useState()

	useEffect(() => {
		const { hash } = window.location
		if (hash) {
			authSuccessHandling(hash)
				.catch(() => {
					setError(t("authentication.login.page.error.unknown"))
				})
		}

		const aToken = props.match.params.token
		if (aToken) {
			become(aToken)
		}
	}, [authSuccessHandling, become, props.match.params.token, t])

	async function onSubmit({
		email,
		password,
	}) {
		try {
			await login(email, password)
		} catch (e) {
			if (e.code === "access_denied") {
				setError(t("authentication.login.page.error.denied"))
			} else {
				setError(t("authentication.login.page.error.unknown"))
			}
		}
	}

	return (
		<div className="component container-pinpo d-flex flex-column min-full-vh pt-xl">
			<AppHeader path={LoginPage.uri} title={null}/>
			<div className="login-page justify-content-center">
				<h1 className="title">{t("authentication.login.title")}</h1>
				{error && <DisplayError error={error}/>}
				<p className="sub-title">{t("authentication.login.use.mail")}</p>
				<PINForm className="login-form" onSubmit={onSubmit} t={t}>
					<PINTextInput
						name="email"
						type="email"
						placeholder={t("authentication.login.email")}
						hiddenRequired
					/>
					<PINTextInput
						name="password"
						type="password"
						placeholder={t("authentication.login.password")}
						hiddenRequired
					/>
					<PINButton
						type="submit"
						className="submit-button"
					>
						{t("authentication.login.connexion")}
					</PINButton>
				</PINForm>
				<PINAnchor href={PasswordChangePage.uri({})}>
					{t("authentication.login.password.reset")}
				</PINAnchor>
			</div>
			<div className="flex-grow"/>
			<LightFooter/>
		</div>
	)
}
LoginPage.propTypes = {
	match: PropTypes.object.isRequired,
}
LoginPage.uri = URIHelper.generate("/login", ["token?"])

export default LoginPage

import Axios from "axios"
import { Sentry } from "pinpo-web-framework"

import Config from "./Config"

function fetchCardsForTagsAndTerms(tags, searchTerms) {
	const tagsQuery = tags.map((tag) => (`tag-${tag} exists`)).join(" OR ")
	const query = `verificationState = trusted AND (${tagsQuery})`
	const params = {
		q: query,
		showArchived: false,
		sortField: "lastModified",
		sortOrder: "desc"
	}
	if (searchTerms) { params.searchTerms = searchTerms }
	return Axios.get(`${Config.URLs.CorsProxy}/${Config.Guru.API.Url}/search/query`, {
		params,
		auth: {
			username: Config.Guru.API.User,
			password: Config.Guru.API.Token
		}
	})
		.catch((error) => {
			Sentry.report(error)
			return { cards: [], params: {}, error }
		})
		.then((result) => ({
			cards: result.data,
			params: result.config ? result.config.params : {},
			error: result.error
		}))
}

export default {
	fetchCardsForTagsAndTerms
}

import ParseObject from "./ParseObject"

class FormResponse extends ParseObject {

	_dismissed = false

	constructor(parseResponse, dismissed = false) {
		super(parseResponse)
		this.setDismissed(dismissed)
	}

	getDismissed() {
		return this._dismissed
	}

	setDismissed(dismissed) {
		this._dismissed = dismissed
	}

}

export default FormResponse

import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "components/hoc/withTranslation"
import { withErrorManager } from "pinpo-web-framework"

import FormInput from "components/shared/form/FormInput"
import FormResponseHelper from "services/helpers/FormResponseHelper"

/**
 *
 * Create a FormResponseContainer to directly call with a formResponse
 * and render FormInput inside.
 *
 * In this container, set all specific config (regex to apply if any,
 * FormInput type, etc.)
 */
class FormResponseContainer extends React.Component {

	static labelForError = "Réponse de formulaire"

	// State and Constructor

	state = {}

	// Private Methods

	placeholderForType = (type, t) => {
		switch (type) {
		case "number":
			return t("form.response.input.number.placeholder")
		case "email":
			return t("form.response.input.email.placeholder")
		case "open-choices":
			return t("form.response.input.open.choice.placeholder")
		default:
			return t("form.response.input.text.placeholder")
		}
	}

	// Lifecycle

	render() {
		const { formResponse, onChange, t } = this.props
		const parseResponse = formResponse.getObject()
		const {
			type,
			description,
			question,
			choices,
			required,
			isMeeting,
			isInt
		} = parseResponse.get("field").attributes
		const value = parseResponse.getValue()
		const dismissed = formResponse.getDismissed()
		const placeholder = this.placeholderForType(type, t)
		const validator = FormResponseHelper.validatorForField(parseResponse.get("field"))
		const attr = {
			id: parseResponse.id,
			showStar: required === true,
			dismissable: required === true,
			dismissed,
			label: question,
			onChange,
			type,
			description,
			value,
			choices,
			placeholder,
			validator,
			t,
			isMeeting,
			isInt
		}
		return (<FormInput key={ parseResponse.id } { ...attr } reload/>)
	}

}
FormResponseContainer.propTypes = {
	formResponse: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
}

export default withErrorManager(withTranslation()(FormResponseContainer))

import { useCallback, useEffect, useState } from "react"

const DEFAULT_OPTIONS = {
	limit: 1, // Time to wait in seconds between each promises
	maxLength: Infinity // Inifinite queue length
}

/**
 * Hook that lets you queue promises and execute them
 * one-by-one with throttling.
 *
 * @param {{ limit: number, maxLength: number }?} options - Options used to configure hook.
 * @returns {{
 *  queuePromise: (promise: Promise<any>) => undefined,
 * 	drainQueue: () => undefined,
 * 	queueLength: number
 * }}
 */
function usePromiseQueue(options = {}) {
	const {
		limit,
		maxLength,
	} = { ...DEFAULT_OPTIONS, ...options }
	const [promises, setPromises] = useState([])
	const [currentPromise, setCurrentPromise] = useState(null)

	const queuePromise = useCallback((promise) => {
		setPromises((_promises) => {
			// We have to count for promises.length + currentPromise
			if (_promises.length < maxLength - 1) {
				return [..._promises, promise]
			}
			return _promises
		})
	}, [maxLength])

	const drainQueue = useCallback(() => {
		setPromises([])
	}, [])

	/**
	 * Trigger current promise, then trigger timer before resetting current promise.
	 */
	useEffect(() => {
		if (currentPromise) {
			let timer = null
			currentPromise.promise()
				.then(() => (
					new Promise((resolve) => {
						timer = setInterval(() => resolve(), limit * 1000)
					})
				))
				.catch((error) => console.error(error))
				.finally(() => setCurrentPromise(null))
			return () => {
				clearInterval(timer)
				timer = null
			}
		}
		return () => null
	}, [currentPromise, limit])

	/**
	 * Set currentPromise if `null` and queue is not empty.
	 */
	useEffect(() => {
		if (!currentPromise && promises.length > 0) {
			const [promise, ...rest] = promises
			// Using this syntax otherwise it will execute function in promise.
			setCurrentPromise({ promise })
			setPromises(rest)
		}
	}, [currentPromise, promises])

	return {
		queuePromise,
		drainQueue,
		queueLength: promises.length
	}
}

export { usePromiseQueue }
